import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rudolf-business-card-scanner-topnav-area" }
const _hoisted_2 = { class: "rudolf-business-card-scanner-container" }
const _hoisted_3 = { class: "business-card-scanner-camera-container" }
const _hoisted_4 = { id: "scanner-content" }
const _hoisted_5 = {
  key: 0,
  class: "ai-scan-button"
}
const _hoisted_6 = {
  key: 1,
  class: "helper-text"
}
const _hoisted_7 = {
  key: 3,
  class: "camera-status-box"
}
const _hoisted_8 = {
  key: 4,
  class: "camera-status-box"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_button = _resolveComponent("b-button")
  const _component_qrcode_stream = _resolveComponent("qrcode-stream")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "rudolf-business-card-scanner-close",
        onClick: _cache[0] || (_cache[0] = $event => ($options.toggleComponent(false)))
      }, [
        _createVNode(_component_b_icon, {
          class: "rudolf-mobile-menu-close",
          icon: "close",
          size: "is-large"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("div", { id: "ai-scan-pause-frame" }, null, -1 /* HOISTED */)),
        _createElementVNode("div", _hoisted_4, [
          (!$data.cameraError && !$data.cameraLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createVNode(_component_b_button, {
                  class: "mt-5",
                  type: "is-primary",
                  rounded: "",
                  onClick: _cache[1] || (_cache[1] = $event => ($options.scanBusinessCardAI()))
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode("Scan business card (AI)")
                  ])),
                  _: 1 /* STABLE */
                })
              ]))
            : _createCommentVNode("v-if", true),
          (!$data.cameraError && !$data.cameraLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(_ctx.l('business-card-scanner/helper-text')), 1 /* TEXT */))
            : _createCommentVNode("v-if", true),
          ($data.toggled)
            ? (_openBlock(), _createBlock(_component_qrcode_stream, {
                key: 2,
                onDetect: $options.onDetect,
                onError: $options.onError,
                onCameraOn: $options.onCameraOn
              }, null, 8 /* PROPS */, ["onDetect", "onError", "onCameraOn"]))
            : _createCommentVNode("v-if", true),
          ($data.cameraLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createVNode(_component_b_loading, {
                  "is-full-page": false,
                  modelValue: $data.cameraLoading,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.cameraLoading) = $event)),
                  animation: "none"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]))
            : _createCommentVNode("v-if", true),
          ($data.cameraError)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString($data.cameraError), 1 /* TEXT */))
            : _createCommentVNode("v-if", true)
        ]),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          modelValue: $data.scannerLoading,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.scannerLoading) = $event)),
          animation: "none"
        }, null, 8 /* PROPS */, ["modelValue"])
      ])
    ])
  ]))
}