import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createBlock as _createBlock, Fragment as _Fragment, renderList as _renderList, withModifiers as _withModifiers } from "vue"
import _imports_0 from 'FRONTEND/images/account-user-profile-placeholder.png'


const _hoisted_1 = { class: "page-content-inner rudolfbox is-relative" }
const _hoisted_2 = { class: "users-page" }
const _hoisted_3 = {
  key: 0,
  class: "has-text-right mb-5"
}
const _hoisted_4 = { class: "item" }
const _hoisted_5 = { class: "item-name" }
const _hoisted_6 = { class: "is-capitalized" }
const _hoisted_7 = { style: {"line-height":"0.9em"} }
const _hoisted_8 = { class: "rudolfbox add-edit-user-container" }
const _hoisted_9 = { class: "modal-header" }
const _hoisted_10 = {
  key: 0,
  class: "rtitle modal-title"
}
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 1,
  class: "rtitle modal-title"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "block mb-5" }
const _hoisted_15 = { class: "rtitle sm normalcase mb-3" }
const _hoisted_16 = { class: "rtitle sm normalcase mb-3" }
const _hoisted_17 = {
  key: 1,
  class: "role-options-tab"
}
const _hoisted_18 = { class: "rtitle sm mb-4" }
const _hoisted_19 = { class: "control" }
const _hoisted_20 = { class: "mb-5" }
const _hoisted_21 = { class: "user-visibilities" }
const _hoisted_22 = { class: "item-container" }
const _hoisted_23 = { class: "item" }
const _hoisted_24 = { class: "item-name" }
const _hoisted_25 = { class: "access-level-select" }
const _hoisted_26 = ["onClick"]
const _hoisted_27 = { class: "rtitle sm mb-4" }
const _hoisted_28 = { class: "mb-5" }
const _hoisted_29 = { class: "product-visibilities" }
const _hoisted_30 = { class: "item-container" }
const _hoisted_31 = { class: "item" }
const _hoisted_32 = { class: "item-name" }
const _hoisted_33 = { class: "access-level-select" }
const _hoisted_34 = ["onClick"]
const _hoisted_35 = { class: "mb-5" }
const _hoisted_36 = { class: "rtitle sm mb-4" }
const _hoisted_37 = { class: "bottom-buttons-area" }
const _hoisted_38 = { class: "rudolfbox" }
const _hoisted_39 = { class: "modal-inner has-text-centered" }
const _hoisted_40 = ["innerHTML"]
const _hoisted_41 = { class: "buttons mb-0" }
const _hoisted_42 = { class: "rudolfbox" }
const _hoisted_43 = { class: "modal-inner has-text-centered" }
const _hoisted_44 = ["innerHTML"]
const _hoisted_45 = { class: "buttons mb-0" }
const _hoisted_46 = { class: "rudolfbox" }
const _hoisted_47 = { class: "modal-inner has-text-centered" }
const _hoisted_48 = ["innerHTML"]
const _hoisted_49 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_table_column = _resolveComponent("b-table-column")
  const _component_b_table = _resolveComponent("b-table")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_tabs = _resolveComponent("b-tabs")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.authStore.isSuperAdmin)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("setup-users/add-new-user"),
              type: "is-white",
              position: "is-left"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_button, {
                  class: "icon-button",
                  type: "is-light",
                  size: "is-large",
                  onClick: _cache[0] || (_cache[0] = $event => ($options.openNewEditUserModal())),
                  disabled: $data.loading
                }, {
                  default: _withCtx(() => _cache[31] || (_cache[31] = [
                    _createTextVNode("+")
                  ])),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["disabled"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]))
        : _createCommentVNode("v-if", true),
      (_openBlock(), _createBlock(_component_b_table, {
        data: $options.usersComputed,
        striped: false,
        hoverable: true,
        "mobile-cards": true,
        loading: $data.loading,
        key: _ctx.usersKey,
        paginated: false,
        "per-page": "15",
        "pagination-simple": true,
        "pagination-position": "both",
        "default-sort-direction": "desc",
        "pagination-rounded": true,
        "sort-icon": "arrow-up",
        "sort-icon-size": "is-small",
        "default-sort": "time"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_b_table_column, {
            field: "name",
            label: _ctx.l("generic/name"),
            sortable: "",
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            default: _withCtx((props) => [
              _createElementVNode("div", _hoisted_4, [
                _cache[32] || (_cache[32] = _createElementVNode("div", { class: "item-img" }, [
                  _createElementVNode("img", {
                    src: _imports_0,
                    width: "32"
                  })
                ], -1 /* HOISTED */)),
                _createElementVNode("div", _hoisted_5, _toDisplayString(props.row.fullName), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_table_column, {
            field: "email",
            label: _ctx.l("generic/contact"),
            sortable: "",
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            default: _withCtx((props) => [
              _createElementVNode("span", null, [
                _createTextVNode(_toDisplayString(props.row.email), 1 /* TEXT */),
                _cache[33] || (_cache[33] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                _createTextVNode(_toDisplayString(props.row.phone), 1 /* TEXT */)
              ])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_table_column, {
            field: "role",
            label: _ctx.l("generic/role"),
            sortable: "",
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            default: _withCtx((props) => [
              _createElementVNode("span", _hoisted_6, _toDisplayString(props.row.role), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_table_column, {
            field: "title",
            label: _ctx.l("generic/role"),
            sortable: "",
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            default: _withCtx((props) => [
              _createElementVNode("span", null, _toDisplayString(props.row.title), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_table_column, {
            label: _ctx.l("generic/password"),
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            header: _withCtx(({ column }) => [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("span", null, _toDisplayString(column.label), 1 /* TEXT */)
              ])
            ]),
            default: _withCtx((props) => [
              _createVNode(_component_b_button, {
                class: "mb-4",
                onClick: $event => ($options.resetUserPasswordModal(props.row)),
                type: "is-primary",
                rounded: ""
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/reset')), 1 /* TEXT */)
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["label"]),
          _createVNode(_component_b_table_column, {
            label: "",
            "header-class": "category-header",
            "cell-class": "category-cell"
          }, {
            default: _withCtx((props) => [
              _createVNode(_component_b_button, {
                class: "icon-button ml-1",
                type: "is-light",
                size: "is-small",
                "icon-right": "pencil-outline",
                onClick: $event => ($options.openNewEditUserModal(props.row)),
                disabled: $data.loading
              }, null, 8 /* PROPS */, ["onClick", "disabled"]),
              ($options.canDeleteUser(props.row))
                ? (_openBlock(), _createBlock(_component_b_button, {
                    key: 0,
                    class: "icon-button mr-5",
                    type: "is-light",
                    size: "is-small",
                    "icon-right": "delete-outline",
                    onClick: $event => ($options.deleteUserConfirmModal(props.row)),
                    disabled: $data.loading
                  }, null, 8 /* PROPS */, ["onClick", "disabled"]))
                : _createCommentVNode("v-if", true)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["data", "loading"]))
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.appLoading,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => ((_ctx.appLoading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"]),
    _createVNode(_component_b_modal, {
      modelValue: $data.addEditUserModalActive,
      "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => (($data.addEditUserModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeAddEditUserModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("form", {
            class: "user-info-form",
            onSubmit: _cache[20] || (_cache[20] = _withModifiers($event => ($options.submitUserInfoModal()), ["prevent"]))
          }, [
            _createElementVNode("div", _hoisted_9, [
              (!$data.editedUserId)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createTextVNode(_toDisplayString(_ctx.l('setup-users/add-new-user')), 1 /* TEXT */),
                    ($data.userFirstName || $data.userLastName)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_11, " - " + _toDisplayString($data.userFirstName) + " " + _toDisplayString($data.userLastName), 1 /* TEXT */))
                      : _createCommentVNode("v-if", true)
                  ]))
                : ($data.editedUserId)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createTextVNode(_toDisplayString(_ctx.l('setup-users/edit-user')), 1 /* TEXT */),
                      ($data.userFirstName || $data.userLastName)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, " - " + _toDisplayString($data.userFirstName) + " " + _toDisplayString($data.userLastName), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]))
                  : _createCommentVNode("v-if", true),
              _createElementVNode("div", null, [
                _createElementVNode("button", {
                  class: "delete",
                  type: "button",
                  onClick: _cache[2] || (_cache[2] = $event => ($options.closeAddEditUserModal()))
                })
              ])
            ]),
            _createVNode(_component_b_tabs, { type: "is-boxed" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_tab_item, {
                  label: "Main details",
                  icon: "account-box"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_14, [
                      _createVNode(_component_b_radio, {
                        class: "mr-6",
                        modelValue: $data.userTitle,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.userTitle) = $event)),
                        name: "userTitle",
                        "native-value": "Mr"
                      }, {
                        default: _withCtx(() => _cache[34] || (_cache[34] = [
                          _createTextVNode("Mr")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"]),
                      _createVNode(_component_b_radio, {
                        class: "mr-6",
                        modelValue: $data.userTitle,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.userTitle) = $event)),
                        name: "userTitle",
                        "native-value": "Ms"
                      }, {
                        default: _withCtx(() => _cache[35] || (_cache[35] = [
                          _createTextVNode("Ms")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"]),
                      _createVNode(_component_b_radio, {
                        class: "mr-6",
                        modelValue: $data.userTitle,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.userTitle) = $event)),
                        name: "userTitle",
                        "native-value": "Mrs"
                      }, {
                        default: _withCtx(() => _cache[36] || (_cache[36] = [
                          _createTextVNode("Mrs")
                        ])),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _createVNode(_component_b_dropdown, {
                      modelValue: $data.userRole,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.userRole) = $event)),
                      "aria-role": "list",
                      position: "is-bottom-right",
                      style: {"margin-bottom":"32px"}
                    }, {
                      trigger: _withCtx(({ active }) => [
                        _createVNode(_component_b_field, {
                          label: "Role",
                          "label-position": "on-border"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              label: $data.userRole,
                              type: "is-select",
                              expanded: "",
                              "icon-right": active ? "menu-up" : "menu-down",
                              style: {"text-transform":"capitalize"}
                            }, null, 8 /* PROPS */, ["label", "icon-right"])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "admin"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.l('setup-users/role-admin')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "user"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.l('setup-users/role-user')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        }),
                        _createVNode(_component_b_dropdown_item, {
                          "aria-role": "listitem",
                          value: "basic"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.l('setup-users/role-basic')), 1 /* TEXT */)
                          ]),
                          _: 1 /* STABLE */
                        })
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["modelValue"]),
                    _createVNode(_component_b_field, {
                      grouped: "",
                      style: {"margin-bottom":"32px"}
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_field, {
                          label: _ctx.l("generic/first-name"),
                          "label-position": "on-border",
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              modelValue: $data.userFirstName,
                              "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.userFirstName) = $event)),
                              placeholder: _ctx.l("generic/first-name"),
                              required: ""
                            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"]),
                        _createVNode(_component_b_field, {
                          label: _ctx.l("generic/last-name"),
                          "label-position": "on-border",
                          expanded: ""
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_input, {
                              modelValue: $data.userLastName,
                              "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.userLastName) = $event)),
                              placeholder: _ctx.l("generic/last-name")
                            }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"])
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/phone-number"),
                      "label-position": "on-border",
                      style: {"margin-bottom":"32px"},
                      type: { "is-danger": $data.duplicatePhoneError },
                      message: $data.duplicatePhoneError ? _ctx.l("setup-users/user-phone-exists") : ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          modelValue: $data.userPhoneNumber,
                          "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.userPhoneNumber) = $event)),
                          placeholder: "+358 123...",
                          expanded: ""
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "type", "message"]),
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/email"),
                      "label-position": "on-border",
                      style: {"margin-bottom":"32px"},
                      type: { "is-danger": $data.duplicateEmailError },
                      message: $data.duplicateEmailError ? _ctx.l("setup-users/user-email-exists") : ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          modelValue: $data.userEmail,
                          "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => (($data.userEmail) = $event)),
                          placeholder: "user@mail.com",
                          expanded: "",
                          required: "",
                          type: "email"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label", "type", "message"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_tab_item, {
                  label: _ctx.l("setup-users/role-options"),
                  icon: "account-group"
                }, {
                  default: _withCtx(() => [
                    ($data.userRole === "superadmin" || $data.userRole === "admin")
                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.l('setup-users/role-options-disabled', $data.userRole)), 1 /* TEXT */),
                          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.l('setup-users/role-options-disabled-note')), 1 /* TEXT */)
                        ], 64 /* STABLE_FRAGMENT */))
                      : (_openBlock(), _createElementBlock("div", _hoisted_17, [
                          _createElementVNode("h4", _hoisted_18, _toDisplayString(_ctx.l('setup-users/user-visibility-title')), 1 /* TEXT */),
                          _createVNode(_component_b_field, {
                            class: "mb-5",
                            grouped: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_autocomplete, {
                                modelValue: $data.usersSearchStr,
                                "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.usersSearchStr) = $event)),
                                placeholder: _ctx.l("setup-users/search-team-members") + "...",
                                icon: "magnify",
                                "open-on-focus": true,
                                "clear-on-select": true,
                                data: $options.filteredUsers,
                                field: "fullName",
                                onSelect: _cache[12] || (_cache[12] = option => $options.addUserVisibility(option)),
                                expanded: ""
                              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data"]),
                              _createElementVNode("p", _hoisted_19, [
                                _createVNode(_component_b_button, {
                                  type: "is-light",
                                  rounded: "",
                                  onClick: _cache[13] || (_cache[13] = $event => ($options.addAllUserVisibilities()))
                                }, {
                                  default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.l('setup-users/add-all')), 1 /* TEXT */)
                                  ]),
                                  _: 1 /* STABLE */
                                })
                              ])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("div", _hoisted_20, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.userVisibilities, (userVisibility) => {
                              return (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                _createElementVNode("div", _hoisted_22, [
                                  _createElementVNode("div", _hoisted_23, [
                                    _cache[37] || (_cache[37] = _createElementVNode("div", { class: "item-img" }, [
                                      _createElementVNode("img", {
                                        src: _imports_0,
                                        width: "32"
                                      })
                                    ], -1 /* HOISTED */)),
                                    _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.usersStore.byId(userVisibility.user_id).fullName), 1 /* TEXT */)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_25, [
                                  _createVNode(_component_b_dropdown, {
                                    modelValue: userVisibility.access_level,
                                    "onUpdate:modelValue": $event => ((userVisibility.access_level) = $event),
                                    "aria-role": "list",
                                    position: "is-bottom-left"
                                  }, {
                                    trigger: _withCtx(({ active }) => [
                                      _createVNode(_component_b_field, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_b_button, {
                                            label: $options.accessLevelLabel(userVisibility.access_level),
                                            type: "is-select",
                                            expanded: "",
                                            size: "is-small",
                                            "icon-right": active ? "chevron-up" : "chevron-down"
                                          }, null, 8 /* PROPS */, ["label", "icon-right"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_dropdown_item, { value: "view" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.l('setup-users/access-level-view')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_b_dropdown_item, { value: "edit" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.l('setup-users/access-level-edit')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _createElementVNode("div", {
                                  class: "remove-visibility",
                                  onClick: $event => ($options.removeUserVisibility(userVisibility.user_id))
                                }, [
                                  _createVNode(_component_b_icon, {
                                    icon: "delete-outline",
                                    "custom-size": "mdi-18px"
                                  })
                                ], 8 /* PROPS */, _hoisted_26)
                              ]))
                            }), 256 /* UNKEYED_FRAGMENT */))
                          ]),
                          _cache[38] || (_cache[38] = _createElementVNode("div", { class: "separator grey mb-4" }, null, -1 /* HOISTED */)),
                          _createElementVNode("h4", _hoisted_27, _toDisplayString(_ctx.l('setup-users/product-visibilities-title')), 1 /* TEXT */),
                          _createVNode(_component_b_field, {
                            class: "mb-5",
                            grouped: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_autocomplete, {
                                class: "mr-1",
                                modelValue: $data.productsSearchStr,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => (($data.productsSearchStr) = $event)),
                                placeholder: _ctx.l("setup-users/search-products") + "...",
                                icon: "magnify",
                                "open-on-focus": true,
                                "clear-on-select": true,
                                data: $options.filteredProducts,
                                field: "name",
                                onSelect: _cache[15] || (_cache[15] = option => $options.addProductVisibility(option)),
                                expanded: ""
                              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "data"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _createElementVNode("div", _hoisted_28, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.productVisibilities, (productVisibility) => {
                              return (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                _createElementVNode("div", _hoisted_30, [
                                  _createElementVNode("div", _hoisted_31, [
                                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.productsStore.byId(productVisibility.product_id).name), 1 /* TEXT */)
                                  ])
                                ]),
                                _createElementVNode("div", _hoisted_33, [
                                  _createVNode(_component_b_dropdown, {
                                    modelValue: productVisibility.access_level,
                                    "onUpdate:modelValue": $event => ((productVisibility.access_level) = $event),
                                    "aria-role": "list",
                                    position: "is-top-left"
                                  }, {
                                    trigger: _withCtx(({ active }) => [
                                      _createVNode(_component_b_field, null, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_b_button, {
                                            label: $options.accessLevelLabel(productVisibility.access_level),
                                            type: "is-select",
                                            expanded: "",
                                            size: "is-small",
                                            "icon-right": active ? "chevron-up" : "chevron-down"
                                          }, null, 8 /* PROPS */, ["label", "icon-right"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1024 /* DYNAMIC_SLOTS */)
                                    ]),
                                    default: _withCtx(() => [
                                      _createVNode(_component_b_dropdown_item, { value: "limited_view" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.l('setup-users/access-level-limited-view')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_b_dropdown_item, { value: "view" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.l('setup-users/access-level-view')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      }),
                                      _createVNode(_component_b_dropdown_item, { value: "edit" }, {
                                        default: _withCtx(() => [
                                          _createTextVNode(_toDisplayString(_ctx.l('setup-users/access-level-edit')), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                      })
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"])
                                ]),
                                _createElementVNode("div", {
                                  class: "remove-visibility",
                                  onClick: $event => ($options.removeProductVisibility(productVisibility.product_id))
                                }, [
                                  _createVNode(_component_b_icon, {
                                    icon: "delete-outline",
                                    "custom-size": "mdi-18px"
                                  })
                                ], 8 /* PROPS */, _hoisted_34)
                              ]))
                            }), 256 /* UNKEYED_FRAGMENT */))
                          ]),
                          _cache[39] || (_cache[39] = _createElementVNode("div", { class: "separator grey mb-4" }, null, -1 /* HOISTED */)),
                          _createElementVNode("div", _hoisted_35, [
                            _createElementVNode("h4", _hoisted_36, _toDisplayString(_ctx.l('setup-users/assign-rights-title')), 1 /* TEXT */),
                            _createVNode(_component_b_field, null, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_taginput, {
                                  ref: "assignRightsTaginput",
                                  modelValue: $options.computedAssignRights,
                                  autocomplete: "",
                                  data: $data.filteredAssignRightProducts,
                                  "open-on-focus": true,
                                  "allow-new": false,
                                  "dropdown-position": "top",
                                  ellipsis: "",
                                  rounded: "",
                                  field: "productName",
                                  placeholder: _ctx.l("setup-users/search-products") + "...",
                                  onFocus: _cache[16] || (_cache[16] = () => $options.getFilteredAssignRightProducts("")),
                                  onTyping: $options.getFilteredAssignRightProducts,
                                  onAdd: _cache[17] || (_cache[17] = (product) => $options.addAssignRightProduct(product)),
                                  onRemove: _cache[18] || (_cache[18] = (product) => $options.removeAssignRightProduct(product))
                                }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder", "onTyping"])
                              ]),
                              _: 1 /* STABLE */
                            })
                          ])
                        ]))
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_37, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[19] || (_cache[19] = $event => ($options.closeAddEditUserModal())),
                disabled: $data.userInfoModalLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]),
              _createVNode(_component_b_button, {
                class: "ml-5",
                type: "is-primary",
                rounded: "",
                "native-type": "submit",
                disabled: $data.userInfoModalLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"])
            ])
          ], 32 /* NEED_HYDRATION */),
          _createVNode(_component_b_loading, {
            "is-full-page": false,
            modelValue: $data.userInfoModalLoading,
            "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => (($data.userInfoModalLoading) = $event)),
            animation: "none"
          }, null, 8 /* PROPS */, ["modelValue"])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteUserConfirmModalActive,
      "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => (($data.deleteUserConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteUser
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_38, [
          _createElementVNode("div", _hoisted_39, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-users/confirm-delete-user-text", $data.userToBeDeleted.fullName)
            }, null, 8 /* PROPS */, _hoisted_40),
            _createElementVNode("div", _hoisted_41, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[23] || (_cache[23] = $event => ($options.closeDeleteUser()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[24] || (_cache[24] = $event => ($options.deleteUser()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.resetPasswordConfirmModalActive,
      "onUpdate:modelValue": _cache[28] || (_cache[28] = $event => (($data.resetPasswordConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [],
      "on-cancel": $options.closeResetPasswordModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_42, [
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("setup-users/confirm-reset-pwd-text", $data.userToPwdReset.fullName)
            }, null, 8 /* PROPS */, _hoisted_44),
            _createElementVNode("div", _hoisted_45, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[26] || (_cache[26] = $event => ($options.closeResetPasswordModal())),
                disabled: $data.resetPasswordLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["disabled"]),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[27] || (_cache[27] = $event => ($options.resetUserPassword())),
                loading: $data.resetPasswordLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/reset')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.newUserPasswordModalActive,
      "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => (($data.newUserPasswordModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [],
      "on-cancel": $options.closeNewUserPasswordModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_46, [
          _createElementVNode("div", _hoisted_47, [
            _createElementVNode("p", {
              class: "modal-text mb-5",
              innerHTML: _ctx.l_escaped("setup-users/user-pwd-reset-new-password", $data.userToPwdReset.email, $data.newUserPassword),
              style: {"margin-top":"16px"}
            }, null, 8 /* PROPS */, _hoisted_48),
            _createElementVNode("div", _hoisted_49, [
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[29] || (_cache[29] = $event => ($options.closeNewUserPasswordModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}