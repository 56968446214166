//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }          from 'pinia'

import * as appConfig           from '../app.config.js'
import { apiRequest }           from '../api_request.js'

import router                   from '../router/index.js'
import socket                   from '../socket.js'

import { useRootStore }         from './root.js'
import { useUsersStore }        from './users.js'


const api = {
    'auth':                 [ 'POST',       '/auth/local'],
    'me':                   [ 'GET',        '/users/me' ],
    'ping':                 [ 'GET',        '/users/ping' ],
}


export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            isLoggedIn: false,
            userId:     null,
        }
    },


    getters: {
        user() {
            if(!this.userId) return null

            const usersStore = useUsersStore()

            const userInfo = usersStore.byId(this.userId)

            if(!userInfo || !userInfo._id) return null

            return userInfo
        },

        isAdmin() {
            return appConfig.USER_ROLES.indexOf(this.user?.role) >= appConfig.USER_ROLES.indexOf('admin')
        },

        isSuperAdmin() {
            return appConfig.USER_ROLES.indexOf(this.user?.role) >= appConfig.USER_ROLES.indexOf('superadmin')
        }
    },


    actions: {
        async login({ email, password, staySignedIn }) {
            const options = {
                method:         api.auth[0],
                headers:        { 'Content-Type': 'application/json' },
                credentials:    'include',
                body:           JSON.stringify({ email, password, staySignedIn })
            }
    
            const response = await fetch(`${appConfig.API_URL}${api.auth[1]}`, options)
    
            if(!response.ok) {
                const error = new Error('Authentication failed')
                error.statusCode = response.status
                throw error
            }
    
            try {
                const user = await response.json()

                const usersStore = useUsersStore()
                usersStore.ADD(user)

                this.userId = user._id

                this.isLoggedIn = true

            } catch(error) {
                const authError = new Error(`Failed to parse authentication response: ${error.message}`)
                throw authError
            }
    
        },


        async authenticate() {
            // Auth by fetching userinfo
            // apiRequest will throw an error if we aren't logged in
            const user = await apiRequest(...api.me, null, false)

            const usersStore = useUsersStore()
            usersStore.ADD(user)

            this.userId = user._id

            this.isLoggedIn = true
        },


        async ping() {
            await apiRequest(...api.ping)
        },

    
        async logout(softLogout = false) {
            const rootStore = useRootStore()

            // Disconnect socket on logout
            socket.disconnect()

            if(!softLogout) {
                const options = {
                    method:         'GET',
                    credentials:    'include',
                    headers:        {}
                }

                if(socket.id) {
                    options['headers']['X-Socket-ID'] = socket.id
                }
        
                const response = await fetch(`${appConfig.API_URL}/auth/local/logout`, options)
        
                if(!response.ok) {
                    const error = new Error('Logout failed')
                    error.statusCode = response.status
                    throw error
                }
            }
    
            rootStore.resetStore()

            this.userId = null
            this.isLoggedIn = false
        
            router.push({ name: 'login' })
        },
    
    }
})
