import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from 'FRONTEND/images/linkedin-icon.svg'


const _hoisted_1 = { class: "search-container" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "mb-5" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "bold" }
const _hoisted_6 = { class: "greytext semibold" }
const _hoisted_7 = { class: "contact-container" }
const _hoisted_8 = { class: "contact-info" }
const _hoisted_9 = { class: "bold" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = {
  key: 2,
  class: "ml-2"
}
const _hoisted_14 = { key: 1 }
const _hoisted_15 = ["href"]
const _hoisted_16 = {
  key: 0,
  class: "contact-linkedin"
}
const _hoisted_17 = ["href"]
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "mb-4 greytext semibold" }
const _hoisted_20 = ["onClick"]
const _hoisted_21 = { class: "greytext semibold uppercase" }
const _hoisted_22 = { class: "bold" }
const _hoisted_23 = {
  key: 1,
  class: "search-result-box"
}
const _hoisted_24 = { class: "contact-container" }
const _hoisted_25 = { class: "contact-info" }
const _hoisted_26 = { class: "greytext semibold uppercase" }
const _hoisted_27 = { class: "bold" }
const _hoisted_28 = { key: 0 }
const _hoisted_29 = { key: 0 }
const _hoisted_30 = { key: 0 }
const _hoisted_31 = {
  key: 2,
  class: "ml-2"
}
const _hoisted_32 = { key: 1 }
const _hoisted_33 = ["href"]
const _hoisted_34 = {
  key: 0,
  class: "contact-linkedin"
}
const _hoisted_35 = ["href"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!$options.searchActive)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _cache[1] || (_cache[1] = _createElementVNode("div", { class: "greytext smallertext mb-4" }, "Recently added leads", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.recentLeads, (lead) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "search-result-box is-clickable",
                key: "recent_lead_" + lead._id,
                onClick: $event => ($options.goToLead(lead))
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("span", _hoisted_5, _toDisplayString(lead.name), 1 /* TEXT */)
                ]),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.l('search/resource-added')) + " : " + _toDisplayString(_ctx.$dayjs(lead.creation_timestamp).format('ddd, ll')), 1 /* TEXT */)
              ], 8 /* PROPS */, _hoisted_4))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _createElementVNode("div", null, [
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "greytext smallertext mb-4" }, "Recently added contacts", -1 /* HOISTED */)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.recentContacts, (contact) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "search-result-box",
                key: "recent_contact_" + contact._id
              }, [
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_9, _toDisplayString(contact.fullName), 1 /* TEXT */),
                      (contact.title)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_10, " (" + _toDisplayString(contact.title) + ")", 1 /* TEXT */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    (contact.telephone || contact.telephone2)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                          (contact.telephone)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_12, _toDisplayString(contact.telephone), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true),
                          (contact.telephone)
                            ? (_openBlock(), _createBlock(_component_b_icon, {
                                key: 1,
                                icon: "content-copy",
                                "custom-class": "clipboard-copy-icon is-clickable",
                                onClick: $event => ($options.copyToClipboard(contact.telephone))
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true),
                          (contact.telephone2)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_13, _toDisplayString(contact.telephone2), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true),
                          (contact.telephone2)
                            ? (_openBlock(), _createBlock(_component_b_icon, {
                                key: 3,
                                icon: "content-copy",
                                "custom-class": "clipboard-copy-icon is-clickable",
                                onClick: $event => ($options.copyToClipboard(contact.telephone2))
                              }, null, 8 /* PROPS */, ["onClick"]))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : _createCommentVNode("v-if", true),
                    (contact.email)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                          _createElementVNode("a", {
                            href: "mailto:" + contact.email
                          }, _toDisplayString(contact.email), 9 /* TEXT, PROPS */, _hoisted_15),
                          _createVNode(_component_b_icon, {
                            icon: "content-copy",
                            "custom-class": "clipboard-copy-icon is-clickable",
                            onClick: $event => ($options.copyToClipboard(contact.email))
                          }, null, 8 /* PROPS */, ["onClick"])
                        ]))
                      : _createCommentVNode("v-if", true),
                    (_ctx.contactsStore.contactsWithLeadsMap.has(contact._id))
                      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get(contact._id), (contactLead) => {
                          return (_openBlock(), _createElementBlock("div", {
                            class: "ml-3 greytext semibold",
                            key: "recent_contact_lead_" + contactLead._id
                          }, _toDisplayString(contactLead.name), 1 /* TEXT */))
                        }), 128 /* KEYED_FRAGMENT */))
                      : _createCommentVNode("v-if", true)
                  ]),
                  (contact.linkedin)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                        _createElementVNode("a", {
                          href: contact.linkedin,
                          target: "_blank",
                          title: "LinkedIn"
                        }, _cache[2] || (_cache[2] = [
                          _createElementVNode("img", {
                            src: _imports_0,
                            width: "30"
                          }, null, -1 /* HOISTED */)
                        ]), 8 /* PROPS */, _hoisted_17)
                      ]))
                    : _createCommentVNode("v-if", true)
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.l('search/search-results')) + ":", 1 /* TEXT */),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.searchResults, (result, idx) => {
            return (_openBlock(), _createElementBlock(_Fragment, {
              key: "search_result_" + idx
            }, [
              _createCommentVNode(""),
              _createCommentVNode(" LEAD search result"),
              _createCommentVNode(""),
              (result.type === "lead")
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "search-result-box is-clickable",
                    onClick: $event => ($options.goToLead(result.resource))
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.l('generic/lead')), 1 /* TEXT */),
                      _cache[4] || (_cache[4] = _createTextVNode()),
                      _createElementVNode("span", _hoisted_22, _toDisplayString(result.resource.name), 1 /* TEXT */)
                    ])
                  ], 8 /* PROPS */, _hoisted_20))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(""),
              _createCommentVNode(" CONTACT search result"),
              _createCommentVNode(""),
              (result.type === "contact")
                ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("div", _hoisted_25, [
                        _createElementVNode("div", null, [
                          _createElementVNode("span", _hoisted_26, _toDisplayString(_ctx.l('generic/contact')), 1 /* TEXT */),
                          _cache[5] || (_cache[5] = _createTextVNode()),
                          _createElementVNode("span", _hoisted_27, _toDisplayString(result.resource.fullName), 1 /* TEXT */),
                          (result.resource.title)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_28, " (" + _toDisplayString(result.resource.title) + ")", 1 /* TEXT */))
                            : _createCommentVNode("v-if", true)
                        ]),
                        (result.resource.telephone || result.resource.telephone2)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                              (result.resource.telephone)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_30, _toDisplayString(result.resource.telephone), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                              (result.resource.telephone)
                                ? (_openBlock(), _createBlock(_component_b_icon, {
                                    key: 1,
                                    icon: "content-copy",
                                    "custom-class": "clipboard-copy-icon is-clickable",
                                    onClick: $event => ($options.copyToClipboard(result.resource.telephone))
                                  }, null, 8 /* PROPS */, ["onClick"]))
                                : _createCommentVNode("v-if", true),
                              (result.resource.telephone2)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_31, _toDisplayString(result.resource.telephone2), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                              (result.resource.telephone2)
                                ? (_openBlock(), _createBlock(_component_b_icon, {
                                    key: 3,
                                    icon: "content-copy",
                                    "custom-class": "clipboard-copy-icon is-clickable",
                                    onClick: $event => ($options.copyToClipboard(result.resource.telephone2))
                                  }, null, 8 /* PROPS */, ["onClick"]))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : _createCommentVNode("v-if", true),
                        (result.resource.email)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                              _createElementVNode("a", {
                                href: "mailto:" + result.resource.email
                              }, _toDisplayString(result.resource.email), 9 /* TEXT, PROPS */, _hoisted_33),
                              _createVNode(_component_b_icon, {
                                icon: "content-copy",
                                "custom-class": "clipboard-copy-icon is-clickable",
                                onClick: $event => ($options.copyToClipboard(result.resource.email))
                              }, null, 8 /* PROPS */, ["onClick"])
                            ]))
                          : _createCommentVNode("v-if", true),
                        (_ctx.contactsStore.contactsWithLeadsMap.has(result.resource._id))
                          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 2 }, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get(result.resource._id), (contactLead) => {
                              return (_openBlock(), _createElementBlock("div", {
                                class: "ml-3 greytext semibold",
                                key: "recent_contact_lead_" + contactLead._id
                              }, _toDisplayString(contactLead.name), 1 /* TEXT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          : _createCommentVNode("v-if", true)
                      ]),
                      (result.resource.linkedin)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_34, [
                            _createElementVNode("a", {
                              href: result.resource.linkedin,
                              target: "_blank",
                              title: "LinkedIn"
                            }, _cache[6] || (_cache[6] = [
                              _createElementVNode("img", {
                                src: _imports_0,
                                width: "30"
                              }, null, -1 /* HOISTED */)
                            ]), 8 /* PROPS */, _hoisted_35)
                          ]))
                        : _createCommentVNode("v-if", true)
                    ])
                  ]))
                : _createCommentVNode("v-if", true)
            ], 64 /* STABLE_FRAGMENT */))
          }), 128 /* KEYED_FRAGMENT */))
        ])),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: _ctx.searchLoading,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => ((_ctx.searchLoading) = $event)),
      animation: "none"
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}