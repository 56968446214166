import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page-content-inner rudolfbox" }
const _hoisted_2 = { class: "settings-page" }
const _hoisted_3 = { class: "settings-content" }
const _hoisted_4 = { class: "mb-5" }
const _hoisted_5 = { class: "settings-area" }
const _hoisted_6 = { class: "settings-bottom" }
const _hoisted_7 = { class: "buttons" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.l('setup-settings/title')), 1 /* TEXT */),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("h2", null, _toDisplayString(_ctx.l('setup-settings/lead-commenting-mode')), 1 /* TEXT */),
          _createElementVNode("div", null, [
            _createVNode(_component_b_field, { class: "mb-4" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_radio, {
                  class: "mr-6",
                  modelValue: $data.leadCommentingMode,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.leadCommentingMode) = $event)),
                  "native-value": "per_lead"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.l('setup-settings/mode-per-lead')), 1 /* TEXT */),
                    _cache[4] || (_cache[4] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                    _createTextVNode(_toDisplayString(_ctx.l('setup-settings/mode-per-lead-details')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_field, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_radio, {
                  class: "mr-6",
                  modelValue: $data.leadCommentingMode,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.leadCommentingMode) = $event)),
                  "native-value": "per_assignment"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.l('setup-settings/mode-per-assignment')), 1 /* TEXT */),
                    _cache[5] || (_cache[5] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                    _createTextVNode(_toDisplayString(_ctx.l('setup-settings/mode-per-assignment-details')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            })
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_button, {
            type: "is-primary",
            rounded: "",
            onClick: _cache[2] || (_cache[2] = $event => ($options.saveSettings())),
            disabled: $data.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.l('setup-settings/save-settings')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["disabled"])
        ])
      ]),
      _createVNode(_component_b_loading, {
        "is-full-page": false,
        modelValue: $data.loading,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.loading) = $event)),
        animation: "none"
      }, null, 8 /* PROPS */, ["modelValue"])
    ])
  ]))
}