import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createVNode as _createVNode, withCtx as _withCtx, TransitionGroup as _TransitionGroup } from "vue"

const _hoisted_1 = { class: "page-content-inner is-relative" }
const _hoisted_2 = { class: "activities-top-columns" }
const _hoisted_3 = { class: "rudolfbox activities-map is-hidden-mobile" }
const _hoisted_4 = { class: "has-text-centered rtitle sm mb-3" }
const _hoisted_5 = {
  key: 0,
  class: "meetings-list"
}
const _hoisted_6 = { class: "meetings-date greytext smallertext semibold" }
const _hoisted_7 = { class: "meetings-daily-list" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = { class: "meeting-text is-relative" }
const _hoisted_10 = { class: "semibold" }
const _hoisted_11 = { class: "bold" }
const _hoisted_12 = {
  key: 1,
  class: "has-text-centered mt-4 is-italic"
}
const _hoisted_13 = { class: "activities-bottom-columns" }
const _hoisted_14 = { class: "activities-bottom-left" }
const _hoisted_15 = { class: "rnotifications-top is-clickable" }
const _hoisted_16 = { class: "has-text-centered rtitle sm mb-3" }
const _hoisted_17 = { class: "rnotifications-top-buttons" }
const _hoisted_18 = {
  key: 1,
  style: {"padding":"3px"}
}
const _hoisted_19 = {
  key: 3,
  style: {"padding":"3px"}
}
const _hoisted_20 = {
  key: 0,
  class: "rnotifications-list"
}
const _hoisted_21 = ["onClick"]
const _hoisted_22 = { class: "rnotification-text" }
const _hoisted_23 = { class: "rnotification-time" }
const _hoisted_24 = ["innerHTML"]
const _hoisted_25 = { class: "rnotification-buttons" }
const _hoisted_26 = {
  key: 0,
  class: "rnotification-mark-as-read"
}
const _hoisted_27 = ["onClick"]
const _hoisted_28 = {
  key: 1,
  class: "rnotification-delete"
}
const _hoisted_29 = ["onClick"]
const _hoisted_30 = {
  key: 1,
  class: "has-text-centered mt-4 is-italic"
}
const _hoisted_31 = { class: "contacts-tab-header-number" }
const _hoisted_32 = { class: "contacts-tab-header-subtitle" }
const _hoisted_33 = { class: "control" }
const _hoisted_34 = { class: "call-list is-relative" }
const _hoisted_35 = ["onClick"]
const _hoisted_36 = {
  key: 0,
  class: "call-list-info"
}
const _hoisted_37 = { class: "call-list-name" }
const _hoisted_38 = { key: 0 }
const _hoisted_39 = { key: 1 }
const _hoisted_40 = {
  key: 0,
  class: "smallertext semibold ml-2"
}
const _hoisted_41 = ["href"]
const _hoisted_42 = {
  key: 1,
  class: "smallertext semibold ml-2"
}
const _hoisted_43 = ["href"]
const _hoisted_44 = {
  key: 2,
  class: "smallertext semibold ml-2"
}
const _hoisted_45 = { class: "smalltext greytext ml-4" }
const _hoisted_46 = {
  key: 1,
  class: "call-list-info"
}
const _hoisted_47 = { class: "call-list-name" }
const _hoisted_48 = {
  key: 0,
  class: "smalltext greytext ml-2"
}
const _hoisted_49 = ["onClick"]
const _hoisted_50 = ["onClick"]
const _hoisted_51 = { class: "call-list-pagination" }
const _hoisted_52 = { class: "pagination-left" }
const _hoisted_53 = { class: "call-list-items-per-page" }
const _hoisted_54 = { class: "call-list-items-per-page-dropdown" }
const _hoisted_55 = { class: "call-list-items-per-page-text" }
const _hoisted_56 = { class: "pagination-right" }
const _hoisted_57 = ["onClick"]
const _hoisted_58 = { class: "contacts-tab-header-number" }
const _hoisted_59 = { class: "contacts-tab-header-subtitle" }
const _hoisted_60 = {
  key: 0,
  class: "control"
}
const _hoisted_61 = { class: "contact-list is-relative" }
const _hoisted_62 = ["onClick"]
const _hoisted_63 = { class: "contact-info" }
const _hoisted_64 = { class: "contact-name" }
const _hoisted_65 = { key: 0 }
const _hoisted_66 = { key: 1 }
const _hoisted_67 = { class: "contact-lead smalltext greytext ml-2" }
const _hoisted_68 = {
  key: 0,
  class: "contact-add-to-call-list is-clickable pr-2 pl-1"
}
const _hoisted_69 = ["onClick"]
const _hoisted_70 = { class: "contact-dropdown-content" }
const _hoisted_71 = ["onClick"]
const _hoisted_72 = { class: "mr-2" }
const _hoisted_73 = { class: "noselect" }
const _hoisted_74 = ["onClick"]
const _hoisted_75 = { class: "mr-2" }
const _hoisted_76 = { class: "noselect" }
const _hoisted_77 = { class: "contacts-pagination" }
const _hoisted_78 = { class: "pagination-left" }
const _hoisted_79 = { class: "contacts-per-page" }
const _hoisted_80 = { class: "contacts-per-page-text" }
const _hoisted_81 = { class: "pagination-right" }
const _hoisted_82 = ["onClick"]
const _hoisted_83 = { class: "todo-top" }
const _hoisted_84 = { class: "todo-top-title" }
const _hoisted_85 = { class: "control" }
const _hoisted_86 = { class: "is-flex is-align-items-center" }
const _hoisted_87 = { class: "mr-2 smalltext bold show-dropdown-text" }
const _hoisted_88 = { class: "mr-2" }
const _hoisted_89 = { class: "todo-show-due-dates" }
const _hoisted_90 = { class: "todo-date greytext smallertext semibold" }
const _hoisted_91 = { class: "todo-daily-list" }
const _hoisted_92 = ["onClick"]
const _hoisted_93 = { class: "todo-dropdown-content" }
const _hoisted_94 = ["onClick"]
const _hoisted_95 = { class: "mr-2" }
const _hoisted_96 = { class: "noselect" }
const _hoisted_97 = ["onClick"]
const _hoisted_98 = { class: "mr-2" }
const _hoisted_99 = { class: "noselect" }
const _hoisted_100 = { class: "greytext smallertext semibold" }
const _hoisted_101 = { class: "rudolfbox" }
const _hoisted_102 = { class: "modal-inner add-edit-contact-modal" }
const _hoisted_103 = {
  key: 0,
  class: "rtitle modal-title mb-5"
}
const _hoisted_104 = {
  key: 1,
  class: "rtitle modal-title mb-5"
}
const _hoisted_105 = { class: "buttons right mb-0" }
const _hoisted_106 = { class: "rudolfbox" }
const _hoisted_107 = { class: "modal-inner has-text-centered" }
const _hoisted_108 = ["innerHTML"]
const _hoisted_109 = { class: "buttons mb-0" }
const _hoisted_110 = { class: "rudolfbox" }
const _hoisted_111 = { class: "modal-inner add-edit-contact-modal" }
const _hoisted_112 = {
  key: 0,
  class: "rtitle modal-title mb-5"
}
const _hoisted_113 = {
  key: 1,
  class: "rtitle modal-title mb-5"
}
const _hoisted_114 = { class: "rtitle sm mb-2" }
const _hoisted_115 = { style: {"list-style-type":"disc","margin-left":"30px","margin-bottom":"10px"} }
const _hoisted_116 = { class: "smallertext semibold" }
const _hoisted_117 = { class: "buttons right mb-0" }
const _hoisted_118 = { class: "rudolfbox" }
const _hoisted_119 = { class: "modal-inner add-edit-todo-modal" }
const _hoisted_120 = {
  key: 0,
  class: "rtitle modal-title mb-5"
}
const _hoisted_121 = {
  key: 1,
  class: "rtitle modal-title mb-5"
}
const _hoisted_122 = { class: "buttons right mb-0" }
const _hoisted_123 = { class: "rudolfbox" }
const _hoisted_124 = { class: "modal-inner has-text-centered" }
const _hoisted_125 = ["innerHTML"]
const _hoisted_126 = ["innerHTML"]
const _hoisted_127 = ["innerHTML"]
const _hoisted_128 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_google_map = _resolveComponent("google-map")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_loading = _resolveComponent("b-loading")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_tabs = _resolveComponent("b-tabs")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_modal = _resolveComponent("b-modal")
  const _component_b_autocomplete = _resolveComponent("b-autocomplete")
  const _component_b_datepicker = _resolveComponent("b-datepicker")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_ctx.googleMapsLoaded)
          ? (_openBlock(), _createBlock(_component_google_map, {
              key: 0,
              ref: "googleMapDefault",
              "map-id": "manage-leads-default-map",
              "min-zoom": 3,
              forceLeadIds: $options.computedFilteredLeadIds,
              refocusOnLeadsChange: true
            }, null, 8 /* PROPS */, ["forceLeadIds"]))
          : _createCommentVNode("v-if", true)
      ]),
      _createCommentVNode(""),
      _createCommentVNode(""),
      _createCommentVNode(" ███╗   ███╗███████╗███████╗████████╗███╗   ██╗ ██████╗ ███████╗"),
      _createCommentVNode(" ████╗ ████║██╔════╝██╔════╝╚══██╔══╝████╗  ██║██╔════╝ ██╔════╝"),
      _createCommentVNode(" ██╔████╔██║█████╗  █████╗     ██║   ██╔██╗ ██║██║  ███╗███████╗"),
      _createCommentVNode(" ██║╚██╔╝██║██╔══╝  ██╔══╝     ██║   ██║╚██╗██║██║   ██║╚════██║"),
      _createCommentVNode(" ██║ ╚═╝ ██║███████╗███████╗   ██║   ██║ ╚████║╚██████╔╝███████║"),
      _createCommentVNode(" ╚═╝     ╚═╝╚══════╝╚══════╝   ╚═╝   ╚═╝  ╚═══╝ ╚═════╝ ╚══════╝"),
      _createCommentVNode(""),
      _createCommentVNode(""),
      _createElementVNode("div", {
        class: _normalizeClass(["rudolfbox activities-meetings", { "active": $data.activeSection === "meetings" && !this.selectedMeetingId && !_ctx.isMobile }]),
        onClick: _cache[0] || (_cache[0] = $event => {$data.activeSection = "meetings"; $data.selectedMeetingId = null})
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["meetings-top", { "is-clickable": !_ctx.isMobile }])
        }, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.l('activities/meetings')), 1 /* TEXT */)
        ], 2 /* CLASS */),
        ($options.computedMeetings.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedMeetings, (dailyMeetings, idx) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "meetings-daily",
                  key: "meetings_daily_list_" + idx
                }, [
                  _createElementVNode("div", _hoisted_6, _toDisplayString(dailyMeetings.dateStr), 1 /* TEXT */),
                  _createElementVNode("div", _hoisted_7, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dailyMeetings.items, (meeting) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["meeting-daily-item is-clickable", { "selected": $data.selectedMeetingId && $data.selectedMeetingId === meeting.leadProduct }]),
                        key: "meeting_item_" + meeting.leadProduct,
                        onClick: _withModifiers($event => ($options.selectMeeting(meeting.leadProduct)), ["stop"])
                      }, [
                        _createElementVNode("div", _hoisted_9, [
                          _createElementVNode("span", _hoisted_10, _toDisplayString(meeting.timeStr), 1 /* TEXT */),
                          _cache[64] || (_cache[64] = _createTextVNode()),
                          _createElementVNode("span", _hoisted_11, _toDisplayString(meeting.leadName), 1 /* TEXT */),
                          _createTextVNode(" / " + _toDisplayString(meeting.productName), 1 /* TEXT */)
                        ])
                      ], 10 /* CLASS, PROPS */, _hoisted_8))
                    }), 128 /* KEYED_FRAGMENT */))
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.l('activities/no-meetings')), 1 /* TEXT */))
      ], 2 /* CLASS */)
    ]),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode(" ███╗   ██╗ ██████╗ ████████╗██╗███████╗"),
        _createCommentVNode(" ████╗  ██║██╔═══██╗╚══██╔══╝██║██╔════╝"),
        _createCommentVNode(" ██╔██╗ ██║██║   ██║   ██║   ██║█████╗"),
        _createCommentVNode(" ██║╚██╗██║██║   ██║   ██║   ██║██╔══╝"),
        _createCommentVNode(" ██║ ╚████║╚██████╔╝   ██║   ██║██║"),
        _createCommentVNode(" ╚═╝  ╚═══╝ ╚═════╝    ╚═╝   ╚═╝╚═╝"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createElementVNode("div", {
          class: _normalizeClass(["rudolfbox activities-notifications is-hidden-mobile", { "active": $data.activeSection === "notifications"}]),
          onClick: _cache[3] || (_cache[3] = $event => ($data.activeSection = "notifications"))
        }, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, [
              _createTextVNode(_toDisplayString(_ctx.l('notifications/notifications')) + " ", 1 /* TEXT */),
              (_ctx.notificationsStore.numUnread > 0)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode("(" + _toDisplayString(_ctx.notificationsStore.numUnread) + ")", 1 /* TEXT */)
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ]),
            _createElementVNode("div", _hoisted_17, [
              (_ctx.notificationsStore.numUnread > 0)
                ? (_openBlock(), _createBlock(_component_b_tooltip, {
                    key: 0,
                    label: _ctx.l("notifications/mark-all-as-read"),
                    type: "is-white",
                    position: "is-top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "is-clickable",
                        style: {"padding":"3px"},
                        onClick: _cache[1] || (_cache[1] = $event => (_ctx.notificationsStore.markAllAsRead()))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: "playlist-check",
                          type: "is-success"
                        })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createVNode(_component_b_icon, {
                      icon: "playlist-check",
                      "custom-class": "disabled-icon"
                    })
                  ])),
              (_ctx.notificationsStore.numRead > 0)
                ? (_openBlock(), _createBlock(_component_b_tooltip, {
                    key: 2,
                    label: _ctx.l("notifications/remove-all-read"),
                    type: "is-white",
                    position: "is-top"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: "is-clickable",
                        style: {"padding":"3px"},
                        onClick: _cache[2] || (_cache[2] = $event => (_ctx.notificationsStore.deleteAllRead()))
                      }, [
                        _createVNode(_component_b_icon, { icon: "trash-can-outline" })
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]))
                : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                    _createVNode(_component_b_icon, {
                      icon: "trash-can-outline",
                      "custom-class": "disabled-icon"
                    })
                  ]))
            ])
          ]),
          (_ctx.notifications.length > 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createVNode(_TransitionGroup, { name: "rnotification-list" }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.notifications, (notification) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["rnotification", { "read": notification.read }]),
                        key: "activities_notification_" + notification._id,
                        onClick: _withModifiers($event => (_ctx.notificationsStore.notificationClick(notification)), ["stop"])
                      }, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$dayjs(notification.created_at).format('LLL')), 1 /* TEXT */),
                          _cache[65] || (_cache[65] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                          _createElementVNode("span", {
                            innerHTML: $options.formatNotificationMessage(notification)
                          }, null, 8 /* PROPS */, _hoisted_24)
                        ]),
                        _createElementVNode("div", _hoisted_25, [
                          (notification.read !== true)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                                _createVNode(_component_b_tooltip, {
                                  label: _ctx.l("notifications/mark-as-read"),
                                  type: "is-white",
                                  position: "is-left"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "is-clickable",
                                      style: {"padding":"3px"},
                                      onClick: _withModifiers($event => {_ctx.notificationsStore.markAsRead(notification); $data.activeSection = "notifications"}, ["stop"])
                                    }, [
                                      _createVNode(_component_b_icon, {
                                        icon: "check",
                                        type: "is-success"
                                      })
                                    ], 8 /* PROPS */, _hoisted_27)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                              ]))
                            : (_openBlock(), _createElementBlock("div", _hoisted_28, [
                                _createVNode(_component_b_tooltip, {
                                  label: _ctx.l("notifications/remove-notification"),
                                  type: "is-white",
                                  position: "is-left"
                                }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("div", {
                                      class: "is-clickable",
                                      style: {"padding":"3px"},
                                      onClick: _withModifiers($event => {_ctx.notificationsStore.deleteNotification(notification); $data.activeSection = "notifications"}, ["stop"])
                                    }, [
                                      _createVNode(_component_b_icon, { icon: "close" })
                                    ], 8 /* PROPS */, _hoisted_29)
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                              ]))
                        ])
                      ], 10 /* CLASS, PROPS */, _hoisted_21))
                    }), 128 /* KEYED_FRAGMENT */))
                  ]),
                  _: 1 /* STABLE */
                })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(_ctx.l('notifications/no-new-notifications')), 1 /* TEXT */))
        ], 2 /* CLASS */),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createCommentVNode("  ██████╗ ██████╗ ███╗   ██╗████████╗ █████╗  ██████╗████████╗███████╗"),
        _createCommentVNode(" ██╔════╝██╔═══██╗████╗  ██║╚══██╔══╝██╔══██╗██╔════╝╚══██╔══╝██╔════╝"),
        _createCommentVNode(" ██║     ██║   ██║██╔██╗ ██║   ██║   ███████║██║        ██║   ███████╗"),
        _createCommentVNode(" ██║     ██║   ██║██║╚██╗██║   ██║   ██╔══██║██║        ██║   ╚════██║"),
        _createCommentVNode(" ╚██████╗╚██████╔╝██║ ╚████║   ██║   ██║  ██║╚██████╗   ██║   ███████║"),
        _createCommentVNode("  ╚═════╝ ╚═════╝ ╚═╝  ╚═══╝   ╚═╝   ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚══════╝"),
        _createCommentVNode(""),
        _createCommentVNode(""),
        _createElementVNode("div", {
          class: _normalizeClass(["rudolfbox activities-contacts", { "active": $data.activeSection === "contacts" && !this.selectedContactId && !_ctx.isMobile }]),
          onClick: _cache[21] || (_cache[21] = $event => {$data.activeSection = "contacts"; $data.selectedContactId = null})
        }, [
          _createElementVNode("div", {
            class: "contacts-top",
            onClick: _cache[4] || (_cache[4] = $event => {$data.activeSection = "contacts"; $data.selectedContactId = null})
          }, _toDisplayString(_ctx.l('activities/contacts-title')), 1 /* TEXT */),
          _createVNode(_component_b_tabs, { position: "is-centered" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_tab_item, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.callList.length), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.l('activities/to-call-list')), 1 /* TEXT */)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_field, {
                    class: "searchfield mb-2",
                    grouped: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        expanded: "",
                        modelValue: $data.callListSearchStr,
                        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.callListSearchStr) = $event)),
                        icon: "magnify",
                        "custom-class": "square product-search-input",
                        placeholder: _ctx.l("generic/search") + "...",
                        "icon-right": $data.callListSearchStr ? "close-circle" : "",
                        "icon-right-clickable": $data.callListSearchStr ? true : false,
                        onIconRightClick: _cache[6] || (_cache[6] = $event => ($data.callListSearchStr = ""))
                      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "icon-right", "icon-right-clickable"]),
                      _createElementVNode("p", _hoisted_33, [
                        _createVNode(_component_b_tooltip, {
                          label: _ctx.l("activities/add-to-call-list-manual"),
                          type: "is-white",
                          position: "is-left"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_b_button, {
                              class: "icon-button",
                              type: "is-light",
                              size: "is-large",
                              onClick: _cache[7] || (_cache[7] = $event => ($options.openAddEditCallListItemModal())),
                              disabled: $data.callListLoading
                            }, {
                              default: _withCtx(() => _cache[66] || (_cache[66] = [
                                _createTextVNode("+")
                              ])),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["disabled"])
                          ]),
                          _: 1 /* STABLE */
                        }, 8 /* PROPS */, ["label"])
                      ])
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createElementVNode("div", _hoisted_34, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedCallList, (callListItem) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["call-list-item is-relative is-clickable", { "selected": $data.selectedContactId && callListItem.contact && $data.selectedContactId === callListItem.contact }]),
                        key: "contact_list_contact_" + callListItem._id,
                        onClick: _withModifiers($event => ($options.selectContact(callListItem.contact)), ["stop"])
                      }, [
                        (callListItem.contact)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
                              _createElementVNode("div", _hoisted_37, [
                                (_ctx.contactsStore.byId(callListItem.contact).title2)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_38, _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).title2), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true),
                                _createTextVNode(" " + _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).fullName), 1 /* TEXT */),
                                (_ctx.contactsStore.byId(callListItem.contact).title)
                                  ? (_openBlock(), _createElementBlock("span", _hoisted_39, " (" + _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).title) + ")", 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true)
                              ]),
                              (_ctx.contactsStore.byId(callListItem.contact).telephone)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                    _createElementVNode("a", {
                                      href: "tel:" + _ctx.contactsStore.byId(callListItem.contact).telephone
                                    }, _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).telephone), 9 /* TEXT, PROPS */, _hoisted_41)
                                  ]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.contactsStore.byId(callListItem.contact).telephone2)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                    _createElementVNode("a", {
                                      href: "tel:" + _ctx.contactsStore.byId(callListItem.contact).telephone2
                                    }, _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).telephone2), 9 /* TEXT, PROPS */, _hoisted_43)
                                  ]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.contactsStore.byId(callListItem.contact).notes)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                                    _cache[67] || (_cache[67] = _createElementVNode("strong", null, "Note: ", -1 /* HOISTED */)),
                                    _createTextVNode(" " + _toDisplayString(_ctx.contactsStore.byId(callListItem.contact).notes), 1 /* TEXT */)
                                  ]))
                                : _createCommentVNode("v-if", true),
                              (_ctx.contactsStore.contactsWithLeadsMap.has(callListItem.contact))
                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                    _cache[68] || (_cache[68] = _createElementVNode("strong", { class: "ml-2 smalltext" }, "Leads: ", -1 /* HOISTED */)),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get(callListItem.contact), (lead) => {
                                      return (_openBlock(), _createElementBlock("div", _hoisted_45, "- " + _toDisplayString(lead.name), 1 /* TEXT */))
                                    }), 256 /* UNKEYED_FRAGMENT */))
                                  ], 64 /* STABLE_FRAGMENT */))
                                : _createCommentVNode("v-if", true)
                            ]))
                          : (_openBlock(), _createElementBlock("div", _hoisted_46, [
                              _createElementVNode("div", _hoisted_47, _toDisplayString(callListItem.firstName) + " " + _toDisplayString(callListItem.lastName), 1 /* TEXT */),
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(callListItem.phoneNumbers, (phoneNumber, idx) => {
                                return (_openBlock(), _createElementBlock("div", {
                                  class: "smallertext semibold ml-2",
                                  key: "call_list_item_" + callListItem._id + "_phone_" + idx
                                }, _toDisplayString(phoneNumber), 1 /* TEXT */))
                              }), 128 /* KEYED_FRAGMENT */)),
                              (callListItem.company)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_48, _toDisplayString(callListItem.company), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true)
                            ])),
                        (!callListItem.contact)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 2,
                              class: "call-list-item-edit is-clickable",
                              onClick: _withModifiers($event => {$options.openAddEditCallListItemModal(callListItem); $data.activeSection = "contacts"}, ["stop"])
                            }, [
                              _createVNode(_component_b_icon, { icon: "pencil-outline" })
                            ], 8 /* PROPS */, _hoisted_49))
                          : _createCommentVNode("v-if", true),
                        _createElementVNode("div", {
                          class: "call-list-item-delete is-clickable",
                          onClick: _withModifiers($event => {$options.openDeleteCallListItemConfirmModal(callListItem); $data.activeSection = "contacts"}, ["stop"])
                        }, [
                          _createVNode(_component_b_icon, { icon: "delete-outline" })
                        ], 8 /* PROPS */, _hoisted_50),
                        _createVNode(_component_b_loading, {
                          "is-full-page": false,
                          modelValue: $data.callListItemsLoading.has(callListItem._id),
                          animation: "none"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ], 10 /* CLASS, PROPS */, _hoisted_35))
                    }), 128 /* KEYED_FRAGMENT */)),
                    _createVNode(_component_b_loading, {
                      "is-full-page": false,
                      modelValue: $data.callListLoading,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => (($data.callListLoading) = $event)),
                      animation: "none"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_51, [
                    _createElementVNode("div", _hoisted_52, [
                      _createElementVNode("div", _hoisted_53, [
                        _createElementVNode("div", _hoisted_54, [
                          _createVNode(_component_b_dropdown, {
                            modelValue: $data.callListItemsPerPage,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => (($data.callListItemsPerPage) = $event)),
                            "aria-role": "list",
                            position: "is-top-right"
                          }, {
                            trigger: _withCtx(({ active }) => [
                              _createVNode(_component_b_field, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_button, {
                                    label: String($data.callListItemsPerPage),
                                    type: "is-select",
                                    expanded: "",
                                    size: "is-small",
                                    "icon-right": active ? "menu-up" : "menu-down"
                                  }, null, 8 /* PROPS */, ["label", "icon-right"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "50"
                              }, {
                                default: _withCtx(() => _cache[69] || (_cache[69] = [
                                  _createTextVNode("50")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "250"
                              }, {
                                default: _withCtx(() => _cache[70] || (_cache[70] = [
                                  _createTextVNode("250")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "500"
                              }, {
                                default: _withCtx(() => _cache[71] || (_cache[71] = [
                                  _createTextVNode("500")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "1000"
                              }, {
                                default: _withCtx(() => _cache[72] || (_cache[72] = [
                                  _createTextVNode("1000")
                                ])),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_55, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.l('activities/per-page').toUpperCase()), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_56, [
                      _createElementVNode("div", {
                        class: "pagination-button prev noselect",
                        onClick: _cache[10] || (_cache[10] = $event => ($options.callListPaginationPrevPage()))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: "chevron-left",
                          "custom-size": "mdi-18px"
                        })
                      ]),
                      ($data.callListPagination)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($data.callListPagination.visiblePages, (page, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(["pagination-button noselect", { "selected": $data.callListCurrentPage === page }]),
                              key: idx,
                              onClick: $event => ($data.callListCurrentPage = page)
                            }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_57))
                          }), 128 /* KEYED_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("div", {
                        class: "pagination-button next noselect",
                        onClick: _cache[11] || (_cache[11] = $event => ($options.callListPaginationNextPage()))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: "chevron-right",
                          "custom-size": "mdi-18px"
                        })
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_tab_item, null, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, [
                    _createElementVNode("div", _hoisted_58, _toDisplayString(_ctx.contacts.length), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_59, _toDisplayString(_ctx.l('activities/contact-list')), 1 /* TEXT */)
                  ])
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_field, {
                    class: "searchfield mb-2",
                    grouped: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        expanded: "",
                        modelValue: $data.contactsSearchStr,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.contactsSearchStr) = $event)),
                        icon: "magnify",
                        "custom-class": "square product-search-input",
                        placeholder: _ctx.l("generic/search") + "...",
                        "icon-right": $data.contactsSearchStr ? "close-circle" : "",
                        "icon-right-clickable": $data.contactsSearchStr ? true : false,
                        onIconRightClick: _cache[13] || (_cache[13] = $event => ($data.contactsSearchStr = ""))
                      }, null, 8 /* PROPS */, ["modelValue", "placeholder", "icon-right", "icon-right-clickable"]),
                      (_ctx.authStore.user?.role !== "basic")
                        ? (_openBlock(), _createElementBlock("p", _hoisted_60, [
                            _createVNode(_component_b_tooltip, {
                              label: _ctx.l("activities/add-new-contact"),
                              type: "is-white",
                              position: "is-left"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_b_button, {
                                  class: "icon-button",
                                  type: "is-light",
                                  size: "is-large",
                                  onClick: _cache[14] || (_cache[14] = $event => ($options.openAddEditContactModal())),
                                  disabled: $data.contactLoading
                                }, {
                                  default: _withCtx(() => _cache[73] || (_cache[73] = [
                                    _createTextVNode("+")
                                  ])),
                                  _: 1 /* STABLE */
                                }, 8 /* PROPS */, ["disabled"])
                              ]),
                              _: 1 /* STABLE */
                            }, 8 /* PROPS */, ["label"])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createElementVNode("div", _hoisted_61, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedContacts, (contact) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: _normalizeClass(["contact is-relative is-clickable", { "selected": $data.selectedContactId && $data.selectedContactId === contact._id }]),
                        key: "contact_list_contact_" + contact._id,
                        onClick: _withModifiers($event => ($options.selectContact(contact._id)), ["stop"])
                      }, [
                        _createElementVNode("div", _hoisted_63, [
                          _createElementVNode("div", _hoisted_64, [
                            (contact.title2)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_65, _toDisplayString(contact.title2), 1 /* TEXT */))
                              : _createCommentVNode("v-if", true),
                            _createTextVNode(" " + _toDisplayString(contact.fullName), 1 /* TEXT */),
                            (contact.title)
                              ? (_openBlock(), _createElementBlock("span", _hoisted_66, " (" + _toDisplayString(contact.title) + ")", 1 /* TEXT */))
                              : _createCommentVNode("v-if", true)
                          ]),
                          (_ctx.contactsStore.contactsWithLeadsMap.has(contact._id))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get(contact._id), (lead) => {
                            return (_openBlock(), _createElementBlock("div", _hoisted_67, _toDisplayString(lead.name), 1 /* TEXT */))
                          }), 256 /* UNKEYED_FRAGMENT */))
                        ]),
                        (_ctx.callList.find(c => c.contact === contact._id))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_68, [
                              _createVNode(_component_b_tooltip, {
                                label: _ctx.l("activities/already-in-call-list"),
                                type: "is-white",
                                position: "is-left"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_icon, {
                                    icon: "phone-plus",
                                    type: "is-grey"
                                  })
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["label"])
                            ]))
                          : (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              class: "contact-add-to-call-list is-clickable pr-2 pl-1",
                              onClick: _withModifiers($event => ($options.addContactToCallList(contact)), ["stop"])
                            }, [
                              _createVNode(_component_b_tooltip, {
                                label: _ctx.l("activities/add-to-call-list"),
                                type: "is-white",
                                position: "is-left"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_icon, { icon: "phone-plus" })
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["label"])
                            ], 8 /* PROPS */, _hoisted_69)),
                        (_ctx.authStore.user?.role !== "basic")
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 2,
                              class: "contact-menu",
                              onClick: _cache[15] || (_cache[15] = _withModifiers($event => ($data.activeSection = "contacts"), ["stop"]))
                            }, [
                              _createVNode(_component_b_dropdown, {
                                ref_for: true,
                                ref: "contact_dropdown_" + contact._id,
                                position: "is-bottom-left",
                                "mobile-modal": false
                              }, {
                                trigger: _withCtx(() => [
                                  _createVNode(_component_b_icon, {
                                    class: "is-clickable",
                                    icon: "dots-vertical"
                                  })
                                ]),
                                default: _withCtx(() => [
                                  _createVNode(_component_b_dropdown_item, {
                                    focusable: false,
                                    custom: "",
                                    paddingless: ""
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_70, [
                                        _createElementVNode("div", {
                                          class: "contact-dropdown-item is-clickable",
                                          onClick: $event => ($options.openAddEditContactModal(contact))
                                        }, [
                                          _createElementVNode("div", _hoisted_72, [
                                            _createVNode(_component_b_icon, {
                                              icon: "pencil-outline",
                                              size: "is-small"
                                            })
                                          ]),
                                          _createElementVNode("div", _hoisted_73, _toDisplayString(_ctx.l('generic/edit')), 1 /* TEXT */)
                                        ], 8 /* PROPS */, _hoisted_71),
                                        _createElementVNode("div", {
                                          class: "contact-dropdown-item is-clickable",
                                          onClick: $event => ($options.openDeleteContactConfirmModal(contact))
                                        }, [
                                          _createElementVNode("div", _hoisted_75, [
                                            _createVNode(_component_b_icon, {
                                              icon: "delete-outline",
                                              size: "is-small"
                                            })
                                          ]),
                                          _createElementVNode("div", _hoisted_76, _toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                                        ], 8 /* PROPS */, _hoisted_74)
                                      ])
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */)
                            ]))
                          : _createCommentVNode("v-if", true),
                        _createVNode(_component_b_loading, {
                          "is-full-page": false,
                          modelValue: $data.contactItemsLoading.has(contact._id),
                          animation: "none"
                        }, null, 8 /* PROPS */, ["modelValue"])
                      ], 10 /* CLASS, PROPS */, _hoisted_62))
                    }), 128 /* KEYED_FRAGMENT */)),
                    _createVNode(_component_b_loading, {
                      "is-full-page": false,
                      modelValue: $data.contactLoading,
                      "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.contactLoading) = $event)),
                      animation: "none"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _createElementVNode("div", _hoisted_77, [
                    _createElementVNode("div", _hoisted_78, [
                      _createElementVNode("div", _hoisted_79, [
                        _createElementVNode("div", {
                          class: "contacts-per-page-dropdown",
                          onClick: _cache[18] || (_cache[18] = _withModifiers(() => {}, ["stop"]))
                        }, [
                          _createVNode(_component_b_dropdown, {
                            modelValue: $data.contactsPerPage,
                            "onUpdate:modelValue": _cache[17] || (_cache[17] = $event => (($data.contactsPerPage) = $event)),
                            "aria-role": "list",
                            position: "is-top-right"
                          }, {
                            trigger: _withCtx(({ active }) => [
                              _createVNode(_component_b_field, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_button, {
                                    label: String($data.contactsPerPage),
                                    type: "is-select",
                                    expanded: "",
                                    size: "is-small",
                                    "icon-right": active ? "menu-up" : "menu-down"
                                  }, null, 8 /* PROPS */, ["label", "icon-right"])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "50"
                              }, {
                                default: _withCtx(() => _cache[74] || (_cache[74] = [
                                  _createTextVNode("50")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "250"
                              }, {
                                default: _withCtx(() => _cache[75] || (_cache[75] = [
                                  _createTextVNode("250")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "500"
                              }, {
                                default: _withCtx(() => _cache[76] || (_cache[76] = [
                                  _createTextVNode("500")
                                ])),
                                _: 1 /* STABLE */
                              }),
                              _createVNode(_component_b_dropdown_item, {
                                "aria-role": "listitem",
                                value: "1000"
                              }, {
                                default: _withCtx(() => _cache[77] || (_cache[77] = [
                                  _createTextVNode("1000")
                                ])),
                                _: 1 /* STABLE */
                              })
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["modelValue"])
                        ]),
                        _createElementVNode("div", _hoisted_80, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.l('activities/per-page').toUpperCase()), 1 /* TEXT */)
                        ])
                      ])
                    ]),
                    _createElementVNode("div", _hoisted_81, [
                      _createElementVNode("div", {
                        class: "pagination-button prev noselect",
                        onClick: _cache[19] || (_cache[19] = _withModifiers($event => {$options.contactsPaginationPrevPage(); $data.activeSection = "contacts"}, ["stop"]))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: "chevron-left",
                          "custom-size": "mdi-18px"
                        })
                      ]),
                      ($data.contactsPagination)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList($data.contactsPagination.visiblePages, (page, idx) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: _normalizeClass(["pagination-button noselect", { "selected": $data.contactsCurrentPage === page }]),
                              key: idx,
                              onClick: _withModifiers($event => {$data.contactsCurrentPage = page; $data.activeSection = "contacts"}, ["stop"])
                            }, _toDisplayString(page), 11 /* TEXT, CLASS, PROPS */, _hoisted_82))
                          }), 128 /* KEYED_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("div", {
                        class: "pagination-button next noselect",
                        onClick: _cache[20] || (_cache[20] = _withModifiers($event => {$options.contactsPaginationNextPage(); $data.activeSection = "contacts"}, ["stop"]))
                      }, [
                        _createVNode(_component_b_icon, {
                          icon: "chevron-right",
                          "custom-size": "mdi-18px"
                        })
                      ])
                    ])
                  ])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _: 1 /* STABLE */
          })
        ], 2 /* CLASS */)
      ]),
      _createCommentVNode(""),
      _createCommentVNode(""),
      _createCommentVNode(" ████████╗ ██████╗ ██████╗  ██████╗"),
      _createCommentVNode(" ╚══██╔══╝██╔═══██╗██╔══██╗██╔═══██╗"),
      _createCommentVNode("    ██║   ██║   ██║██║  ██║██║   ██║"),
      _createCommentVNode("    ██║   ██║   ██║██║  ██║██║   ██║"),
      _createCommentVNode("    ██║   ╚██████╔╝██████╔╝╚██████╔╝"),
      _createCommentVNode("    ╚═╝    ╚═════╝ ╚═════╝  ╚═════╝"),
      _createCommentVNode(""),
      _createCommentVNode(""),
      _createElementVNode("div", {
        class: _normalizeClass(["rudolfbox activities-todo is-relative", { "active": $data.activeSection === "todo" && !this.selectedTodoId && !_ctx.isMobile }]),
        onClick: _cache[31] || (_cache[31] = $event => {$data.activeSection = "todo"; $data.selectedTodoId = null})
      }, [
        _createElementVNode("div", _hoisted_83, [
          _createElementVNode("div", _hoisted_84, _toDisplayString(_ctx.l('activities/to-do-list')), 1 /* TEXT */),
          _createVNode(_component_b_field, {
            class: "searchfield mb-2",
            grouped: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_b_input, {
                expanded: "",
                modelValue: $data.todoSearchStr,
                "onUpdate:modelValue": _cache[22] || (_cache[22] = $event => (($data.todoSearchStr) = $event)),
                icon: "magnify",
                "custom-class": "square product-search-input",
                placeholder: _ctx.l("generic/search") + "...",
                "icon-right": $data.todoSearchStr ? "close-circle" : "",
                "icon-right-clickable": $data.todoSearchStr ? true : false,
                onIconRightClick: _cache[23] || (_cache[23] = $event => ($data.todoSearchStr = ""))
              }, null, 8 /* PROPS */, ["modelValue", "placeholder", "icon-right", "icon-right-clickable"]),
              _createElementVNode("p", _hoisted_85, [
                _createVNode(_component_b_tooltip, {
                  label: _ctx.l("activities/add-todo-item"),
                  type: "is-white",
                  position: "is-left"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_button, {
                      class: "icon-button",
                      type: "is-light",
                      size: "is-large",
                      onClick: _cache[24] || (_cache[24] = $event => ($options.openAddEditTodoModal())),
                      disabled: $data.todoLoading
                    }, {
                      default: _withCtx(() => _cache[78] || (_cache[78] = [
                        _createTextVNode("+")
                      ])),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["disabled"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ])
            ]),
            _: 1 /* STABLE */
          }),
          _createElementVNode("div", _hoisted_86, [
            _createElementVNode("div", _hoisted_87, _toDisplayString(_ctx.l('activities/todos-show-dropdown')) + ":", 1 /* TEXT */),
            _createElementVNode("div", _hoisted_88, [
              _createVNode(_component_b_dropdown, {
                modelValue: $data.todoShow,
                "onUpdate:modelValue": _cache[25] || (_cache[25] = $event => (($data.todoShow) = $event))
              }, {
                trigger: _withCtx(({ active }) => [
                  _createVNode(_component_b_field, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_button, {
                        label: $options.todoShowLabel,
                        type: "is-select",
                        size: "is-small",
                        "icon-right": active ? "chevron-up" : "chevron-down"
                      }, null, 8 /* PROPS */, ["label", "icon-right"])
                    ]),
                    _: 2 /* DYNAMIC */
                  }, 1024 /* DYNAMIC_SLOTS */)
                ]),
                default: _withCtx(() => [
                  _createVNode(_component_b_dropdown_item, { value: "upcoming" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('activities/todos-show-upcoming')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_dropdown_item, { value: "all" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('activities/todos-show-all')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["modelValue"])
            ]),
            _createElementVNode("div", null, [
              _createVNode(_component_b_field, { style: {"height":"19px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_checkbox, {
                    modelValue: $data.todoShowCompleted,
                    "onUpdate:modelValue": _cache[26] || (_cache[26] = $event => (($data.todoShowCompleted) = $event)),
                    size: "is-small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('activities/todos-show-completed')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_89, [
              _createVNode(_component_b_field, { style: {"height":"19px"} }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_checkbox, {
                    modelValue: $data.todoShowDueDates,
                    "onUpdate:modelValue": _cache[27] || (_cache[27] = $event => (($data.todoShowDueDates) = $event)),
                    size: "is-small"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('activities/todos-show-due-dates')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ]),
        (_openBlock(), _createElementBlock("div", {
          class: "todo-list",
          key: $data.todosRefreshToggler
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.computedTodos, (dailyTodos, idx) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "todo-daily",
              key: "todo_list_" + idx
            }, [
              _createElementVNode("div", _hoisted_90, _toDisplayString(dailyTodos.dateStr), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_91, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dailyTodos.items, (todo) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["todo-daily-item", { "selected": $data.selectedTodoId && $data.selectedTodoId === todo._id }]),
                    key: "todo_item_" + todo._id,
                    onClick: _withModifiers($event => (todo.dueDateItem ? $options.goToLead(todo.leadId) : $options.selectTodo(todo._id)), ["stop"])
                  }, [
                    _createElementVNode("div", {
                      class: "todo-dots",
                      onClick: _cache[28] || (_cache[28] = _withModifiers($event => ($data.activeSection = "todo"), ["stop"]))
                    }, [
                      (!todo.dueDateItem)
                        ? (_openBlock(), _createBlock(_component_b_dropdown, {
                            key: 0,
                            ref_for: true,
                            ref: "todo_dropdown_" + todo._id,
                            position: "is-bottom-right",
                            "mobile-modal": false
                          }, {
                            trigger: _withCtx(() => [
                              _createVNode(_component_b_icon, {
                                class: "is-clickable",
                                icon: "dots-vertical"
                              })
                            ]),
                            default: _withCtx(() => [
                              _createVNode(_component_b_dropdown_item, {
                                focusable: false,
                                custom: "",
                                paddingless: ""
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_93, [
                                    _createElementVNode("div", {
                                      class: "todo-dropdown-item is-clickable",
                                      onClick: $event => ($options.openAddEditTodoModal(todo))
                                    }, [
                                      _createElementVNode("div", _hoisted_95, [
                                        _createVNode(_component_b_icon, {
                                          icon: "pencil-outline",
                                          size: "is-small"
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_96, _toDisplayString(_ctx.l('generic/edit')), 1 /* TEXT */)
                                    ], 8 /* PROPS */, _hoisted_94),
                                    _createElementVNode("div", {
                                      class: "todo-dropdown-item is-clickable",
                                      onClick: $event => ($options.deleteTodo(todo._id))
                                    }, [
                                      _createElementVNode("div", _hoisted_98, [
                                        _createVNode(_component_b_icon, {
                                          icon: "delete-outline",
                                          size: "is-small"
                                        })
                                      ]),
                                      _createElementVNode("div", _hoisted_99, _toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                                    ], 8 /* PROPS */, _hoisted_97)
                                  ])
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1024 /* DYNAMIC_SLOTS */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1536 /* NEED_PATCH, DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", {
                      class: "todo-checkbox",
                      onClick: _cache[29] || (_cache[29] = _withModifiers($event => ($data.activeSection = "todo"), ["stop"]))
                    }, [
                      (!todo.dueDateItem)
                        ? (_openBlock(), _createBlock(_component_b_field, { key: 0 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_checkbox, {
                                modelValue: todo.done,
                                onInput: (evt) => $options.updateTodoDone(todo._id, evt.target.checked),
                                disabled: $data.todoItemsLoading.has(todo._id)
                              }, null, 8 /* PROPS */, ["modelValue", "onInput", "disabled"])
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : _createCommentVNode("v-if", true)
                    ]),
                    _createElementVNode("div", {
                      class: _normalizeClass(["todo-text is-relative", { "greytext": todo.done, "bold": todo.dueDateItem }])
                    }, [
                      _createTextVNode(_toDisplayString(todo.text), 1 /* TEXT */),
                      (Array.isArray(todo?.leads) && todo.leads.length > 0 && _ctx.leadsStore.byId(todo.leads[0])?._id)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                            _cache[79] || (_cache[79] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                            _createElementVNode("span", _hoisted_100, _toDisplayString(_ctx.l('activities/related-lead')) + ": " + _toDisplayString(this.leadsStore.byId(todo.leads[0]).name), 1 /* TEXT */)
                          ], 64 /* STABLE_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                      _createVNode(_component_b_loading, {
                        "is-full-page": false,
                        modelValue: $data.todoItemsLoading.has(todo._id),
                        animation: "none"
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ], 2 /* CLASS */)
                  ], 10 /* CLASS, PROPS */, _hoisted_92))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]))
          }), 128 /* KEYED_FRAGMENT */))
        ])),
        _createVNode(_component_b_loading, {
          "is-full-page": false,
          modelValue: $data.todoLoading,
          "onUpdate:modelValue": _cache[30] || (_cache[30] = $event => (($data.todoLoading) = $event)),
          animation: "none"
        }, null, 8 /* PROPS */, ["modelValue"])
      ], 2 /* CLASS */)
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.addEditCallListItemModalOpen,
      "onUpdate:modelValue": _cache[38] || (_cache[38] = $event => (($data.addEditCallListItemModalOpen) = $event)),
      width: 710,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeAddEditCallListItemModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_101, [
          _createElementVNode("div", _hoisted_102, [
            (!$data.editedCallListItemId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_103, _toDisplayString(_ctx.l('activities/add-to-call-list-manual')), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_104, _toDisplayString(_ctx.l('activities/edit-call-list-item')), 1 /* TEXT */)),
            _createVNode(_component_b_field, {
              class: "mb-4",
              grouped: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("generic/first-name"),
                  "label-position": "on-border",
                  expanded: "",
                  type: { "is-danger": $data.callListItemFirstNameError},
                  message: $data.callListItemFirstNameError ? "* " + _ctx.l("activities/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.callListItemFirstName,
                      "onUpdate:modelValue": _cache[32] || (_cache[32] = $event => (($data.callListItemFirstName) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("generic/last-name"),
                  "label-position": "on-border",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.callListItemLastName,
                      "onUpdate:modelValue": _cache[33] || (_cache[33] = $event => (($data.callListItemLastName) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_field, {
              class: "mb-4",
              grouped: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_field, {
                  label: _ctx.l("activities/call-list-item-phone-numbers"),
                  "label-position": "on-border",
                  expanded: "",
                  type: { "is-danger": $data.callListItemPhoneNumberError },
                  message: $data.callListItemPhoneNumberError ? "* " + _ctx.l("activities/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_taginput, {
                      modelValue: $data.callListItemPhoneNumbers,
                      "onUpdate:modelValue": _cache[34] || (_cache[34] = $event => (($data.callListItemPhoneNumbers) = $event)),
                      ellipsis: ""
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("activities/call-list-item-company"),
                  "label-position": "on-border",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.callListItemCompany,
                      "onUpdate:modelValue": _cache[35] || (_cache[35] = $event => (($data.callListItemCompany) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_105, [
              _createVNode(_component_b_button, {
                loading: $data.callListLoading,
                disabled: $data.callListLoading,
                type: "is-light",
                rounded: "",
                onClick: _cache[36] || (_cache[36] = $event => ($options.closeAddEditCallListItemModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"]),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[37] || (_cache[37] = $event => ($options.submitCallListItemModal())),
                loading: $data.callListLoading,
                disabled: $data.callListLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteCallListItemConfirmModalActive,
      "onUpdate:modelValue": _cache[41] || (_cache[41] = $event => (($data.deleteCallListItemConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteCallListItemConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_106, [
          _createElementVNode("div", _hoisted_107, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "mb-4 mt-4",
              innerHTML: _ctx.l_escaped("activities/confirm-delete-call-list-item", $data.callListItemToBeDeleted.firstName + " " + $data.callListItemToBeDeleted.lastName)
            }, null, 8 /* PROPS */, _hoisted_108),
            _createElementVNode("div", _hoisted_109, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[39] || (_cache[39] = $event => ($options.closeDeleteCallListItemConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[40] || (_cache[40] = $event => ($options.deleteCallListItem($data.callListItemToBeDeleted)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.addEditContactModalOpen,
      "onUpdate:modelValue": _cache[53] || (_cache[53] = $event => (($data.addEditContactModalOpen) = $event)),
      width: 710,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeAddEditContactModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_110, [
          _createElementVNode("div", _hoisted_111, [
            (!$data.editedContactId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_112, _toDisplayString(_ctx.l('activities/add-new-contact')), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_113, _toDisplayString(_ctx.l('activities/edit-contact')), 1 /* TEXT */)),
            _createVNode(_component_b_field, {
              class: "mb-4",
              grouped: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("generic/first-name"),
                  "label-position": "on-border",
                  expanded: "",
                  type: { "is-danger": $data.contactFirstNameError || $data.contactDuplicateError },
                  message: $data.contactFirstNameError ? "* " + _ctx.l("activities/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactFirstName,
                      "onUpdate:modelValue": _cache[42] || (_cache[42] = $event => (($data.contactFirstName) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("generic/last-name"),
                  "label-position": "on-border",
                  expanded: "",
                  type: { "is-danger": $data.contactLastNameError || $data.contactDuplicateError },
                  message: $data.contactLastNameError ? "* " + _ctx.l("activities/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactLastName,
                      "onUpdate:modelValue": _cache[43] || (_cache[43] = $event => (($data.contactLastName) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_field, {
              class: "mb-4",
              grouped: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_field, {
                  label: _ctx.l("contacts/contact-position"),
                  "label-position": "on-border",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactPosition,
                      "onUpdate:modelValue": _cache[44] || (_cache[44] = $event => (($data.contactPosition) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"]),
                _createVNode(_component_b_field, {
                  label: _ctx.l("contacts/contact-title"),
                  "label-position": "on-border",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactTitle,
                      "onUpdate:modelValue": _cache[45] || (_cache[45] = $event => (($data.contactTitle) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_field, {
              class: "mb-4 phone-numbers",
              grouped: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("contacts/phone1"),
                  "label-position": "on-border",
                  expanded: "",
                  type: { "is-danger": $data.contactPhoneNumberError || $data.contactDuplicateError },
                  message: $data.contactPhoneNumberError ? "* " + _ctx.l("activities/required-field") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactTelephone,
                      "onUpdate:modelValue": _cache[46] || (_cache[46] = $event => (($data.contactTelephone) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createVNode(_component_b_field, {
                  class: "mb-4",
                  label: _ctx.l("contacts/phone2"),
                  "label-position": "on-border",
                  expanded: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.contactTelephone2,
                      "onUpdate:modelValue": _cache[47] || (_cache[47] = $event => (($data.contactTelephone2) = $event))
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label"])
              ]),
              _: 1 /* STABLE */
            }),
            _createVNode(_component_b_field, {
              class: "mb-4",
              label: _ctx.l("generic/email"),
              "label-position": "on-border",
              expanded: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.contactEmail,
                  "onUpdate:modelValue": _cache[48] || (_cache[48] = $event => (($data.contactEmail) = $event)),
                  type: "email"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_b_field, {
              label: "LinkedIn",
              "label-position": "on-border",
              expanded: "",
              type: { "is-danger": $data.contactLinkedInError },
              message: $data.contactLinkedInError ? _ctx.l("contacts/invalid-linkedin-url") : "",
              style: {"margin-bottom":"2rem"}
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.contactLinkedIn,
                  "onUpdate:modelValue": _cache[49] || (_cache[49] = $event => (($data.contactLinkedIn) = $event)),
                  icon: "linkedin"
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["type", "message"]),
            _createVNode(_component_b_field, {
              class: "mb-4",
              label: _ctx.l("contacts/notes"),
              "label-position": "on-border",
              expanded: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.contactNotes,
                  "onUpdate:modelValue": _cache[50] || (_cache[50] = $event => (($data.contactNotes) = $event))
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            ($data.editedContactId && _ctx.contactsStore.contactsWithLeadsMap.has($data.editedContactId))
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createElementVNode("p", _hoisted_114, _toDisplayString(_ctx.l('contacts/related-leads')), 1 /* TEXT */),
                  _createElementVNode("ul", _hoisted_115, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.contactsStore.contactsWithLeadsMap.get($data.editedContactId), (lead) => {
                      return (_openBlock(), _createElementBlock("li", _hoisted_116, _toDisplayString(lead.name), 1 /* TEXT */))
                    }), 256 /* UNKEYED_FRAGMENT */))
                  ])
                ], 64 /* STABLE_FRAGMENT */))
              : _createCommentVNode("v-if", true),
            _createElementVNode("div", _hoisted_117, [
              _createVNode(_component_b_button, {
                loading: $data.contactLoading,
                disabled: $data.contactLoading,
                type: "is-light",
                rounded: "",
                onClick: _cache[51] || (_cache[51] = $event => ($options.closeAddEditContactModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"]),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[52] || (_cache[52] = $event => ($options.submitContactModal())),
                loading: $data.contactLoading,
                disabled: $data.contactLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.addEditTodoModalOpen,
      "onUpdate:modelValue": _cache[60] || (_cache[60] = $event => (($data.addEditTodoModalOpen) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeAddEditTodoModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_118, [
          _createElementVNode("div", _hoisted_119, [
            (!$data.editedTodoId)
              ? (_openBlock(), _createElementBlock("div", _hoisted_120, _toDisplayString(_ctx.l('activities/add-todo-item')), 1 /* TEXT */))
              : (_openBlock(), _createElementBlock("div", _hoisted_121, _toDisplayString(_ctx.l('activities/edit-todo-item')), 1 /* TEXT */)),
            _createVNode(_component_b_field, {
              class: "mb-4",
              label: _ctx.l("generic/text"),
              "label-position": "on-border",
              type: { "is-danger": $data.todoTextError },
              message: $data.todoTextError ? "* " + _ctx.l("activities/required-field") : ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_input, {
                  modelValue: $data.todoText,
                  "onUpdate:modelValue": _cache[54] || (_cache[54] = $event => (($data.todoText) = $event)),
                  expanded: ""
                }, null, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label", "type", "message"]),
            _createVNode(_component_b_field, {
              class: "mb-4",
              label: _ctx.l("activities/related-lead"),
              "label-position": "on-border"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_b_autocomplete, {
                  modelValue: $data.todoLeadsSearchStr,
                  "onUpdate:modelValue": _cache[55] || (_cache[55] = $event => (($data.todoLeadsSearchStr) = $event)),
                  data: $options.todoLeadOptions,
                  field: "name",
                  clearable: "",
                  "open-on-focus": true,
                  onSelect: _cache[56] || (_cache[56] = (lead) => { $data.todoLeadId = lead ? lead._id : null })
                }, null, 8 /* PROPS */, ["modelValue", "data"])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_b_field, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_datepicker, {
                  modelValue: $data.todoDueDate,
                  "onUpdate:modelValue": _cache[57] || (_cache[57] = $event => (($data.todoDueDate) = $event)),
                  inline: "",
                  locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                  "show-week-number": true,
                  "first-day-of-week": 1,
                  "trap-focus": "",
                  "min-date": _ctx.$dayjs().utc().subtract(1, "day").toDate(),
                  disabled: $data.todoLoading
                }, null, 8 /* PROPS */, ["modelValue", "locale", "min-date", "disabled"])
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("div", _hoisted_122, [
              _createVNode(_component_b_button, {
                loading: $data.todoLoading,
                disabled: $data.todoLoading,
                type: "is-light",
                rounded: "",
                onClick: _cache[58] || (_cache[58] = $event => ($options.closeAddEditTodoModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"]),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[59] || (_cache[59] = $event => ($options.submitTodoModal())),
                loading: $data.todoLoading,
                disabled: $data.todoLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"]),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.deleteContactConfirmModalActive,
      "onUpdate:modelValue": _cache[63] || (_cache[63] = $event => (($data.deleteContactConfirmModalActive) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeDeleteContactConfirmModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_123, [
          _createElementVNode("div", _hoisted_124, [
            _createVNode(_component_b_icon, {
              icon: "alert-circle-outline",
              "custom-class": "modal-alert"
            }),
            _createElementVNode("p", {
              class: "mb-4 mt-4",
              innerHTML: _ctx.l_escaped("activities/confirm-delete-contact-text1", $data.contactToBeDeleted.fullName)
            }, null, 8 /* PROPS */, _hoisted_125),
            (_ctx.contactsStore.contactsWithLeadsMap.has($data.contactToBeDeleted._id))
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  class: "mb-4",
                  innerHTML: _ctx.l_escaped("activities/confirm-delete-contact-text2", _ctx.contactsStore.contactsWithLeadsMap.get($data.contactToBeDeleted._id).length)
                }, null, 8 /* PROPS */, _hoisted_126))
              : _createCommentVNode("v-if", true),
            _createElementVNode("p", {
              class: "mb-4",
              innerHTML: _ctx.l_escaped("activities/confirm-delete-contact-text3")
            }, null, 8 /* PROPS */, _hoisted_127),
            _createElementVNode("div", _hoisted_128, [
              _createVNode(_component_b_button, {
                type: "is-light",
                rounded: "",
                onClick: _cache[61] || (_cache[61] = $event => ($options.closeDeleteContactConfirmModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[62] || (_cache[62] = $event => ($options.deleteContact($data.contactToBeDeleted)))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/delete')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}