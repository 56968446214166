import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "add-lead-contact-mobile-container" }
const _hoisted_2 = { class: "top-area" }
const _hoisted_3 = { class: "content-area" }
const _hoisted_4 = { class: "bottom-area" }
const _hoisted_5 = { class: "bottom-buttons" }
const _hoisted_6 = { style: {"text-transform":"uppercase"} }
const _hoisted_7 = { style: {"text-transform":"uppercase"} }
const _hoisted_8 = { style: {"grid-column":"1 / 3","text-transform":"uppercase"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_add_edit_lead = _resolveComponent("add-edit-lead")
  const _component_b_button = _resolveComponent("b-button")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.l('add-edit-lead/add-lead-contacts')), 1 /* TEXT */)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(), _createBlock(_component_add_edit_lead, {
        ref: "addLeadContactsMobile",
        type: "addNewLead",
        "map-id": "add-lead-mobile-lead-location",
        key: $data.addEditLeadToggler,
        onAfterSaved: _cache[0] || (_cache[0] = (lead) => $options.afterSaved(lead))
      }))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_b_button, {
            type: "is-light",
            expanded: "",
            onClick: _cache[1] || (_cache[1] = $event => (_ctx.addNewLeadMobileToggled = false)),
            loading: $data.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.l('generic/discard')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_b_button, {
            type: "is-primary",
            expanded: "",
            onClick: _cache[2] || (_cache[2] = $event => ($options.save())),
            loading: $data.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.l('generic/save-n-exit')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_b_button, {
            type: "is-light",
            expanded: "",
            onClick: _cache[3] || (_cache[3] = $event => ($options.save("goToLead"))),
            loading: $data.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.l('add-edit-lead/save-n-proceed')), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["loading"])
        ])
      ])
    ])
  ]))
}