//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'


export const useBusinessCardScannerStore = defineStore('businessCardScanner', {
    state: () => {
        return {
            lead: null,
            contact: null,
        }
    },


    getters: {
    },


    actions: {
        reset() {
            this.lead = null
            this.contact = null
        }
    }
})
