import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "manage-leads-table-outer is-relative" }
const _hoisted_2 = { class: "manage-leads-top" }
const _hoisted_3 = { class: "leads-title" }
const _hoisted_4 = {
  key: 0,
  class: "rtitle normalcase normalweight loading"
}
const _hoisted_5 = {
  key: 1,
  class: "rtitle",
  style: {"text-wrap":"nowrap"}
}
const _hoisted_6 = {
  key: 0,
  class: "table-menu is-hidden-mobile"
}
const _hoisted_7 = { class: "active-filters" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "has-text-primary bold" }
const _hoisted_10 = { class: "is-flex is-justify-content-center" }
const _hoisted_11 = { class: "linktext" }
const _hoisted_12 = {
  key: 1,
  class: "rpagination"
}
const _hoisted_13 = { class: "pagination-per-page" }
const _hoisted_14 = { class: "leads-per-page" }
const _hoisted_15 = { class: "leads-per-page-dropdown" }
const _hoisted_16 = { class: "bold" }
const _hoisted_17 = { class: "pagination-pages" }
const _hoisted_18 = { class: "pagination-position" }
const _hoisted_19 = { class: "manage-leads-table-body" }
const _hoisted_20 = {
  key: 0,
  class: "rgrid-scroller"
}
const _hoisted_21 = {
  key: 0,
  class: "rgrid-header bulk-manage-checkbox-fixed"
}
const _hoisted_22 = { class: "rgrid-header-inner has-text-centered" }
const _hoisted_23 = { class: "rgrid-header-inner" }
const _hoisted_24 = { class: "column-header" }
const _hoisted_25 = { class: "column-name" }
const _hoisted_26 = { class: "column-sort" }
const _hoisted_27 = {
  key: 0,
  class: "touch-header-resize-handle"
}
const _hoisted_28 = ["data-column"]
const _hoisted_29 = { class: "column-header" }
const _hoisted_30 = { class: "column-name" }
const _hoisted_31 = ["onClick"]
const _hoisted_32 = { key: 0 }
const _hoisted_33 = {
  key: 0,
  class: "touch-header-resize-handle"
}
const _hoisted_34 = { class: "rgrid-main-row" }
const _hoisted_35 = {
  key: 0,
  class: "rgrid-entry has-text-centered bulk-manage-checkbox-fixed"
}
const _hoisted_36 = ["onClick"]
const _hoisted_37 = ["onClick"]
const _hoisted_38 = {
  key: 0,
  class: "lead-number is-hidden-mobile"
}
const _hoisted_39 = {
  key: 0,
  class: "rgrid-entry bold"
}
const _hoisted_40 = {
  key: 1,
  class: "rgrid-entry"
}
const _hoisted_41 = {
  key: 0,
  style: {"max-width":"200px","margin":"5px 15px 0 -0.75em"}
}
const _hoisted_42 = {
  key: 2,
  class: "rgrid-entry"
}
const _hoisted_43 = { class: "sales-totals-textual" }
const _hoisted_44 = { class: "sales-value" }
const _hoisted_45 = { class: "sales-totals-textual" }
const _hoisted_46 = { class: "sales-value" }
const _hoisted_47 = { class: "sales-totals-textual" }
const _hoisted_48 = { class: "sales-value" }
const _hoisted_49 = {
  key: 3,
  class: "rgrid-entry"
}
const _hoisted_50 = {
  key: 0,
  style: {"max-width":"150px","margin":"5px 15px 0 -0.75em"}
}
const _hoisted_51 = {
  key: 4,
  class: "rgrid-entry"
}
const _hoisted_52 = {
  key: 0,
  class: "bold"
}
const _hoisted_53 = {
  key: 5,
  class: "rgrid-entry"
}
const _hoisted_54 = {
  key: 0,
  class: "bold"
}
const _hoisted_55 = {
  key: 6,
  class: "rgrid-entry"
}
const _hoisted_56 = { class: "products-cell-container" }
const _hoisted_57 = { class: "products-product-name" }
const _hoisted_58 = { key: 0 }
const _hoisted_59 = {
  key: 7,
  class: "rgrid-entry"
}
const _hoisted_60 = {
  key: 0,
  class: "statuses-cell-container"
}
const _hoisted_61 = {
  key: 0,
  class: "status-container"
}
const _hoisted_62 = { class: "status-product" }
const _hoisted_63 = { class: "greytext status-product-name" }
const _hoisted_64 = { class: "status-status" }
const _hoisted_65 = {
  key: 8,
  class: "rgrid-entry"
}
const _hoisted_66 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_67 = { class: "per-product-name greytext" }
const _hoisted_68 = { class: "per-product-text" }
const _hoisted_69 = {
  key: 9,
  class: "rgrid-entry"
}
const _hoisted_70 = { class: "bold" }
const _hoisted_71 = {
  key: 10,
  class: "rgrid-entry"
}
const _hoisted_72 = { class: "bold" }
const _hoisted_73 = {
  key: 11,
  class: "rgrid-entry"
}
const _hoisted_74 = { class: "bold" }
const _hoisted_75 = {
  key: 12,
  class: "rgrid-entry"
}
const _hoisted_76 = { class: "bold" }
const _hoisted_77 = {
  key: 13,
  class: "rgrid-entry"
}
const _hoisted_78 = { class: "bold" }
const _hoisted_79 = {
  key: 14,
  class: "rgrid-entry"
}
const _hoisted_80 = { class: "bold" }
const _hoisted_81 = {
  key: 15,
  class: "rgrid-entry"
}
const _hoisted_82 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_83 = {
  key: 0,
  class: "smalltext semibold"
}
const _hoisted_84 = { class: "greytext per-product-name" }
const _hoisted_85 = { class: "per-product-text" }
const _hoisted_86 = {
  key: 16,
  class: "rgrid-entry"
}
const _hoisted_87 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_88 = {
  key: 0,
  class: "smalltext semibold"
}
const _hoisted_89 = { class: "greytext per-product-name" }
const _hoisted_90 = { class: "per-product-text" }
const _hoisted_91 = {
  key: 17,
  class: "rgrid-entry"
}
const _hoisted_92 = {
  key: 18,
  class: "rgrid-entry"
}
const _hoisted_93 = { class: "bold" }
const _hoisted_94 = {
  key: 19,
  class: "rgrid-entry"
}
const _hoisted_95 = { class: "bold" }
const _hoisted_96 = {
  key: 20,
  class: "rgrid-entry"
}
const _hoisted_97 = {
  key: 0,
  class: "lead-contacts-list"
}
const _hoisted_98 = { key: 0 }
const _hoisted_99 = {
  key: 21,
  class: "rgrid-entry"
}
const _hoisted_100 = { class: "bold" }
const _hoisted_101 = {
  key: 22,
  class: "rgrid-entry"
}
const _hoisted_102 = { class: "bold" }
const _hoisted_103 = {
  key: 23,
  class: "rgrid-entry"
}
const _hoisted_104 = { class: "bold" }
const _hoisted_105 = {
  key: 24,
  class: "rgrid-entry"
}
const _hoisted_106 = { class: "bold" }
const _hoisted_107 = {
  key: 25,
  class: "rgrid-entry"
}
const _hoisted_108 = { class: "bold" }
const _hoisted_109 = {
  key: 26,
  class: "rgrid-entry"
}
const _hoisted_110 = { class: "bold" }
const _hoisted_111 = {
  key: 27,
  class: "rgrid-entry"
}
const _hoisted_112 = { class: "bold" }
const _hoisted_113 = {
  key: 28,
  class: "rgrid-entry"
}
const _hoisted_114 = { class: "bold" }
const _hoisted_115 = {
  key: 29,
  class: "rgrid-entry"
}
const _hoisted_116 = {
  key: 30,
  class: "rgrid-entry"
}
const _hoisted_117 = { class: "bold" }
const _hoisted_118 = {
  key: 31,
  class: "rgrid-entry"
}
const _hoisted_119 = { class: "bold" }
const _hoisted_120 = {
  key: 32,
  class: "rgrid-entry"
}
const _hoisted_121 = { class: "semibold" }
const _hoisted_122 = {
  key: 33,
  class: "rgrid-entry"
}
const _hoisted_123 = {
  key: 0,
  style: {"display":"flex","flex-direction":"column","flex-wrap":"nowrap","gap":"10px"}
}
const _hoisted_124 = { class: "smalltext bold" }
const _hoisted_125 = { class: "smalltext greytext" }
const _hoisted_126 = { class: "semibold" }
const _hoisted_127 = { key: 0 }
const _hoisted_128 = { class: "smalltext greytext" }
const _hoisted_129 = { class: "semibold" }
const _hoisted_130 = {
  key: 34,
  class: "rgrid-entry"
}
const _hoisted_131 = { key: 0 }
const _hoisted_132 = { key: 0 }
const _hoisted_133 = {
  key: 0,
  class: "rgrid-entry has-text-centered bulk-manage-checkbox-fixed"
}
const _hoisted_134 = ["onClick"]
const _hoisted_135 = { class: "is-flex" }
const _hoisted_136 = { style: {"margin-right":"5px"} }
const _hoisted_137 = {
  key: 0,
  class: "lead-number"
}
const _hoisted_138 = {
  key: 0,
  class: "rgrid-entry bold"
}
const _hoisted_139 = {
  key: 1,
  class: "rgrid-entry"
}
const _hoisted_140 = {
  key: 0,
  style: {"max-width":"200px","margin":"5px 15px 0 -0.75em"}
}
const _hoisted_141 = {
  key: 2,
  class: "rgrid-entry"
}
const _hoisted_142 = { class: "sales-totals-textual" }
const _hoisted_143 = { class: "sales-value" }
const _hoisted_144 = { class: "sales-totals-textual" }
const _hoisted_145 = { class: "sales-value" }
const _hoisted_146 = { class: "sales-totals-textual" }
const _hoisted_147 = { class: "sales-value" }
const _hoisted_148 = {
  key: 3,
  class: "rgrid-entry"
}
const _hoisted_149 = {
  key: 0,
  style: {"max-width":"150px","margin":"5px 15px 0 -0.75em"}
}
const _hoisted_150 = {
  key: 4,
  class: "rgrid-entry"
}
const _hoisted_151 = {
  key: 0,
  class: "bold"
}
const _hoisted_152 = {
  key: 5,
  class: "rgrid-entry"
}
const _hoisted_153 = {
  key: 0,
  class: "bold"
}
const _hoisted_154 = {
  key: 6,
  class: "rgrid-entry"
}
const _hoisted_155 = { class: "products-cell-container" }
const _hoisted_156 = { class: "products-product-name" }
const _hoisted_157 = { key: 0 }
const _hoisted_158 = {
  key: 7,
  class: "rgrid-entry"
}
const _hoisted_159 = {
  key: 0,
  class: "statuses-cell-container"
}
const _hoisted_160 = {
  key: 0,
  class: "status-container"
}
const _hoisted_161 = { class: "status-product" }
const _hoisted_162 = { class: "greytext status-product-name" }
const _hoisted_163 = { class: "status-status" }
const _hoisted_164 = {
  key: 8,
  class: "rgrid-entry"
}
const _hoisted_165 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_166 = { class: "per-product-name greytext" }
const _hoisted_167 = { class: "per-product-text" }
const _hoisted_168 = {
  key: 9,
  class: "rgrid-entry"
}
const _hoisted_169 = { class: "bold" }
const _hoisted_170 = {
  key: 10,
  class: "rgrid-entry"
}
const _hoisted_171 = { class: "bold" }
const _hoisted_172 = {
  key: 11,
  class: "rgrid-entry"
}
const _hoisted_173 = { class: "bold" }
const _hoisted_174 = {
  key: 12,
  class: "rgrid-entry"
}
const _hoisted_175 = { class: "bold" }
const _hoisted_176 = {
  key: 13,
  class: "rgrid-entry"
}
const _hoisted_177 = { class: "bold" }
const _hoisted_178 = {
  key: 14,
  class: "rgrid-entry"
}
const _hoisted_179 = { class: "bold" }
const _hoisted_180 = {
  key: 15,
  class: "rgrid-entry"
}
const _hoisted_181 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_182 = {
  key: 0,
  class: "smalltext semibold"
}
const _hoisted_183 = { class: "greytext per-product-name" }
const _hoisted_184 = { class: "per-product-text" }
const _hoisted_185 = {
  key: 16,
  class: "rgrid-entry"
}
const _hoisted_186 = {
  key: 0,
  class: "per-product-cell-container"
}
const _hoisted_187 = {
  key: 0,
  class: "smalltext semibold"
}
const _hoisted_188 = { class: "greytext per-product-name" }
const _hoisted_189 = { class: "per-product-text" }
const _hoisted_190 = {
  key: 17,
  class: "rgrid-entry"
}
const _hoisted_191 = {
  key: 18,
  class: "rgrid-entry"
}
const _hoisted_192 = { class: "bold" }
const _hoisted_193 = {
  key: 19,
  class: "rgrid-entry"
}
const _hoisted_194 = { class: "bold" }
const _hoisted_195 = {
  key: 20,
  class: "rgrid-entry"
}
const _hoisted_196 = {
  key: 0,
  class: "lead-contacts-list"
}
const _hoisted_197 = {
  key: 21,
  class: "rgrid-entry"
}
const _hoisted_198 = { class: "bold" }
const _hoisted_199 = {
  key: 22,
  class: "rgrid-entry"
}
const _hoisted_200 = { class: "bold" }
const _hoisted_201 = {
  key: 23,
  class: "rgrid-entry"
}
const _hoisted_202 = { class: "bold" }
const _hoisted_203 = {
  key: 24,
  class: "rgrid-entry"
}
const _hoisted_204 = { class: "bold" }
const _hoisted_205 = {
  key: 25,
  class: "rgrid-entry"
}
const _hoisted_206 = { class: "bold" }
const _hoisted_207 = {
  key: 26,
  class: "rgrid-entry"
}
const _hoisted_208 = { class: "bold" }
const _hoisted_209 = {
  key: 27,
  class: "rgrid-entry"
}
const _hoisted_210 = { class: "bold" }
const _hoisted_211 = {
  key: 28,
  class: "rgrid-entry"
}
const _hoisted_212 = { class: "bold" }
const _hoisted_213 = {
  key: 29,
  class: "rgrid-entry"
}
const _hoisted_214 = {
  key: 30,
  class: "rgrid-entry"
}
const _hoisted_215 = { class: "bold" }
const _hoisted_216 = {
  key: 31,
  class: "rgrid-entry"
}
const _hoisted_217 = { class: "bold" }
const _hoisted_218 = {
  key: 32,
  class: "rgrid-entry"
}
const _hoisted_219 = { class: "semibold" }
const _hoisted_220 = {
  key: 33,
  class: "rgrid-entry"
}
const _hoisted_221 = { key: 0 }
const _hoisted_222 = { class: "smalltext greytext" }
const _hoisted_223 = { class: "semibold" }
const _hoisted_224 = {
  key: 34,
  class: "rgrid-entry"
}
const _hoisted_225 = { key: 0 }
const _hoisted_226 = { key: 0 }
const _hoisted_227 = { class: "rudolfbox" }
const _hoisted_228 = { class: "modal-inner has-text-centered" }
const _hoisted_229 = { class: "rtitle mt-4 mb-5" }
const _hoisted_230 = { class: "modal-text" }
const _hoisted_231 = ["innerHTML"]
const _hoisted_232 = { class: "buttons mb-0" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_bulk_manage = _resolveComponent("bulk-manage")
  const _component_bulk_edit = _resolveComponent("bulk-edit")
  const _component_table_settings = _resolveComponent("table-settings")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_b_tooltip = _resolveComponent("b-tooltip")
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_columns_sorting = _resolveComponent("columns-sorting")
  const _component_columns_quicksearch = _resolveComponent("columns-quicksearch")
  const _component_chart_target_actual_last_yr_sales = _resolveComponent("chart-target-actual-last-yr-sales")
  const _component_chart_monthly_sales = _resolveComponent("chart-monthly-sales")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_taglist = _resolveComponent("b-taglist")
  const _component_b_modal = _resolveComponent("b-modal")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    ($data.bulkManageActive)
      ? (_openBlock(), _createBlock(_component_bulk_manage, {
          key: 0,
          ref: "bulkManage",
          "selected-leads": $data.selectedLeads,
          onClose: _cache[0] || (_cache[0] = () => { $options.toggleBulkManage(false) })
        }, null, 8 /* PROPS */, ["selected-leads"]))
      : _createCommentVNode("v-if", true),
    ($data.bulkEditActive)
      ? (_openBlock(), _createBlock(_component_bulk_edit, {
          key: 1,
          "selected-leads": $data.selectedLeads,
          onClose: _cache[1] || (_cache[1] = () => { $options.toggleBulkEdit(false) })
        }, null, 8 /* PROPS */, ["selected-leads"]))
      : _createCommentVNode("v-if", true),
    ($data.tableSettingsActive)
      ? (_openBlock(), _createBlock(_component_table_settings, {
          key: 2,
          "table-columns": $data.tableColumns,
          "column-order": $data.columnOrder,
          "selected-columns": $data.selectedColumns,
          onSelectedColumns: $options.updateSelectedColumns,
          onColumnToggled: _cache[2] || (_cache[2] = (column, toggle) => $options.tableColumnToggled(column, toggle)),
          onClose: _cache[3] || (_cache[3] = () => { $options.toggleTableSettings(false) })
        }, null, 8 /* PROPS */, ["table-columns", "column-order", "selected-columns", "onSelectedColumns"]))
      : _createCommentVNode("v-if", true),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ████████╗ ██████╗ ██████╗"),
    _createCommentVNode(" ╚══██╔══╝██╔═══██╗██╔══██╗"),
    _createCommentVNode("    ██║   ██║   ██║██████╔╝"),
    _createCommentVNode("    ██║   ██║   ██║██╔═══╝"),
    _createCommentVNode("    ██║   ╚██████╔╝██║"),
    _createCommentVNode("    ╚═╝    ╚═════╝ ╚═╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          (_ctx.appLoading)
            ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.l('manage-leads-table/retrieving-leads')), 1 /* TEXT */))
            : (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.leads.length) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-leads')), 1 /* TEXT */))
        ])
      ]),
      (!_ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("manage-leads-table/excel-export-tooltip"),
              type: "is-white",
              "append-to-body": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "table-menu-item",
                  onClick: _cache[4] || (_cache[4] = $event => ($options.openExcelExportConfigModal()))
                }, [
                  _createVNode(_component_b_icon, { icon: "microsoft-excel" })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("manage-leads-table/bulk-manage-tooltip"),
              type: "is-white",
              "append-to-body": ""
            }, {
              default: _withCtx(() => [
                (_ctx.isAdmin)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "table-menu-item",
                      onClick: _cache[5] || (_cache[5] = $event => ($options.toggleBulkManage(true)))
                    }, [
                      _createVNode(_component_b_icon, { icon: "multicast" })
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("manage-leads-table/bulk-edit-tooltip"),
              type: "is-white",
              "append-to-body": ""
            }, {
              default: _withCtx(() => [
                (_ctx.isAdmin)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "table-menu-item",
                      onClick: _cache[6] || (_cache[6] = $event => ($options.toggleBulkEdit(true)))
                    }, [
                      _createVNode(_component_b_icon, { icon: "pencil-box-multiple-outline" })
                    ]))
                  : _createCommentVNode("v-if", true)
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"]),
            _createVNode(_component_b_tooltip, {
              label: _ctx.l("manage-leads-table/table-settings-tooltip"),
              type: "is-white",
              "append-to-body": ""
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", {
                  class: "table-menu-item",
                  onClick: _cache[7] || (_cache[7] = $event => ($options.toggleTableSettings(true)))
                }, [
                  _createVNode(_component_b_icon, { icon: "cog-outline" })
                ])
              ]),
              _: 1 /* STABLE */
            }, 8 /* PROPS */, ["label"])
          ]))
        : _createCommentVNode("v-if", true),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.numFiltersActive > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.numFiltersActive) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-filters-active').toLowerCase()), 1 /* TEXT */),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", {
                  class: "noselect semibold is-clickable",
                  onClick: _cache[8] || (_cache[8] = $event => (_ctx.leadsStore.resetFilters()))
                }, [
                  _cache[22] || (_cache[22] = _createTextVNode("[")),
                  _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.l('manage-leads-table/clear-filters').toLowerCase()), 1 /* TEXT */),
                  _cache[23] || (_cache[23] = _createTextVNode("]"))
                ])
              ])
            ]))
          : _createCommentVNode("v-if", true)
      ]),
      (!_ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_b_dropdown, {
                    modelValue: _ctx.leadsPerPage,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = $event => ((_ctx.leadsPerPage) = $event)),
                    "aria-role": "list",
                    position: "is-bottom-right",
                    onChange: _cache[10] || (_cache[10] = (value) => $options.leadsPerPageChanged(value))
                  }, {
                    trigger: _withCtx(({ active }) => [
                      _createVNode(_component_b_field, null, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_button, {
                            label: _ctx.leadsPerPage,
                            type: "is-select",
                            expanded: ""
                          }, null, 8 /* PROPS */, ["label"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ]),
                    default: _withCtx(() => [
                      _createVNode(_component_b_dropdown_item, { custom: "" }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.l('manage-leads-table/leads-per-page')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "10"
                      }, {
                        default: _withCtx(() => _cache[24] || (_cache[24] = [
                          _createTextVNode("10")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "25"
                      }, {
                        default: _withCtx(() => _cache[25] || (_cache[25] = [
                          _createTextVNode("25")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "50"
                      }, {
                        default: _withCtx(() => _cache[26] || (_cache[26] = [
                          _createTextVNode("50")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "100"
                      }, {
                        default: _withCtx(() => _cache[27] || (_cache[27] = [
                          _createTextVNode("100")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "250"
                      }, {
                        default: _withCtx(() => _cache[28] || (_cache[28] = [
                          _createTextVNode("250")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "500"
                      }, {
                        default: _withCtx(() => _cache[29] || (_cache[29] = [
                          _createTextVNode("500")
                        ])),
                        _: 1 /* STABLE */
                      }),
                      _createVNode(_component_b_dropdown_item, {
                        "aria-role": "listitem",
                        value: "1000"
                      }, {
                        default: _withCtx(() => _cache[30] || (_cache[30] = [
                          _createTextVNode("1000")
                        ])),
                        _: 1 /* STABLE */
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", {
                class: "pagination-button noselect is-hidden-mobile",
                onClick: _cache[11] || (_cache[11] = $event => ($options.paginationFirstPage()))
              }, [
                _createVNode(_component_b_icon, {
                  icon: "page-first",
                  "custom-size": "mdi-24px"
                })
              ]),
              _createElementVNode("div", {
                class: "pagination-button noselect",
                onClick: _cache[12] || (_cache[12] = $event => ($options.paginationPrevPage()))
              }, [
                _createVNode(_component_b_icon, {
                  icon: "chevron-left",
                  "custom-size": "mdi-24px"
                })
              ]),
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_b_input, {
                  id: "leadsPerPageInput",
                  type: "number",
                  size: "is-small",
                  min: "1",
                  modelValue: _ctx.currentPage,
                  onInput: _cache[13] || (_cache[13] = (event) => { $options.setPage(event.target.value) }),
                  style: {"width":"1ch"}
                }, null, 8 /* PROPS */, ["modelValue"]),
                _cache[31] || (_cache[31] = _createElementVNode("div", null, "/", -1 /* HOISTED */)),
                _createElementVNode("div", null, _toDisplayString(_ctx.pagination?.lastPage || 1), 1 /* TEXT */)
              ]),
              _createElementVNode("div", {
                class: "pagination-button noselect",
                onClick: _cache[14] || (_cache[14] = $event => ($options.paginationNextPage()))
              }, [
                _createVNode(_component_b_icon, {
                  icon: "chevron-right",
                  "custom-size": "mdi-24px"
                })
              ]),
              _createElementVNode("div", {
                class: "pagination-button noselect is-hidden-mobile",
                onClick: _cache[15] || (_cache[15] = $event => ($options.paginationLastPage()))
              }, [
                _createVNode(_component_b_icon, {
                  icon: "page-last",
                  "custom-size": "mdi-24px"
                })
              ])
            ])
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode("  ██████╗ ██████╗ ██╗██████╗"),
    _createCommentVNode(" ██╔════╝ ██╔══██╗██║██╔══██╗"),
    _createCommentVNode(" ██║  ███╗██████╔╝██║██║  ██║"),
    _createCommentVNode(" ██║   ██║██╔══██╗██║██║  ██║"),
    _createCommentVNode(" ╚██████╔╝██║  ██║██║██████╔╝"),
    _createCommentVNode("  ╚═════╝ ╚═╝  ╚═╝╚═╝╚═════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createElementVNode("div", _hoisted_19, [
      (!_ctx.appLoading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
            _createElementVNode("div", {
              class: "rgrid",
              id: "rgrid-handle",
              style: _normalizeStyle($options.gridTemplateColumnsCSS)
            }, [
              _createCommentVNode(""),
              _createCommentVNode(""),
              _createCommentVNode(" Column headers"),
              _createCommentVNode(""),
              _createCommentVNode(""),
              _createCommentVNode(""),
              _createCommentVNode(" \"Select all leads on page\" -checkbox when \"bulk manage\" -mode is active"),
              _createCommentVNode(""),
              ($options.bulkModeActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                    _createElementVNode("div", _hoisted_22, [
                      _createVNode(_component_b_checkbox, {
                        type: "is-primary",
                        modelValue: $options.allVisibleLeadsChecked,
                        "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($options.allVisibleLeadsChecked) = $event))
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ])
                  ]))
                : _createCommentVNode("v-if", true),
              _createCommentVNode(""),
              _createCommentVNode(" Empty cell, collapse subleads column on rows"),
              _createCommentVNode(""),
              _createElementVNode("div", {
                class: _normalizeClass(["rgrid-header", {              "row-collapse-fixed": !$options.bulkModeActive,              "row-collapse-fixed2": $options.bulkModeActive            }])
              }, _cache[32] || (_cache[32] = [
                _createElementVNode("div", { class: "rgrid-header-inner" }, null, -1 /* HOISTED */)
              ]), 2 /* CLASS */),
              _createCommentVNode(""),
              _createCommentVNode(" Name column, always first"),
              _createCommentVNode(""),
              _createElementVNode("div", {
                class: _normalizeClass(["rgrid-header", {              "lead-name-fixed": !$options.bulkModeActive,              "lead-name-fixed2": $options.bulkModeActive            }]),
                "data-column": "leadname"
              }, [
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, [
                    _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.settingsStore.label("leadname")), 1 /* TEXT */),
                    _createElementVNode("div", _hoisted_26, [
                      _createVNode(_component_columns_sorting, {
                        column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") },
                        onSortingChanged: _cache[17] || (_cache[17] = $event => ($options.scrollTableToTop()))
                      }, null, 8 /* PROPS */, ["column"])
                    ])
                  ]),
                  _createElementVNode("div", null, [
                    _createVNode(_component_columns_quicksearch, {
                      column: { "field": "leadname", "label": _ctx.settingsStore.label("leadname") }
                    }, null, 8 /* PROPS */, ["column"])
                  ])
                ]),
                _cache[33] || (_cache[33] = _createElementVNode("span", { class: "grid-header-resize-handle" }, null, -1 /* HOISTED */)),
                ($data.isTouch)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_27))
                  : _createCommentVNode("v-if", true)
              ], 2 /* CLASS */),
              _createCommentVNode(""),
              _createCommentVNode(" Rest of columns"),
              _createCommentVNode(""),
              (_openBlock(), _createElementBlock("div", {
                id: "rgrid-draggable-headers",
                key: $data.columnHeadersKey
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedAndOrderedColumns, (column, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "rgrid-header",
                    key: "headers_" + column.field,
                    "data-column": column.field,
                    style: _normalizeStyle({ "z-index": $options.selectedAndOrderedColumns.length - index })
                  }, [
                    _createElementVNode("div", {
                      class: _normalizeClass(["rgrid-header-inner", { "last": (index + 1) === $options.selectedAndOrderedColumns.length }])
                    }, [
                      _createElementVNode("div", _hoisted_29, [
                        _createElementVNode("div", _hoisted_30, _toDisplayString(column.label), 1 /* TEXT */),
                        (column.sortable)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              class: "column-sort",
                              onClick: $event => ($options.toggleSort(column.field))
                            }, [
                              (column.field === "creation_timestamp" || column.field === "next_appts" || column.field === "due_dates")
                                ? (_openBlock(), _createBlock(_component_b_icon, {
                                    key: 0,
                                    icon: _ctx.sortingStore.column === column.field ? (_ctx.sortingStore.order === "desc" ? "sort-clock-descending-outline" : "sort-clock-ascending-outline") : "swap-vertical",
                                    type: _ctx.sortingStore.column === column.field ? "is-dark" : "",
                                    "custom-class": "r-icon"
                                  }, null, 8 /* PROPS */, ["icon", "type"]))
                                : (column.field === "sales_ytd" || column.field === "sales_ytdvs")
                                  ? (_openBlock(), _createBlock(_component_b_icon, {
                                      key: 1,
                                      icon: _ctx.sortingStore.column === column.field ? (_ctx.sortingStore.order === "desc" ? "sort-numeric-descending" : "sort-numeric-ascending") : "swap-vertical",
                                      type: _ctx.sortingStore.column === column.field ? "is-dark" : "",
                                      "custom-class": "r-icon"
                                    }, null, 8 /* PROPS */, ["icon", "type"]))
                                  : (column.field.startsWith("question_"))
                                    ? (_openBlock(), _createBlock(_component_b_icon, {
                                        key: 2,
                                        icon: _ctx.sortingStore.column === column.field ? (_ctx.sortingStore.order === "desc" ? "sort-descending" : "sort-ascending") : "sort",
                                        type: _ctx.sortingStore.column === column.field ? "is-dark" : "",
                                        "custom-class": "r-icon"
                                      }, null, 8 /* PROPS */, ["icon", "type"]))
                                    : (_openBlock(), _createBlock(_component_b_icon, {
                                        key: 3,
                                        icon: _ctx.sortingStore.column === column.field ? (_ctx.sortingStore.order === "desc" ? "sort-alphabetical-descending" : "sort-alphabetical-ascending") : "swap-vertical",
                                        type: _ctx.sortingStore.column === column.field ? "is-dark" : "",
                                        "custom-class": "r-icon"
                                      }, null, 8 /* PROPS */, ["icon", "type"]))
                            ], 8 /* PROPS */, _hoisted_31))
                          : _createCommentVNode("v-if", true)
                      ]),
                      (column.filterable)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                            _createVNode(_component_columns_quicksearch, { column: column }, null, 8 /* PROPS */, ["column"])
                          ]))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */),
                    _cache[34] || (_cache[34] = _createElementVNode("span", { class: "grid-header-resize-handle" }, null, -1 /* HOISTED */)),
                    ($data.isTouch)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_33))
                      : _createCommentVNode("v-if", true)
                  ], 12 /* STYLE, PROPS */, _hoisted_28))
                }), 128 /* KEYED_FRAGMENT */))
              ])),
              _createCommentVNode(""),
              _createCommentVNode(""),
              _createCommentVNode(" Rows"),
              _createCommentVNode(""),
              _createCommentVNode(""),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.paginatedLeads, (lead) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "rgrid-row",
                  key: "lead_" + lead._id
                }, [
                  _createElementVNode("div", _hoisted_34, [
                    ($options.bulkModeActive)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                          _createVNode(_component_b_checkbox, {
                            type: "is-primary",
                            modelValue: this.selectedLeads.find(l => l._id === lead._id) ? true : false,
                            "onUpdate:modelValue": (checked) => $options.setLeadSelection(lead, checked)
                          }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                        ]))
                      : _createCommentVNode("v-if", true),
                    _createElementVNode("div", {
                      class: _normalizeClass(["rgrid-entry", {                  "row-collapse-fixed": !$options.bulkModeActive,                  "row-collapse-fixed2": $options.bulkModeActive                }])
                    }, [
                      ($options.leadHasSubleads(lead))
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "row-collapse-button",
                            onClick: $event => ($options.toggleSubLeadsCollapse(lead))
                          }, [
                            _createVNode(_component_b_icon, {
                              icon: this.collapsedMainLeads.has(lead._id) ? "chevron-up" : "chevron-right",
                              "custom-size": "mdi-24px",
                              "custom-class": "gray-icon"
                            }, null, 8 /* PROPS */, ["icon"])
                          ], 8 /* PROPS */, _hoisted_36))
                        : _createCommentVNode("v-if", true)
                    ], 2 /* CLASS */),
                    _createElementVNode("div", {
                      style: {"display":"contents"},
                      onClick: $event => ($options.leadClicked(lead))
                    }, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["rgrid-entry rtitle sm", {                    "lead-name-fixed": !$options.bulkModeActive,                    "lead-name-fixed2": $options.bulkModeActive                  }])
                      }, [
                        _createTextVNode(_toDisplayString(lead.name) + " ", 1 /* TEXT */),
                        (lead.index && !$options.useMapClustering && _ctx.leadsPerPage <= 50)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_38, "#" + _toDisplayString(lead.index), 1 /* TEXT */))
                          : _createCommentVNode("v-if", true)
                      ], 2 /* CLASS */),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedAndOrderedColumns, (column) => {
                        return (_openBlock(), _createElementBlock(_Fragment, {
                          key: lead._id + "_cell_" + column.field
                        }, [
                          (column.field === "belongs_to")
                            ? (_openBlock(), _createElementBlock("div", _hoisted_39, _toDisplayString(lead.project), 1 /* TEXT */))
                            : _createCommentVNode("v-if", true),
                          (column.field === "sales_totals")
                            ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                                (lead.hasSales)
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_41, [
                                      _createVNode(_component_chart_target_actual_last_yr_sales, {
                                        type: "minimal",
                                        leadId: lead._id
                                      }, null, 8 /* PROPS */, ["leadId"])
                                    ]))
                                  : _createCommentVNode("v-if", true)
                              ]))
                            : (column.field === "sales_totals_text")
                              ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                  (lead.hasSales)
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                        _createElementVNode("div", _hoisted_43, [
                                          _cache[35] || (_cache[35] = _createElementVNode("div", { class: "sales-label" }, "Target YTD: ", -1 /* HOISTED */)),
                                          _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$filters.toCurrency(this.sales(lead).target)), 1 /* TEXT */)
                                        ]),
                                        _createElementVNode("div", _hoisted_45, [
                                          _cache[36] || (_cache[36] = _createElementVNode("div", { class: "sales-label" }, "Actual YTD: ", -1 /* HOISTED */)),
                                          _createElementVNode("div", _hoisted_46, _toDisplayString(_ctx.$filters.toCurrency(this.sales(lead).monthly)), 1 /* TEXT */)
                                        ]),
                                        _createElementVNode("div", _hoisted_47, [
                                          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "sales-label" }, "Last year YTD: ", -1 /* HOISTED */)),
                                          _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.$filters.toCurrency(this.sales(lead).lastYear)), 1 /* TEXT */)
                                        ])
                                      ], 64 /* STABLE_FRAGMENT */))
                                    : _createCommentVNode("v-if", true)
                                ]))
                              : (column.field === "sales_bymonth")
                                ? (_openBlock(), _createElementBlock("div", _hoisted_49, [
                                    (lead.hasSales)
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_50, [
                                          _createVNode(_component_chart_monthly_sales, {
                                            type: "minimal",
                                            leadId: lead._id
                                          }, null, 8 /* PROPS */, ["leadId"])
                                        ]))
                                      : _createCommentVNode("v-if", true)
                                  ]))
                                : (column.field === "sales_ytd")
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_51, [
                                      (lead.hasSales)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_52, _toDisplayString(_ctx.$filters.toCurrency(this.sales(lead).monthly)), 1 /* TEXT */))
                                        : _createCommentVNode("v-if", true)
                                    ]))
                                  : (column.field === "sales_ytdvs")
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_53, [
                                        (lead.hasSales)
                                          ? (_openBlock(), _createElementBlock("span", _hoisted_54, _toDisplayString(this.sales(lead).vsTargetPercentage) + "%", 1 /* TEXT */))
                                          : _createCommentVNode("v-if", true)
                                      ]))
                                    : (column.field === "product")
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_55, [
                                          _createElementVNode("div", _hoisted_56, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                              return (_openBlock(), _createElementBlock("div", {
                                                class: "products-cell-product",
                                                key: "product_assignment_" + lead._id + "_" + idx
                                              }, [
                                                _createElementVNode("div", _hoisted_57, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                (idx !== lead.assignments.length - 1)
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_58, "| "))
                                                  : _createCommentVNode("v-if", true)
                                              ]))
                                            }), 128 /* KEYED_FRAGMENT */))
                                          ])
                                        ]))
                                      : (column.field === "status")
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_59, [
                                            (lead.hasUserAssignments)
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_60, [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                                    return (_openBlock(), _createElementBlock(_Fragment, null, [
                                                      (assignment.hasReport)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_61, [
                                                            _createElementVNode("div", {
                                                              class: "status-color",
                                                              style: _normalizeStyle({ "backgroundColor": assignment.statusColor })
                                                            }, null, 4 /* STYLE */),
                                                            _createElementVNode("div", _hoisted_62, [
                                                              _createElementVNode("span", _hoisted_63, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                              _cache[38] || (_cache[38] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                              _createElementVNode("span", _hoisted_64, _toDisplayString(assignment.statusText), 1 /* TEXT */)
                                                            ])
                                                          ]))
                                                        : _createCommentVNode("v-if", true)
                                                    ], 64 /* STABLE_FRAGMENT */))
                                                  }), 256 /* UNKEYED_FRAGMENT */))
                                                ]))
                                              : _createCommentVNode("v-if", true)
                                          ]))
                                        : (column.field === "assignment")
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_65, [
                                              (lead.assignments.length > 0)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_66, [
                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                                      return (_openBlock(), _createElementBlock("div", {
                                                        class: "smalltext semibold",
                                                        key: "user_assignment_" + lead._id + "_" + idx
                                                      }, [
                                                        _createElementVNode("span", _hoisted_67, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                        _cache[39] || (_cache[39] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                        _createElementVNode("span", _hoisted_68, _toDisplayString(assignment.assigneeFullName), 1 /* TEXT */)
                                                      ]))
                                                    }), 128 /* KEYED_FRAGMENT */))
                                                  ]))
                                                : _createCommentVNode("v-if", true)
                                            ]))
                                          : (column.field === "full_address")
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_69, [
                                                _createElementVNode("span", _hoisted_70, _toDisplayString(lead.address), 1 /* TEXT */)
                                              ]))
                                            : (column.field === "address")
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_71, [
                                                  _createElementVNode("span", _hoisted_72, _toDisplayString(lead.streetaddress), 1 /* TEXT */)
                                                ]))
                                              : (column.field === "city")
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_73, [
                                                    _createElementVNode("span", _hoisted_74, _toDisplayString(lead.city), 1 /* TEXT */)
                                                  ]))
                                                : (column.field === "state")
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_75, [
                                                      _createElementVNode("span", _hoisted_76, _toDisplayString(lead.formattedState), 1 /* TEXT */)
                                                    ]))
                                                  : (column.field === "country")
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_77, [
                                                        _createElementVNode("span", _hoisted_78, _toDisplayString(lead.formattedCountry), 1 /* TEXT */)
                                                      ]))
                                                    : (column.field === "address_misc")
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_79, [
                                                          _createElementVNode("span", _hoisted_80, _toDisplayString(lead.addressmisc), 1 /* TEXT */)
                                                        ]))
                                                      : (column.field === "due_dates")
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_81, [
                                                            (lead.hasDueDates)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_82, [
                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                                                    return (_openBlock(), _createElementBlock(_Fragment, {
                                                                      key: "report_due_date_" + lead._id + "_" + idx
                                                                    }, [
                                                                      (assignment.dueDate)
                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_83, [
                                                                            _createElementVNode("span", _hoisted_84, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                                            _cache[40] || (_cache[40] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                            _createElementVNode("span", _hoisted_85, _toDisplayString(_ctx.$dayjs(assignment.dueDate).format('ddd, ll')), 1 /* TEXT */)
                                                                          ]))
                                                                        : _createCommentVNode("v-if", true)
                                                                    ], 64 /* STABLE_FRAGMENT */))
                                                                  }), 128 /* KEYED_FRAGMENT */))
                                                                ]))
                                                              : _createCommentVNode("v-if", true)
                                                          ]))
                                                        : (column.field === "next_appts")
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_86, [
                                                              (lead.hasAppointments)
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_87, [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                                                      return (_openBlock(), _createElementBlock(_Fragment, {
                                                                        key: "report_next_appt_" + lead._id + "_" + idx
                                                                      }, [
                                                                        (assignment.nextAppt)
                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_88, [
                                                                              _createElementVNode("span", _hoisted_89, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                                              _cache[42] || (_cache[42] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                              _createElementVNode("span", _hoisted_90, [
                                                                                _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('ddd, ll')), 1 /* TEXT */),
                                                                                _cache[41] || (_cache[41] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('LT')), 1 /* TEXT */)
                                                                              ])
                                                                            ]))
                                                                          : _createCommentVNode("v-if", true)
                                                                      ], 64 /* STABLE_FRAGMENT */))
                                                                    }), 128 /* KEYED_FRAGMENT */))
                                                                  ]))
                                                                : _createCommentVNode("v-if", true)
                                                            ]))
                                                          : (column.field === "phone_numbers")
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_91, [
                                                                _createVNode(_component_b_taglist, null, {
                                                                  default: _withCtx(() => [
                                                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.phoneNumbers, (phoneNumber, idx) => {
                                                                      return (_openBlock(), _createBlock(_component_b_tag, { key: idx }, {
                                                                        default: _withCtx(() => [
                                                                          _createTextVNode(_toDisplayString(phoneNumber), 1 /* TEXT */)
                                                                        ]),
                                                                        _: 2 /* DYNAMIC */
                                                                      }, 1024 /* DYNAMIC_SLOTS */))
                                                                    }), 128 /* KEYED_FRAGMENT */))
                                                                  ]),
                                                                  _: 2 /* DYNAMIC */
                                                                }, 1024 /* DYNAMIC_SLOTS */)
                                                              ]))
                                                            : (column.field === "website")
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_92, [
                                                                  _createElementVNode("span", _hoisted_93, _toDisplayString(lead.website), 1 /* TEXT */)
                                                                ]))
                                                              : (column.field === "email")
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_94, [
                                                                    _createElementVNode("span", _hoisted_95, _toDisplayString(lead.email), 1 /* TEXT */)
                                                                  ]))
                                                                : (column.field === "contacts")
                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_96, [
                                                                      (Array.isArray(lead.contacts) && lead.contacts.length > 0)
                                                                        ? (_openBlock(), _createElementBlock("ul", _hoisted_97, [
                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.contacts, (contact) => {
                                                                              return (_openBlock(), _createElementBlock("li", {
                                                                                key: "lead_" + lead._id + "_contact_" + contact._id
                                                                              }, [
                                                                                _createTextVNode(_toDisplayString(contact.title2) + " " + _toDisplayString(contact.fullName) + " ", 1 /* TEXT */),
                                                                                (contact.email)
                                                                                  ? (_openBlock(), _createElementBlock("span", _hoisted_98, "<" + _toDisplayString(contact.email) + ">", 1 /* TEXT */))
                                                                                  : _createCommentVNode("v-if", true)
                                                                              ]))
                                                                            }), 128 /* KEYED_FRAGMENT */))
                                                                          ]))
                                                                        : _createCommentVNode("v-if", true)
                                                                    ]))
                                                                  : (column.field === "latitude")
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_99, [
                                                                        _createElementVNode("span", _hoisted_100, _toDisplayString(lead.location?.latitude), 1 /* TEXT */)
                                                                      ]))
                                                                    : (column.field === "longitude")
                                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_101, [
                                                                          _createElementVNode("span", _hoisted_102, _toDisplayString(lead.location?.longitude), 1 /* TEXT */)
                                                                        ]))
                                                                      : (column.field === "workzone1")
                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_103, [
                                                                            _createElementVNode("span", _hoisted_104, _toDisplayString(lead.zone1), 1 /* TEXT */)
                                                                          ]))
                                                                        : (column.field === "workzone2")
                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_105, [
                                                                              _createElementVNode("span", _hoisted_106, _toDisplayString(lead.zone2), 1 /* TEXT */)
                                                                            ]))
                                                                          : (column.field === "workzone3")
                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_107, [
                                                                                _createElementVNode("span", _hoisted_108, _toDisplayString(lead.zone3), 1 /* TEXT */)
                                                                              ]))
                                                                            : (column.field === "workzone4")
                                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_109, [
                                                                                  _createElementVNode("span", _hoisted_110, _toDisplayString(lead.zone4), 1 /* TEXT */)
                                                                                ]))
                                                                              : (column.field === "region1")
                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_111, [
                                                                                    _createElementVNode("span", _hoisted_112, _toDisplayString(lead.region), 1 /* TEXT */)
                                                                                  ]))
                                                                                : (column.field === "region2")
                                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_113, [
                                                                                      _createElementVNode("span", _hoisted_114, _toDisplayString(lead.region2), 1 /* TEXT */)
                                                                                    ]))
                                                                                  : (column.field === "tags")
                                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_115, [
                                                                                        _createVNode(_component_b_taglist, null, {
                                                                                          default: _withCtx(() => [
                                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.tags, (tag, idx) => {
                                                                                              return (_openBlock(), _createBlock(_component_b_tag, { key: idx }, {
                                                                                                default: _withCtx(() => [
                                                                                                  _createTextVNode(_toDisplayString(tag), 1 /* TEXT */)
                                                                                                ]),
                                                                                                _: 2 /* DYNAMIC */
                                                                                              }, 1024 /* DYNAMIC_SLOTS */))
                                                                                            }), 128 /* KEYED_FRAGMENT */))
                                                                                          ]),
                                                                                          _: 2 /* DYNAMIC */
                                                                                        }, 1024 /* DYNAMIC_SLOTS */)
                                                                                      ]))
                                                                                    : (column.field === "type")
                                                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_116, [
                                                                                          _createElementVNode("span", _hoisted_117, _toDisplayString(lead.projecttype), 1 /* TEXT */)
                                                                                        ]))
                                                                                      : (column.field === "customer_type")
                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_118, [
                                                                                            _createElementVNode("span", _hoisted_119, _toDisplayString(lead.customertype), 1 /* TEXT */)
                                                                                          ]))
                                                                                        : (column.field === "creation_timestamp")
                                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_120, [
                                                                                              _createElementVNode("span", _hoisted_121, _toDisplayString(_ctx.$dayjs(lead.creation_timestamp).format('llll')), 1 /* TEXT */)
                                                                                            ]))
                                                                                          : (column.field === "last_comment")
                                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_122, [
                                                                                                (_ctx.leadCommentingMode === "per_assignment")
                                                                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                                                                      ($options.getLastUserCommentPerAssignment(lead).length > 0)
                                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_123, [
                                                                                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.getLastUserCommentPerAssignment(lead), (assignment) => {
                                                                                                              return (_openBlock(), _createElementBlock("div", null, [
                                                                                                                _createElementVNode("span", _hoisted_124, "(" + _toDisplayString(assignment.productName) + ") ", 1 /* TEXT */),
                                                                                                                _createElementVNode("span", _hoisted_125, _toDisplayString(assignment.lastComment.commentor) + " (" + _toDisplayString(_ctx.$dayjs(assignment.lastComment.postedAt).format('ll')) + ")", 1 /* TEXT */),
                                                                                                                _cache[43] || (_cache[43] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                                                _createElementVNode("span", _hoisted_126, _toDisplayString(assignment.lastComment.comment), 1 /* TEXT */)
                                                                                                              ]))
                                                                                                            }), 256 /* UNKEYED_FRAGMENT */))
                                                                                                          ]))
                                                                                                        : _createCommentVNode("v-if", true)
                                                                                                    ], 64 /* STABLE_FRAGMENT */))
                                                                                                  : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                                                      ($options.getLastLeadUserComment(lead.comments) !== "")
                                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_127, [
                                                                                                            _createElementVNode("span", _hoisted_128, _toDisplayString($options.getLastLeadUserComment(lead.comments).commentor) + " (" + _toDisplayString(_ctx.$dayjs($options.getLastLeadUserComment(lead.comments).postedAt).format('ll')) + ")", 1 /* TEXT */),
                                                                                                            _cache[44] || (_cache[44] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                                            _createElementVNode("span", _hoisted_129, _toDisplayString($options.getLastLeadUserComment(lead.comments).comment), 1 /* TEXT */)
                                                                                                          ]))
                                                                                                        : _createCommentVNode("v-if", true)
                                                                                                    ], 64 /* STABLE_FRAGMENT */))
                                                                                              ]))
                                                                                            : (column.field.startsWith("question_"))
                                                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_130, [
                                                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(lead.assignments, (assignment, idx) => {
                                                                                                    return (_openBlock(), _createElementBlock(_Fragment, {
                                                                                                      key: "question_assignments_" + lead._id + "_" + idx
                                                                                                    }, [
                                                                                                      (assignment.answers[column.templateQuestion._id])
                                                                                                        ? (_openBlock(), _createElementBlock("span", _hoisted_131, [
                                                                                                            _createCommentVNode(" Slider and freeText, answer is in 'value'"),
                                                                                                            ([ "slider", "free_text" ].includes($options.questionType(column.templateQuestion)))
                                                                                                              ? (_openBlock(), _createElementBlock("span", _hoisted_132, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.value), 1 /* TEXT */))
                                                                                                              : ($options.questionType(column.templateQuestion) === "number")
                                                                                                                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                                                                    _createCommentVNode(" Number question, answer is in 'num'"),
                                                                                                                    _createElementVNode("span", null, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.num), 1 /* TEXT */)
                                                                                                                  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                : ($options.questionType(column.templateQuestion) === "personal_sales")
                                                                                                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                                                                                                      _createCommentVNode(" Personal sales question, answer is in 'quantity'"),
                                                                                                                      _createElementVNode("span", null, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.quantity), 1 /* TEXT */)
                                                                                                                    ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                  : ([ "multiple", "multiple_many" ].includes($options.questionType(column.templateQuestion)))
                                                                                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                                                                                                        _createCommentVNode(" Multiple choice answers"),
                                                                                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assignment.answers[column.templateQuestion._id].answer.choices, (choice, idx) => {
                                                                                                                          return (_openBlock(), _createElementBlock("span", {
                                                                                                                            class: "smalltext semibold",
                                                                                                                            key: "answer_" + assignment.answers[column.templateQuestion._id]._id + "_choice_" + idx
                                                                                                                          }, [
                                                                                                                            _createTextVNode("- " + _toDisplayString(choice), 1 /* TEXT */),
                                                                                                                            _cache[45] || (_cache[45] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                                                                                                          ]))
                                                                                                                        }), 128 /* KEYED_FRAGMENT */))
                                                                                                                      ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                    : _createCommentVNode("v-if", true)
                                                                                                          ]))
                                                                                                        : _createCommentVNode("v-if", true)
                                                                                                    ], 64 /* STABLE_FRAGMENT */))
                                                                                                  }), 128 /* KEYED_FRAGMENT */))
                                                                                                ]))
                                                                                              : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ], 8 /* PROPS */, _hoisted_37)
                  ]),
                  _createCommentVNode(""),
                  _createCommentVNode(""),
                  _createCommentVNode(" Subrows"),
                  _createCommentVNode(""),
                  _createCommentVNode(" NOTE: This section and the \"main rows\" above are identical - they should really"),
                  _createCommentVNode("       be in a shared component. However, for performance reasons, right now we'll"),
                  _createCommentVNode("       keep them like this and endure the slightly awkward maintainability"),
                  _createCommentVNode(""),
                  _createCommentVNode(""),
                  (_ctx.collapsedMainLeads.has(lead._id))
                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(lead.subleads, (sublead) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "rgrid-subrow",
                          key: sublead._id
                        }, [
                          ($options.bulkModeActive)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_133, [
                                _createVNode(_component_b_field, null, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_checkbox, {
                                      type: "is-primary",
                                      modelValue: this.selectedLeads.find(l => l._id === sublead._id) ? true : false,
                                      "onUpdate:modelValue": (checked) => $options.setLeadSelection(sublead, checked)
                                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1024 /* DYNAMIC_SLOTS */)
                              ]))
                            : _createCommentVNode("v-if", true),
                          _createElementVNode("div", {
                            style: {"display":"contents"},
                            onClick: $event => ($options.leadClicked(sublead))
                          }, [
                            _createElementVNode("div", {
                              class: _normalizeClass(["rgrid-entry", {                      "row-collapse-fixed": !$options.bulkModeActive,                      "row-collapse-fixed2": $options.bulkModeActive                    }])
                            }, null, 2 /* CLASS */),
                            _createElementVNode("div", {
                              class: _normalizeClass(["rgrid-entry rtitle sm", {                      "lead-name-fixed": !$options.bulkModeActive,                      "lead-name-fixed2": $options.bulkModeActive                    }])
                            }, [
                              _createElementVNode("div", _hoisted_135, [
                                _createElementVNode("div", _hoisted_136, [
                                  _createVNode(_component_b_icon, { icon: "arrow-right-thin" })
                                ]),
                                _createElementVNode("div", null, [
                                  _createTextVNode(_toDisplayString(sublead.name) + " ", 1 /* TEXT */),
                                  (sublead.index && !$options.useMapClustering && _ctx.leadsPerPage <= 50)
                                    ? (_openBlock(), _createElementBlock("span", _hoisted_137, "#" + _toDisplayString(sublead.index), 1 /* TEXT */))
                                    : _createCommentVNode("v-if", true)
                                ])
                              ])
                            ], 2 /* CLASS */),
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.selectedAndOrderedColumns, (column) => {
                              return (_openBlock(), _createElementBlock(_Fragment, {
                                key: sublead._id + "_cell_" + column.field
                              }, [
                                (column.field === "belongs_to")
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_138, _toDisplayString(sublead.project), 1 /* TEXT */))
                                  : _createCommentVNode("v-if", true),
                                (column.field === "sales_totals")
                                  ? (_openBlock(), _createElementBlock("div", _hoisted_139, [
                                      (sublead.hasSales)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_140, [
                                            _createVNode(_component_chart_target_actual_last_yr_sales, {
                                              type: "minimal",
                                              leadId: sublead._id
                                            }, null, 8 /* PROPS */, ["leadId"])
                                          ]))
                                        : _createCommentVNode("v-if", true)
                                    ]))
                                  : (column.field === "sales_totals_text")
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_141, [
                                        (sublead.hasSales)
                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                              _createElementVNode("div", _hoisted_142, [
                                                _cache[46] || (_cache[46] = _createElementVNode("div", { class: "sales-label" }, "Target YTD: ", -1 /* HOISTED */)),
                                                _createElementVNode("div", _hoisted_143, _toDisplayString(_ctx.$filters.toCurrency(this.sales(sublead).target)), 1 /* TEXT */)
                                              ]),
                                              _createElementVNode("div", _hoisted_144, [
                                                _cache[47] || (_cache[47] = _createElementVNode("div", { class: "sales-label" }, "Actual YTD: ", -1 /* HOISTED */)),
                                                _createElementVNode("div", _hoisted_145, _toDisplayString(_ctx.$filters.toCurrency(this.sales(sublead).monthly)), 1 /* TEXT */)
                                              ]),
                                              _createElementVNode("div", _hoisted_146, [
                                                _cache[48] || (_cache[48] = _createElementVNode("div", { class: "sales-label" }, "Last year YTD: ", -1 /* HOISTED */)),
                                                _createElementVNode("div", _hoisted_147, _toDisplayString(_ctx.$filters.toCurrency(this.sales(sublead).lastYear)), 1 /* TEXT */)
                                              ])
                                            ], 64 /* STABLE_FRAGMENT */))
                                          : _createCommentVNode("v-if", true)
                                      ]))
                                    : (column.field === "sales_bymonth")
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_148, [
                                          (sublead.hasSales)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_149, [
                                                _createVNode(_component_chart_monthly_sales, {
                                                  type: "minimal",
                                                  leadId: sublead._id
                                                }, null, 8 /* PROPS */, ["leadId"])
                                              ]))
                                            : _createCommentVNode("v-if", true)
                                        ]))
                                      : (column.field === "sales_ytd")
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_150, [
                                            (sublead.hasSales)
                                              ? (_openBlock(), _createElementBlock("span", _hoisted_151, _toDisplayString(_ctx.$filters.toCurrency(this.sales(sublead).monthly)), 1 /* TEXT */))
                                              : _createCommentVNode("v-if", true)
                                          ]))
                                        : (column.field === "sales_ytdvs")
                                          ? (_openBlock(), _createElementBlock("div", _hoisted_152, [
                                              (sublead.hasSales)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_153, _toDisplayString(this.sales(sublead).vsTargetPercentage) + "%", 1 /* TEXT */))
                                                : _createCommentVNode("v-if", true)
                                            ]))
                                          : (column.field === "product")
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_154, [
                                                _createElementVNode("div", _hoisted_155, [
                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                    return (_openBlock(), _createElementBlock("div", {
                                                      class: "products-cell-product",
                                                      key: "product_assignment_" + sublead._id + "_" + idx
                                                    }, [
                                                      _createElementVNode("div", _hoisted_156, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                      (idx !== sublead.products.length - 1)
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_157, "| "))
                                                        : _createCommentVNode("v-if", true)
                                                    ]))
                                                  }), 128 /* KEYED_FRAGMENT */))
                                                ])
                                              ]))
                                            : (column.field === "status")
                                              ? (_openBlock(), _createElementBlock("div", _hoisted_158, [
                                                  (sublead.hasUserAssignments)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_159, [
                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                          return (_openBlock(), _createElementBlock(_Fragment, null, [
                                                            (assignment.assigneeId)
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_160, [
                                                                  _createElementVNode("div", {
                                                                    class: "status-color",
                                                                    style: _normalizeStyle({ "backgroundColor": assignment.statusColor })
                                                                  }, null, 4 /* STYLE */),
                                                                  _createElementVNode("div", _hoisted_161, [
                                                                    _createElementVNode("span", _hoisted_162, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                                    _cache[49] || (_cache[49] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                    _createElementVNode("span", _hoisted_163, _toDisplayString(assignment.statusText), 1 /* TEXT */)
                                                                  ])
                                                                ]))
                                                              : _createCommentVNode("v-if", true)
                                                          ], 64 /* STABLE_FRAGMENT */))
                                                        }), 256 /* UNKEYED_FRAGMENT */))
                                                      ]))
                                                    : _createCommentVNode("v-if", true)
                                                ]))
                                              : (column.field === "assignment")
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_164, [
                                                    (sublead.hasUserAssignments)
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_165, [
                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                            return (_openBlock(), _createElementBlock("div", {
                                                              class: "smalltext semibold",
                                                              key: "user_assignment_" + sublead._id + "_" + idx
                                                            }, [
                                                              _createElementVNode("span", _hoisted_166, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                              _cache[50] || (_cache[50] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                              _createElementVNode("span", _hoisted_167, _toDisplayString(assignment.assigneeFullName), 1 /* TEXT */)
                                                            ]))
                                                          }), 128 /* KEYED_FRAGMENT */))
                                                        ]))
                                                      : _createCommentVNode("v-if", true)
                                                  ]))
                                                : (column.field === "full_address")
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_168, [
                                                      _createElementVNode("span", _hoisted_169, _toDisplayString(sublead.address), 1 /* TEXT */)
                                                    ]))
                                                  : (column.field === "address")
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_170, [
                                                        _createElementVNode("span", _hoisted_171, _toDisplayString(sublead.streetaddress), 1 /* TEXT */)
                                                      ]))
                                                    : (column.field === "city")
                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_172, [
                                                          _createElementVNode("span", _hoisted_173, _toDisplayString(sublead.city), 1 /* TEXT */)
                                                        ]))
                                                      : (column.field === "state")
                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_174, [
                                                            _createElementVNode("span", _hoisted_175, _toDisplayString(sublead.formattedState), 1 /* TEXT */)
                                                          ]))
                                                        : (column.field === "country")
                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_176, [
                                                              _createElementVNode("span", _hoisted_177, _toDisplayString(sublead.formattedCountry), 1 /* TEXT */)
                                                            ]))
                                                          : (column.field === "address_misc")
                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_178, [
                                                                _createElementVNode("span", _hoisted_179, _toDisplayString(sublead.addressmisc), 1 /* TEXT */)
                                                              ]))
                                                            : (column.field === "due_dates")
                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_180, [
                                                                  (sublead.hasDueDates)
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_181, [
                                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                                          return (_openBlock(), _createElementBlock(_Fragment, {
                                                                            key: "report_due_date_" + sublead._id + "_" + idx
                                                                          }, [
                                                                            (assignment.dueDate)
                                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_182, [
                                                                                  _createElementVNode("span", _hoisted_183, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                                                  _cache[51] || (_cache[51] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                  _createElementVNode("span", _hoisted_184, _toDisplayString(_ctx.$dayjs(assignment.dueDate).format('ddd, ll')), 1 /* TEXT */)
                                                                                ]))
                                                                              : _createCommentVNode("v-if", true)
                                                                          ], 64 /* STABLE_FRAGMENT */))
                                                                        }), 128 /* KEYED_FRAGMENT */))
                                                                      ]))
                                                                    : _createCommentVNode("v-if", true)
                                                                ]))
                                                              : (column.field === "next_appts")
                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_185, [
                                                                    (sublead.hasAppointments)
                                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_186, [
                                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                                            return (_openBlock(), _createElementBlock(_Fragment, {
                                                                              key: "report_next_appt_" + sublead._id + "_" + idx
                                                                            }, [
                                                                              (assignment.nextAppt)
                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_187, [
                                                                                    _createElementVNode("span", _hoisted_188, _toDisplayString(assignment.productName), 1 /* TEXT */),
                                                                                    _cache[53] || (_cache[53] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                    _createElementVNode("span", _hoisted_189, [
                                                                                      _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('ddd, ll')), 1 /* TEXT */),
                                                                                      _cache[52] || (_cache[52] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                      _createTextVNode(_toDisplayString(_ctx.$dayjs(assignment.nextAppt).format('LT')), 1 /* TEXT */)
                                                                                    ])
                                                                                  ]))
                                                                                : _createCommentVNode("v-if", true)
                                                                            ], 64 /* STABLE_FRAGMENT */))
                                                                          }), 128 /* KEYED_FRAGMENT */))
                                                                        ]))
                                                                      : _createCommentVNode("v-if", true)
                                                                  ]))
                                                                : (column.field === "phone_numbers")
                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_190, [
                                                                      _createVNode(_component_b_taglist, null, {
                                                                        default: _withCtx(() => [
                                                                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.phoneNumbers, (phoneNumber, idx) => {
                                                                            return (_openBlock(), _createBlock(_component_b_tag, { key: idx }, {
                                                                              default: _withCtx(() => [
                                                                                _createTextVNode(_toDisplayString(phoneNumber), 1 /* TEXT */)
                                                                              ]),
                                                                              _: 2 /* DYNAMIC */
                                                                            }, 1024 /* DYNAMIC_SLOTS */))
                                                                          }), 128 /* KEYED_FRAGMENT */))
                                                                        ]),
                                                                        _: 2 /* DYNAMIC */
                                                                      }, 1024 /* DYNAMIC_SLOTS */)
                                                                    ]))
                                                                  : (column.field === "website")
                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_191, [
                                                                        _createElementVNode("span", _hoisted_192, _toDisplayString(sublead.website), 1 /* TEXT */)
                                                                      ]))
                                                                    : (column.field === "email")
                                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_193, [
                                                                          _createElementVNode("span", _hoisted_194, _toDisplayString(sublead.email), 1 /* TEXT */)
                                                                        ]))
                                                                      : (column.field === "contacts")
                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_195, [
                                                                            (Array.isArray(sublead.contacts) && sublead.contacts.length > 0)
                                                                              ? (_openBlock(), _createElementBlock("ul", _hoisted_196, [
                                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.contacts, (contact) => {
                                                                                    return (_openBlock(), _createElementBlock("li", {
                                                                                      key: "lead_" + sublead._id + "_contact_" + contact._id
                                                                                    }, _toDisplayString(contact.fullName) + " <" + _toDisplayString(contact.email) + ">", 1 /* TEXT */))
                                                                                  }), 128 /* KEYED_FRAGMENT */))
                                                                                ]))
                                                                              : _createCommentVNode("v-if", true)
                                                                          ]))
                                                                        : (column.field === "latitude")
                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_197, [
                                                                              _createElementVNode("span", _hoisted_198, _toDisplayString(sublead.location?.latitude), 1 /* TEXT */)
                                                                            ]))
                                                                          : (column.field === "longitude")
                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_199, [
                                                                                _createElementVNode("span", _hoisted_200, _toDisplayString(sublead.location?.longitude), 1 /* TEXT */)
                                                                              ]))
                                                                            : (column.field === "workzone1")
                                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_201, [
                                                                                  _createElementVNode("span", _hoisted_202, _toDisplayString(sublead.zone1), 1 /* TEXT */)
                                                                                ]))
                                                                              : (column.field === "workzone2")
                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_203, [
                                                                                    _createElementVNode("span", _hoisted_204, _toDisplayString(sublead.zone2), 1 /* TEXT */)
                                                                                  ]))
                                                                                : (column.field === "workzone3")
                                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_205, [
                                                                                      _createElementVNode("span", _hoisted_206, _toDisplayString(sublead.zone3), 1 /* TEXT */)
                                                                                    ]))
                                                                                  : (column.field === "workzone4")
                                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_207, [
                                                                                        _createElementVNode("span", _hoisted_208, _toDisplayString(sublead.zone4), 1 /* TEXT */)
                                                                                      ]))
                                                                                    : (column.field === "region1")
                                                                                      ? (_openBlock(), _createElementBlock("div", _hoisted_209, [
                                                                                          _createElementVNode("span", _hoisted_210, _toDisplayString(sublead.region), 1 /* TEXT */)
                                                                                        ]))
                                                                                      : (column.field === "region2")
                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_211, [
                                                                                            _createElementVNode("span", _hoisted_212, _toDisplayString(sublead.region2), 1 /* TEXT */)
                                                                                          ]))
                                                                                        : (column.field === "tags")
                                                                                          ? (_openBlock(), _createElementBlock("div", _hoisted_213, [
                                                                                              _createVNode(_component_b_taglist, null, {
                                                                                                default: _withCtx(() => [
                                                                                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.tags, (tag, idx) => {
                                                                                                    return (_openBlock(), _createBlock(_component_b_tag, { key: idx }, {
                                                                                                      default: _withCtx(() => [
                                                                                                        _createTextVNode(_toDisplayString(tag), 1 /* TEXT */)
                                                                                                      ]),
                                                                                                      _: 2 /* DYNAMIC */
                                                                                                    }, 1024 /* DYNAMIC_SLOTS */))
                                                                                                  }), 128 /* KEYED_FRAGMENT */))
                                                                                                ]),
                                                                                                _: 2 /* DYNAMIC */
                                                                                              }, 1024 /* DYNAMIC_SLOTS */)
                                                                                            ]))
                                                                                          : (column.field === "type")
                                                                                            ? (_openBlock(), _createElementBlock("div", _hoisted_214, [
                                                                                                _createElementVNode("span", _hoisted_215, _toDisplayString(sublead.projecttype), 1 /* TEXT */)
                                                                                              ]))
                                                                                            : (column.field === "customer_type")
                                                                                              ? (_openBlock(), _createElementBlock("div", _hoisted_216, [
                                                                                                  _createElementVNode("span", _hoisted_217, _toDisplayString(sublead.customertype), 1 /* TEXT */)
                                                                                                ]))
                                                                                              : (column.field === "creation_timestamp")
                                                                                                ? (_openBlock(), _createElementBlock("div", _hoisted_218, [
                                                                                                    _createElementVNode("span", _hoisted_219, _toDisplayString(_ctx.$dayjs(sublead.creation_timestamp).format('llll')), 1 /* TEXT */)
                                                                                                  ]))
                                                                                                : (column.field === "last_comment")
                                                                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_220, [
                                                                                                      ($options.getLastLeadUserComment(sublead.comments) !== "")
                                                                                                        ? (_openBlock(), _createElementBlock("div", _hoisted_221, [
                                                                                                            _createElementVNode("span", _hoisted_222, _toDisplayString($options.getLastLeadUserComment(sublead.comments).commentor) + " (" + _toDisplayString(_ctx.$dayjs($options.getLastLeadUserComment(sublead.comments).postedAt).format('ll')) + ")", 1 /* TEXT */),
                                                                                                            _cache[54] || (_cache[54] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                                                                                            _createElementVNode("span", _hoisted_223, _toDisplayString($options.getLastLeadUserComment(sublead.comments).comment), 1 /* TEXT */)
                                                                                                          ]))
                                                                                                        : _createCommentVNode("v-if", true)
                                                                                                    ]))
                                                                                                  : (column.field.startsWith("question_"))
                                                                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_224, [
                                                                                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sublead.assignments, (assignment, idx) => {
                                                                                                          return (_openBlock(), _createElementBlock(_Fragment, {
                                                                                                            key: "question_assignments_" + sublead._id + "_" + idx
                                                                                                          }, [
                                                                                                            (assignment.answers[column.templateQuestion._id])
                                                                                                              ? (_openBlock(), _createElementBlock("span", _hoisted_225, [
                                                                                                                  _createCommentVNode(" Slider and freeText, answer is in 'value'"),
                                                                                                                  ([ "slider", "free_text" ].includes($options.questionType(column.templateQuestion)))
                                                                                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_226, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.value), 1 /* TEXT */))
                                                                                                                    : ($options.questionType(column.templateQuestion) === "number")
                                                                                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                                                                                          _createCommentVNode(" Number question, answer is in 'num'"),
                                                                                                                          _createElementVNode("span", null, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.num), 1 /* TEXT */)
                                                                                                                        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                      : ($options.questionType(column.templateQuestion) === "personal_sales")
                                                                                                                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                                                                                                            _createCommentVNode(" Personal sales question, answer is in 'quantity'"),
                                                                                                                            _createElementVNode("span", null, _toDisplayString(assignment.answers[column.templateQuestion._id].answer.quantity), 1 /* TEXT */)
                                                                                                                          ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                        : ([ "multiple", "multiple_many" ].includes($options.questionType(column.templateQuestion)))
                                                                                                                          ? (_openBlock(), _createElementBlock(_Fragment, { key: 3 }, [
                                                                                                                              _createCommentVNode(" Multiple choice answers"),
                                                                                                                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(assignment.answers[column.templateQuestion._id].answer.choices, (choice, idx) => {
                                                                                                                                return (_openBlock(), _createElementBlock("span", {
                                                                                                                                  class: "smalltext semibold",
                                                                                                                                  key: "answer_" + assignment.answers[column.templateQuestion._id]._id + "_choice_" + idx
                                                                                                                                }, [
                                                                                                                                  _createTextVNode("- " + _toDisplayString(choice), 1 /* TEXT */),
                                                                                                                                  _cache[55] || (_cache[55] = _createElementVNode("br", null, null, -1 /* HOISTED */))
                                                                                                                                ]))
                                                                                                                              }), 128 /* KEYED_FRAGMENT */))
                                                                                                                            ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                                                                                                                          : _createCommentVNode("v-if", true)
                                                                                                                ]))
                                                                                                              : _createCommentVNode("v-if", true)
                                                                                                          ], 64 /* STABLE_FRAGMENT */))
                                                                                                        }), 128 /* KEYED_FRAGMENT */))
                                                                                                      ]))
                                                                                                    : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            }), 128 /* KEYED_FRAGMENT */))
                          ], 8 /* PROPS */, _hoisted_134)
                        ]))
                      }), 128 /* KEYED_FRAGMENT */))
                    : _createCommentVNode("v-if", true)
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ], 4 /* STYLE */)
          ]))
        : _createCommentVNode("v-if", true)
    ]),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createCommentVNode(" ███╗   ███╗ ██████╗ ██████╗  █████╗ ██╗     ███████╗"),
    _createCommentVNode(" ████╗ ████║██╔═══██╗██╔══██╗██╔══██╗██║     ██╔════╝"),
    _createCommentVNode(" ██╔████╔██║██║   ██║██║  ██║███████║██║     ███████╗"),
    _createCommentVNode(" ██║╚██╔╝██║██║   ██║██║  ██║██╔══██║██║     ╚════██║"),
    _createCommentVNode(" ██║ ╚═╝ ██║╚██████╔╝██████╔╝██║  ██║███████╗███████║"),
    _createCommentVNode(" ╚═╝     ╚═╝ ╚═════╝ ╚═════╝ ╚═╝  ╚═╝╚══════╝╚══════╝"),
    _createCommentVNode(""),
    _createCommentVNode(""),
    _createVNode(_component_b_modal, {
      class: "fit-content-height",
      modelValue: $data.excelExportConfigModalOpen,
      "onUpdate:modelValue": _cache[21] || (_cache[21] = $event => (($data.excelExportConfigModalOpen) = $event)),
      width: 640,
      scroll: "keep",
      "can-cancel": [ 'escape', 'outside' ],
      "on-cancel": $options.closeExcelExportConfigModal
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_227, [
          _createElementVNode("div", _hoisted_228, [
            _createVNode(_component_b_icon, {
              icon: "microsoft-excel",
              "custom-class": "modal-excel"
            }),
            _createElementVNode("h2", _hoisted_229, _toDisplayString(_ctx.l('manage-leads-excel-export/dialog-title')), 1 /* TEXT */),
            _createVNode(_component_b_field, null, {
              default: _withCtx(() => [
                _createVNode(_component_b_checkbox, {
                  modelValue: $data.excelExportIncludeContacts,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = $event => (($data.excelExportIncludeContacts) = $event))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('manage-leads-excel-export/repeat-lead-per-contact')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"])
              ]),
              _: 1 /* STABLE */
            }),
            _createElementVNode("p", _hoisted_230, _toDisplayString(_ctx.l('manage-leads-excel-export/dialog-body')), 1 /* TEXT */),
            _createElementVNode("p", {
              class: "modal-text",
              innerHTML: _ctx.l_escaped("manage-leads-excel-export/dialog-note")
            }, null, 8 /* PROPS */, _hoisted_231),
            _createElementVNode("div", _hoisted_232, [
              _createVNode(_component_b_button, {
                type: "is-primary",
                rounded: "",
                onClick: _cache[19] || (_cache[19] = $event => ($options.exportLeadsViewToExcel())),
                loading: $data.leadsExcelExportLoading,
                disabled: $data.leadsExcelExportLoading
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/export')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"]),
              _createVNode(_component_b_button, {
                loading: $data.leadsExcelExportLoading,
                disabled: $data.leadsExcelExportLoading,
                type: "is-light",
                rounded: "",
                onClick: _cache[20] || (_cache[20] = $event => ($options.closeExcelExportConfigModal()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["loading", "disabled"])
            ])
          ])
        ])
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["modelValue", "on-cancel"])
  ]))
}