import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, withModifiers as _withModifiers } from "vue"
import _imports_0 from 'FRONTEND/images/account-user-profile-placeholder.png'


const _hoisted_1 = { class: "page-content-inner is-relative" }
const _hoisted_2 = { class: "columns account-page-columns" }
const _hoisted_3 = { class: "column is-6" }
const _hoisted_4 = { class: "columns" }
const _hoisted_5 = { class: "column is-4" }
const _hoisted_6 = { class: "rudolfbox account-page-box user-info-box" }
const _hoisted_7 = { class: "user-title" }
const _hoisted_8 = {
  key: 0,
  class: "user-organization mb-1"
}
const _hoisted_9 = { class: "user-role" }
const _hoisted_10 = { class: "mb-4" }
const _hoisted_11 = { class: "column is-8" }
const _hoisted_12 = { class: "rudolfbox account-page-box edit-my-information-box" }
const _hoisted_13 = { class: "rtitle sm has-text-centered mb-4" }
const _hoisted_14 = { class: "block" }
const _hoisted_15 = { class: "has-text-right mb-4" }
const _hoisted_16 = { class: "rudolfbox account-page-box" }
const _hoisted_17 = { class: "rtitle sm has-text-centered mb-4" }
const _hoisted_18 = { class: "block" }
const _hoisted_19 = { class: "has-text-right mb-4" }
const _hoisted_20 = { class: "column is-6" }
const _hoisted_21 = { class: "columns" }
const _hoisted_22 = { class: "column is-12" }
const _hoisted_23 = { class: "rudolfbox account-page-box change-password-box" }
const _hoisted_24 = { class: "is-flex-grow-0" }
const _hoisted_25 = { class: "has-text-right mb-4 mt-5" }
const _hoisted_26 = { class: "rudolfbox account-page-box send-feedback-box" }
const _hoisted_27 = { class: "rtitle sm has-text-centered mb-4" }
const _hoisted_28 = { class: "mb-4" }
const _hoisted_29 = { class: "lead-fields-selection mb-4" }
const _hoisted_30 = { class: "has-text-right mb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_loading = _resolveComponent("b-loading")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.authStore.user)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createElementVNode("div", null, [
                      _cache[20] || (_cache[20] = _createElementVNode("img", {
                        class: "mt-3 mb-4",
                        src: _imports_0
                      }, null, -1 /* HOISTED */)),
                      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.authStore.user.fullName), 1 /* TEXT */),
                      (_ctx.authStore.user.organization)
                        ? (_openBlock(), _createElementBlock("h5", _hoisted_8, _toDisplayString(_ctx.authStore.user.organization), 1 /* TEXT */))
                        : _createCommentVNode("v-if", true),
                      _createElementVNode("h4", _hoisted_9, _toDisplayString(_ctx.l('generic/role')) + ": " + _toDisplayString(_ctx.authStore.user.role), 1 /* TEXT */)
                    ]),
                    _createElementVNode("div", null, [
                      _cache[22] || (_cache[22] = _createElementVNode("div", { class: "separator" }, null, -1 /* HOISTED */)),
                      _createElementVNode("p", _hoisted_10, [
                        _createTextVNode(_toDisplayString(_ctx.l('account/logged-in-email')), 1 /* TEXT */),
                        _cache[21] || (_cache[21] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                        _createTextVNode(_toDisplayString(_ctx.authStore.user.email), 1 /* TEXT */)
                      ]),
                      _createVNode(_component_b_button, {
                        class: "mb-4",
                        onClick: _cache[0] || (_cache[0] = $event => ($options.logout())),
                        type: "is-primary",
                        rounded: ""
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.l('account/logout-button-text')), 1 /* TEXT */)
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ], 64 /* STABLE_FRAGMENT */))
                : _createCommentVNode("v-if", true)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("h3", _hoisted_13, _toDisplayString(_ctx.l('account/edit-my-information')), 1 /* TEXT */),
              _createElementVNode("form", {
                onSubmit: _cache[9] || (_cache[9] = _withModifiers($event => ($options.saveMyInformation()), ["prevent"])),
                style: {"height":"100%"}
              }, [
                _createElementVNode("div", _hoisted_14, [
                  _createVNode(_component_b_radio, {
                    class: "mr-6",
                    modelValue: $data.editedTitle,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => (($data.editedTitle) = $event)),
                    name: "editedTitle",
                    "native-value": "Mr"
                  }, {
                    default: _withCtx(() => _cache[23] || (_cache[23] = [
                      _createTextVNode("Mr")
                    ])),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"]),
                  _createVNode(_component_b_radio, {
                    class: "mr-6",
                    modelValue: $data.editedTitle,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => (($data.editedTitle) = $event)),
                    name: "editedTitle",
                    "native-value": "Ms"
                  }, {
                    default: _withCtx(() => _cache[24] || (_cache[24] = [
                      _createTextVNode("Ms")
                    ])),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"]),
                  _createVNode(_component_b_radio, {
                    class: "mr-6",
                    modelValue: $data.editedTitle,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.editedTitle) = $event)),
                    name: "editedTitle",
                    "native-value": "Mrs"
                  }, {
                    default: _withCtx(() => _cache[25] || (_cache[25] = [
                      _createTextVNode("Mrs")
                    ])),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["modelValue"])
                ]),
                _createVNode(_component_b_field, {
                  grouped: "",
                  style: {"margin-bottom":"32px"}
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/first-name"),
                      "label-position": "on-border",
                      expanded: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          modelValue: $data.editedFirstName,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => (($data.editedFirstName) = $event)),
                          placeholder: _ctx.l("generic/first-name"),
                          required: ""
                        }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"]),
                    _createVNode(_component_b_field, {
                      label: _ctx.l("generic/last-name"),
                      "label-position": "on-border",
                      expanded: ""
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_b_input, {
                          modelValue: $data.editedLastName,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => (($data.editedLastName) = $event)),
                          placeholder: _ctx.l("generic/last-name")
                        }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
                      ]),
                      _: 1 /* STABLE */
                    }, 8 /* PROPS */, ["label"])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_field, {
                  label: _ctx.l("generic/phone-number"),
                  "label-position": "on-border",
                  style: {"margin-bottom":"32px"},
                  type: { "is-danger": $data.duplicatePhoneError },
                  message: $data.duplicatePhoneError ? _ctx.l("setup-users/user-phone-exists") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.editedPhoneNumber,
                      "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => (($data.editedPhoneNumber) = $event)),
                      placeholder: "+358 123...",
                      expanded: ""
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createVNode(_component_b_field, {
                  label: _ctx.l("generic/email"),
                  "label-position": "on-border",
                  style: {"margin-bottom":"32px"},
                  type: { "is-danger": $data.duplicateEmailError },
                  message: $data.duplicateEmailError ? _ctx.l("setup-users/user-email-exists") : ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_b_input, {
                      modelValue: $data.editedEmail,
                      "onUpdate:modelValue": _cache[7] || (_cache[7] = $event => (($data.editedEmail) = $event)),
                      placeholder: "user@mail.com",
                      expanded: "",
                      required: "",
                      type: "email"
                    }, null, 8 /* PROPS */, ["modelValue"])
                  ]),
                  _: 1 /* STABLE */
                }, 8 /* PROPS */, ["label", "type", "message"]),
                _createElementVNode("div", _hoisted_15, [
                  _createVNode(_component_b_button, {
                    type: "is-light",
                    rounded: "",
                    onClick: _cache[8] || (_cache[8] = $event => ($options.resetMyInformation()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  }),
                  _createVNode(_component_b_button, {
                    class: "ml-5",
                    type: "is-primary",
                    rounded: "",
                    "native-type": "submit"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                  })
                ])
              ], 32 /* NEED_HYDRATION */)
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_16, [
            _createElementVNode("h3", _hoisted_17, _toDisplayString(_ctx.l('account/refer-rudolf')), 1 /* TEXT */),
            _createElementVNode("form", {
              onSubmit: _cache[10] || (_cache[10] = _withModifiers($event => ($options.sendReferRudolf()), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_18, [
                _createVNode(_component_b_radio, {
                  class: "mr-6",
                  name: "referRudolfTitle",
                  "native-value": "Mr",
                  disabled: true
                }, {
                  default: _withCtx(() => _cache[26] || (_cache[26] = [
                    _createTextVNode("Mr")
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_radio, {
                  class: "mr-6",
                  name: "referRudolfTitle",
                  "native-value": "Ms",
                  disabled: true
                }, {
                  default: _withCtx(() => _cache[27] || (_cache[27] = [
                    _createTextVNode("Ms")
                  ])),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_radio, {
                  name: "referRudolfTitle",
                  "native-value": "Mrs",
                  disabled: true
                }, {
                  default: _withCtx(() => _cache[28] || (_cache[28] = [
                    _createTextVNode("Mrs")
                  ])),
                  _: 1 /* STABLE */
                })
              ]),
              _createVNode(_component_b_field, {
                grouped: "",
                style: {"margin-bottom":"2rem"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/first-name"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: _ctx.l("generic/first-name"),
                        disabled: true
                      }, null, 8 /* PROPS */, ["placeholder"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]),
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/last-name"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: _ctx.l("generic/last-name"),
                        disabled: true
                      }, null, 8 /* PROPS */, ["placeholder"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_field, {
                grouped: "",
                style: {"margin-bottom":"2rem"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/company"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: "ABC Ltd.",
                        disabled: true
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]),
                  _createVNode(_component_b_field, {
                    label: _ctx.l("contacts/contact-position"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: "CEO",
                        disabled: true
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_field, {
                grouped: "",
                style: {"margin-bottom":"2rem"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/phone-number"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: "+358 123...",
                        disabled: true
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"]),
                  _createVNode(_component_b_field, {
                    label: _ctx.l("generic/email"),
                    "label-position": "on-border",
                    expanded: ""
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        placeholder: "user@mail.com",
                        disabled: true
                      })
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label"])
                ]),
                _: 1 /* STABLE */
              }),
              _createVNode(_component_b_field, {
                label: _ctx.l("account/refer-addt-comments"),
                "label-position": "on-border",
                style: {"margin-bottom":"2rem"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_input, {
                    type: "textarea",
                    disabled: true
                  })
                ]),
                _: 1 /* STABLE */
              }, 8 /* PROPS */, ["label"]),
              _createElementVNode("div", _hoisted_19, [
                _createVNode(_component_b_button, {
                  class: "ml-5",
                  type: "is-primary",
                  rounded: "",
                  "native-type": "submit",
                  disabled: true
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/send')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ], 32 /* NEED_HYDRATION */)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createCommentVNode("        div.column.is-4\n  div.rudolfbox.account-page-box.roles-assigned-box\n    h3.rtitle.sm.has-text-centered.mb-4 Roles assigned\n    div.roles-scroll-area\n      h5.role-title Superadmin\n      div.user-item\n        div.user-img\n          img(src='~FRONTEND/images/account-user-profile-placeholder.png' width='32')\n        div.user-name\n          span Super admin user\n      h5.role-title Admin/s\n      div.user-item(v-for='idx in 3')\n        div.user-img\n          img(src='~FRONTEND/images/account-user-profile-placeholder.png' width='32')\n        div.user-name\n          span Admin {{ idx }}\n      h5.role-title User/s\n      div.user-item(v-for='idx in 10')\n        div.user-img\n          img(src='~FRONTEND/images/account-user-profile-placeholder.png' width='32')\n        div.user-name\n          span User {{ idx === 4 ? 'with long name that won\\'t fit on one line' : idx }}"),
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("div", _hoisted_24, [
                _cache[29] || (_cache[29] = _createElementVNode("h3", { class: "rtitle sm has-text-centered mb-4" }, "Change password", -1 /* HOISTED */)),
                _createElementVNode("form", {
                  onSubmit: _cache[15] || (_cache[15] = _withModifiers($event => ($options.changeUserPassword()), ["prevent"]))
                }, [
                  _createVNode(_component_b_field, {
                    label: _ctx.l("account/old-password"),
                    "label-position": "on-border",
                    style: {"margin-bottom":"2rem"},
                    type: { "is-danger": $data.changePwdOldPasswordIncorrect }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        modelValue: $data.changePwdOldPassword,
                        "onUpdate:modelValue": _cache[11] || (_cache[11] = $event => (($data.changePwdOldPassword) = $event)),
                        type: "password",
                        "password-reveal": ""
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label", "type"]),
                  _createVNode(_component_b_field, {
                    label: _ctx.l("account/new-password"),
                    "label-position": "on-border",
                    style: {"margin-bottom":"2rem"},
                    type: { "is-danger": $data.changePwdMismatch || $data.changePwdTooWeakPassword }
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        modelValue: $data.changePwdNewPassword,
                        "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => (($data.changePwdNewPassword) = $event)),
                        type: "password",
                        "password-reveal": ""
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label", "type"]),
                  _createVNode(_component_b_field, {
                    label: _ctx.l("account/confirm-password"),
                    "label-position": "on-border",
                    type: { "is-danger": $data.changePwdMismatch || $data.changePwdTooWeakPassword },
                    message: $options.changePasswordErrorMsg
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_b_input, {
                        modelValue: $data.changePwdConfirmNewPassword,
                        "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => (($data.changePwdConfirmNewPassword) = $event)),
                        type: "password",
                        "password-reveal": ""
                      }, null, 8 /* PROPS */, ["modelValue"])
                    ]),
                    _: 1 /* STABLE */
                  }, 8 /* PROPS */, ["label", "type", "message"]),
                  _createElementVNode("div", _hoisted_25, [
                    _createVNode(_component_b_button, {
                      type: "is-light",
                      rounded: "",
                      onClick: _cache[14] || (_cache[14] = $event => ($options.resetMyInformation()))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/cancel')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    _createVNode(_component_b_button, {
                      class: "ml-5",
                      type: "is-primary",
                      rounded: "",
                      "native-type": "submit"
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    })
                  ])
                ], 32 /* NEED_HYDRATION */)
              ])
            ])
          ])
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("h3", _hoisted_27, _toDisplayString(_ctx.l('account/lead-page-settings')), 1 /* TEXT */),
            _createElementVNode("p", _hoisted_28, _toDisplayString(_ctx.l('account/lead-page-settings-note')), 1 /* TEXT */),
            _createElementVNode("div", _hoisted_29, [
              _createVNode(_component_b_field, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_taginput, {
                    ref: "userLeadDetailsFields",
                    modelValue: $data.editedLeadDetailsFields,
                    "onUpdate:modelValue": _cache[16] || (_cache[16] = $event => (($data.editedLeadDetailsFields) = $event)),
                    autocomplete: "",
                    data: $data.filteredLeadDetailsFields,
                    "open-on-focus": true,
                    "allow-new": false,
                    ellipsis: "",
                    rounded: "",
                    field: "text",
                    placeholder: _ctx.l("account/search-fields") + "...",
                    onTyping: $options.getFilteredFields,
                    onRemove: _cache[17] || (_cache[17] = (field) => $options.removeLeadDetailsField(field))
                  }, null, 8 /* PROPS */, ["modelValue", "data", "placeholder", "onTyping"])
                ]),
                _: 1 /* STABLE */
              })
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createVNode(_component_b_button, {
                class: "ml-5",
                type: "is-primary",
                rounded: "",
                onClick: _cache[18] || (_cache[18] = $event => ($options.saveLeadDetailsFields()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.l('generic/save')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
              })
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_b_loading, {
      "is-full-page": false,
      modelValue: $data.loading,
      "onUpdate:modelValue": _cache[19] || (_cache[19] = $event => (($data.loading) = $event))
    }, null, 8 /* PROPS */, ["modelValue"])
  ]))
}