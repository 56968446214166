import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")

  return (_openBlock(), _createBlock(_component_b_field, {
    label: $props.label + " *",
    type: { "is-danger": $props.requiredError },
    message: $options.getErrorMessage()
  }, {
    default: _withCtx(() => [
      _createVNode(_component_b_input, {
        ref: "autocomplete",
        type: "text",
        class: _normalizeClass($props.classname),
        id: $props.id,
        placeholder: $props.placeholder,
        disabled: $props.disabled,
        icon: "map-marker",
        modelValue: $data.autocompleteText,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($data.autocompleteText) = $event)),
        autocomplete: "off",
        autocorrect: "off",
        autocapitalize: "off",
        spellcheck: "off",
        onFocus: _cache[1] || (_cache[1] = $event => ($options.onFocus())),
        onBlur: _cache[2] || (_cache[2] = $event => ($options.onBlur())),
        onChange: $options.onChange,
        onKeypress: $options.onKeyPress,
        onKeyup: $options.onKeyUp
      }, null, 8 /* PROPS */, ["class", "id", "placeholder", "disabled", "modelValue", "onChange", "onKeypress", "onKeyup"])
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["label", "type", "message"]))
}