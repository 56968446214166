//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import dayjs                          from 'dayjs'

import { useLeadsStore }              from './store/leads.js'
import { useLocalizationsStore }      from './store/localizations.js'



/********************************************************************
*********************************************************************

███╗   ███╗██╗███████╗ ██████╗
████╗ ████║██║██╔════╝██╔════╝
██╔████╔██║██║███████╗██║
██║╚██╔╝██║██║╚════██║██║
██║ ╚═╝ ██║██║███████║╚██████╗
╚═╝     ╚═╝╚═╝╚══════╝ ╚═════╝

Miscellaneous helper methods

*********************************************************************
********************************************************************/

export function formatNotificationMessage(notification) {
  // Legacy, non-localized notification message
  if(notification.message) return notification.message

  const localizationsStore = useLocalizationsStore()

  // New, localized message
  if(['assignment', 'next_appt_change', 'due_date_change'].includes(notification.type)) {
    let localization = `notifications/${notification.type}`

    if(['next_appt_change', 'due_date_change'].includes(notification.type) && notification.field1 === 'removed') {
      localization += `_removed`
    }

    return localizationsStore.l_escaped(localization,
      notification.leadName,
      notification.productName,
      notification.sourceUser
    )
  } else if(notification.type === 'status_change') {
    return localizationsStore.l_escaped(`notifications/${notification.type}`,
      notification.field2, // Status text is in field2
      notification.leadName,
      notification.productName,
      notification.sourceUser,
    )
  } else if(notification.type === 'new_lead_comment') {
    return localizationsStore.l_escaped(`notifications/${notification.type}`,
      notification.leadName,
      notification.sourceUser,
    )
  } else if(notification.type === 'due_date_reminder_1week') {
    return localizationsStore.l_escaped(`notifications/${notification.type}`,
      notification.leadName,
      notification.productName,
    )
  }

}

// long = longer description
export function getQuestionTypeStr(type, long = false) {
  const localizationStore = useLocalizationsStore()

  const longField = long ? '-long' : ''

  switch(type) {
    case 'multiple':        return localizationStore.l(`setup-questions/questiontype-multiple${longField}`)
    case 'multiple_many':   return localizationStore.l(`setup-questions/questiontype-multiplemany${longField}`)
    case 'slider':          return localizationStore.l(`setup-questions/questiontype-slider${longField}`)
    case 'value':           return localizationStore.l(`setup-questions/questiontype-value${longField}`)
    case 'short_value':     return localizationStore.l(`setup-questions/questiontype-short-value${longField}`)
    case 'number':          return localizationStore.l(`setup-questions/questiontype-number`)
    case 'personal_sales':  return localizationStore.l(`setup-questions/questiontype-personalsales${longField}`)
    case 'public_questionnare_title':       return localizationStore.l(`setup-questions/questiontype-public-questionnare-title`)
    case 'public_questionnare_subtitle':    return localizationStore.l(`setup-questions/questiontype-public-questionnare-subtitle`)
    default: return '<unknown>'
}
}

export function isStrongPassword(password) {
  if(typeof password !== 'string') return false

  const passwordRegex = /^(?=(.*[a-zA-Z]){7,})(?=(.*[0-9]){2,})(?=(.*[!@#$%^&?*()\-__+.]){1,}).{8,}$/

  return password.match(passwordRegex)
}


export function fileToBinaryString(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        reader.onload = async (e) => {
            try {
                const rawData = e.target.result
    
                const data = new Uint8Array(rawData)
                let arr = new Array()

                for(let i = 0; i != data.length; ++i) {
                    arr[i] = String.fromCharCode(data[i])
                }
        
                const binaryStr = arr.join('')
        
                resolve(binaryStr)
            } catch(error) {
                reject(error)
            }
        }

        reader.readAsArrayBuffer(file)
    })
}


// TODO: streamline this and below function
export function formatCurrency(value) {
    if(typeof value !== 'number') {
        return value
    }

    let ret = value.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')

    return ret.slice(0, -3)
}


// TODO: streamline this and above function
export function toCurrency(value, digits = 2) {
    if(typeof value !== "number") return value

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: digits
    })

    return formatter.format(value)
}


export function isValidHttpUrl(string) {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === "http:" || url.protocol === "https:"
}


export function resourceSortFunction(a, b) {
    const sortOrderA = Number.isInteger(a.sort_order_id) ? a.sort_order_id : 999999
    const sortOrderB = Number.isInteger(b.sort_order_id) ? b.sort_order_id : 999999

    return sortOrderA - sortOrderB
}


export function sortAlphabetical(property) {
  return (a, b) => {
    if(a[property] < b[property]) return -1
    if(a[property] > b[property]) return 1

    return 0
  }
}


export function stringSortCaseInsensitive(a, b) { return a.toLowerCase().localeCompare(b.toLowerCase()) }


export function monthsYtd() {
    let months = []

    for(let m = dayjs().startOf('year'); m.diff(dayjs().startOf('month'), 'months') <= 0; m = m.add(1, 'month')) {
        months.push({
            number: m.month(),
            display: m.format('MMMM'),
        })
    }

    return months
}


export function formatDate(date, component = '') {
    if(component === 'day') {
      return dayjs(date).format('D')
    } else if(component === 'ordinal') {
      const dayWithOrdinal = dayjs(date).format('Do')
      return dayWithOrdinal.replace(/\d+/g, '')
    } else if(component === 'month-and-year') {
      return dayjs(date).format('MMM YYYY')
    } else if(component === 'full-month-and-year') {
      return dayjs(date).format('MMMM YYYY')
    } else if(component === 'full-datetime') {
      return dayjs(date).format('Do MMMM YYYY, HH:mm')
    } else if(component === 'full-date') {
      return dayjs(date).format('Do MMMM YYYY')
    } else if(component === 'time') {
      return dayjs(date).format('HH:mm')
    }
}


export function capitalizeFirstLetter(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}


export function textEllipsis(text, length) {
  return text.length > length ? `${text.substring(0, length)}...` : text
}


export function toMap(list, keyProp) {
  const keyValuePairs = list.map(l => [l[keyProp], l])
  return new Map(keyValuePairs)
}


export function swapElements(array, index1, index2) {
  const temp = array[index1]
  array[index1] = array[index2]
  array[index2] = temp
}


export function calcSales(lead, selectedMonth) {
  let target    = 0
  let monthly   = 0
  let lastYear  = 0
  let vsTargetPercentage = 0

  const leadsStore = useLeadsStore()

  if(!lead.hasSales) return { target, monthly, lastYear, vsTargetPercentage }

  for(const assignment of lead.assignments) {
    if(!assignment.hasSales) continue

    if(leadsStore.filtering.products.length > 0) {
      const filteredProductIds = leadsStore.filtering.products.map(p => p._id)

      if(!filteredProductIds.includes(assignment.productId)) continue
    }

    for(let i = 0; i <= selectedMonth; i++) {
        target    += assignment.sales.target[i]
        monthly   += assignment.sales.monthly[i]
        lastYear  += assignment.sales.lastYear[i]
    }
  }

  if(target > 0) {
    vsTargetPercentage = Math.round((monthly / target) * 100)
  }

  return { target, monthly, lastYear, vsTargetPercentage }
}


export function stringNormalize(str) {
  if(typeof str !== 'string') return str

  return str.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}


export function isObject(obj) { return typeof obj === 'object' && !Array.isArray(obj) && obj !== null }


export function questionType(question) {
  // Long text field
  if(question.type === 'value' && (!question.quantitative_type || question.quantitative_type === "normal")) return 'free_text'

  // Personal sales target
  if(question.type === 'value' && question.quantitative_type === 'quantitative_dotsize') return 'personal_sales'

  return question?.type // Rest, just return type as it is
}



/********************************************************************
*********************************************************************

███████╗ ██████╗ ██████╗ ████████╗██╗███╗   ██╗ ██████╗
██╔════╝██╔═══██╗██╔══██╗╚══██╔══╝██║████╗  ██║██╔════╝
███████╗██║   ██║██████╔╝   ██║   ██║██╔██╗ ██║██║  ███╗
╚════██║██║   ██║██╔══██╗   ██║   ██║██║╚██╗██║██║   ██║
███████║╚██████╔╝██║  ██║   ██║   ██║██║ ╚████║╚██████╔╝
╚══════╝ ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚═╝╚═╝  ╚═══╝ ╚═════╝

Leads sorting helpers

*********************************************************************
********************************************************************/


export function stringSort(order, field, a, b) {
  let aField = a[field] || ''
  let bField = b[field] || ''

  if(Array.isArray(aField)) aField = aField.join(' ')
  if(Array.isArray(bField)) bField = bField.join(' ')

  aField = aField.toLowerCase()
  bField = bField.toLowerCase()

  // Make sure empty fields always get sorted last, regardless of order
  if(aField.length > 0 && bField.length === 0) return -1
  if(aField.length === 0 && bField.length > 0) return 1
  if(aField.length === 0 && bField.length === 0) return 0

  if(order === 'asc') {
      return aField.localeCompare(bField)
  } else {
      return bField.localeCompare(aField)
  }
}


export function productsSort(order, a, b) {
  const aProductsStr = a.assignments.map(as => as.productName).join(' ').toLowerCase()
  const bProductsStr = b.assignments.map(as => as.productName).join(' ').toLowerCase()

  // Make sure empty fields always get sorted last, regardless of order
  if(aProductsStr.length > 0 && bProductsStr.length === 0) return -1
  if(aProductsStr.length === 0 && bProductsStr.length > 0) return 1
  if(aProductsStr.length === 0 && bProductsStr.length === 0) return 0

  if(order === 'asc') {
    return aProductsStr.localeCompare(bProductsStr)
  } else {
    return bProductsStr.localeCompare(aProductsStr)
  }
}


export function statusesSort(order, a, b) {
  let aStatusesStr = ''
  let bStatusesStr = ''

  if(a.hasUserAssignments === true) {
    for(const assignment of a.assignments) {
      if(assignment.statusIdx === null) continue

      aStatusesStr += `${assignment.productName.toLowerCase()} ${String(assignment.statusIdx || '0').padStart(4, '0')} `
    }
  }

  if(b.hasUserAssignments === true) {
    for(const assignment of b.assignments) {
      if(assignment.statusIdx === null) continue

      bStatusesStr += `${assignment.productName.toLowerCase()} ${String(assignment.statusIdx || '0').padStart(4, '0')} `
    }
  }

  // Make sure empty fields always get sorted last, regardless of order
  if(aStatusesStr.length > 0 && bStatusesStr.length === 0) return -1
  if(aStatusesStr.length === 0 && bStatusesStr.length > 0) return 1
  if(aStatusesStr.length === 0 && bStatusesStr.length === 0) return 0

  if(order === 'asc') {
    return aStatusesStr.localeCompare(bStatusesStr)
  } else {
    return bStatusesStr.localeCompare(aStatusesStr)
  }
}


export function assignmentsSort(order, a, b) {
  let aAssignmentsStr = ''
  let bAssignmentsStr = ''

  for(const assignment of a.assignments) {
    aAssignmentsStr += `${assignment.productName.toLowerCase()} ${assignment.assigneeFullName.toLowerCase()} `
  }

  for(const assignment of b.assignments) {
    bAssignmentsStr += `${assignment.productName.toLowerCase()} ${assignment.assigneeFullName.toLowerCase()} `
  }

  // Make sure empty fields always get sorted last, regardless of order
  if(aAssignmentsStr.length > 0 && bAssignmentsStr.length === 0) return -1
  if(aAssignmentsStr.length === 0 && bAssignmentsStr.length > 0) return 1
  if(aAssignmentsStr.length === 0 && bAssignmentsStr.length === 0) return 0

  if(order === 'asc') {
    return aAssignmentsStr.localeCompare(bAssignmentsStr)
  } else {
    return bAssignmentsStr.localeCompare(aAssignmentsStr)
  }
}


export function reportDateSort(order, reportField, a, b) {
  let aDate = reportFirstDate(a, reportField, order)
  let bDate = reportFirstDate(b, reportField, order)

  // Make sure empty fields always get sorted last, regardless of order
  if(aDate !== null && bDate === null) return -1
  if(aDate === null && bDate !== null) return 1
  if(aDate === null && bDate === null) return 0

  return order === 'desc' ? bDate.localeCompare(aDate) : aDate.localeCompare(bDate)
}


function reportFirstDate(lead, reportField, order) {
  let firstDate = null

  for(const assignment of lead.assignments) {
    let reportDate = assignment[reportField]

    if(!reportDate) continue

    if(firstDate === null) {
      firstDate = reportDate
      continue
    }

    if(order === 'desc' && reportDate.localeCompare(firstDate) > 0) {
      firstDate = reportDate
    } else if(order === 'asc' && reportDate.localeCompare(firstDate) < 0) {
      firstDate = reportDate
    }
  }

  return firstDate
}


export function salesSort(order, type, selectedMonth, a, b) {
  if(!a.hasSales && b.hasSales) return 1
  if(a.hasSales && !b.hasSales) return -1

  let aSales = calcSales(a, selectedMonth)
  let bSales = calcSales(b, selectedMonth)

  if(type === 'sales_ytd') {
    // Make sure empty fields always get sorted last, regardless of order
    if(aSales.monthly !== 0 && bSales.monthly === 0) return -1
    if(aSales.monthly === 0 && bSales.monthly !== 0) return 1
    if(aSales.monthly === 0 && bSales.monthly === 0) return 0

    if(order === 'desc') return bSales.monthly - aSales.monthly
    if(order === 'asc') return aSales.monthly - bSales.monthly
  } else if(type === 'sales_ytdvs') {
    // Make sure empty fields always get sorted last, regardless of order
    if(aSales.vsTargetPercentage !== 0 && bSales.vsTargetPercentage === 0) return -1
    if(aSales.vsTargetPercentage === 0 && bSales.vsTargetPercentage !== 0) return 1
    if(aSales.vsTargetPercentage === 0 && bSales.vsTargetPercentage === 0) return 0

    if(order === 'desc') return bSales.vsTargetPercentage - aSales.vsTargetPercentage
    if(order === 'asc') return aSales.vsTargetPercentage - bSales.vsTargetPercentage
  }

}


export function questionAnswersSort(order, templateQuestion, a, b) {
  const type        = templateQuestion.type
  const qType       = templateQuestion.quantitative_type

  let aAnswerStr = ''
  let bAnswerStr = ''

  const aAssignment = a.assignments.find(assignment => assignment.formId === templateQuestion.formid)
  const bAssignment = b.assignments.find(assignment => assignment.formId === templateQuestion.formid)

  if(aAssignment) aAnswerStr = _buildAnswerStr(aAssignment, templateQuestion)
  if(bAssignment) bAnswerStr = _buildAnswerStr(bAssignment, templateQuestion)

  // Make sure empty fields always get sorted last, regardless of order
  if(aAnswerStr.length > 0 && bAnswerStr.length === 0) return -1
  if(aAnswerStr.length === 0 && bAnswerStr.length > 0) return 1
  if(aAnswerStr.length === 0 && bAnswerStr.length === 0) return 0

  if(type === 'slider' || (type === 'value' && qType !== 'normal')) {
    const aValue = parseInt(aAnswerStr)
    const bValue = parseInt(bAnswerStr)
    return order === 'desc' ? bValue - aValue : aValue - bValue
  } else {
    return order === 'desc' ? bAnswerStr.localeCompare(aAnswerStr) : aAnswerStr.localeCompare(bAnswerStr)
  }
}


function _buildAnswerStr(assignment, templateQuestion) {
  const type        = templateQuestion.type
  const templateId  = templateQuestion._id
  const qType       = templateQuestion.quantitative_type

  const answeredQuestion = assignment.answers[templateId]

  if(!answeredQuestion) return ''

  if(type === 'slider' || (type === 'value' && qType === 'normal')) {
    return `${String(answeredQuestion.answer.value)}`
  } else if(type === 'value' && qType !== 'normal') {
    return `${String(answeredQuestion.answer.quantity)}`
  } else {
    // Multiple choice answer
    const choices = answeredQuestion?.answer?.choices || []

    let choicesStr = ''

    for(const choice of choices) {
      choicesStr += choice
    }

    return choicesStr
  }

}



/********************************************************************
*********************************************************************

██████╗  █████╗  ██████╗ ██╗███╗   ██╗ █████╗ ████████╗██╗ ██████╗ ███╗   ██╗
██╔══██╗██╔══██╗██╔════╝ ██║████╗  ██║██╔══██╗╚══██╔══╝██║██╔═══██╗████╗  ██║
██████╔╝███████║██║  ███╗██║██╔██╗ ██║███████║   ██║   ██║██║   ██║██╔██╗ ██║
██╔═══╝ ██╔══██║██║   ██║██║██║╚██╗██║██╔══██║   ██║   ██║██║   ██║██║╚██╗██║
██║     ██║  ██║╚██████╔╝██║██║ ╚████║██║  ██║   ██║   ██║╚██████╔╝██║ ╚████║
╚═╝     ╚═╝  ╚═╝ ╚═════╝ ╚═╝╚═╝  ╚═══╝╚═╝  ╚═╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝

Pagination calculator

*********************************************************************
********************************************************************/


export function calculatePagination(items, perPage, currentPage) {
  // Default settings, for 'all'
  let lastPage  = 1
  let from      = 1
  let to        = items.length

  // Pagination calculations
  if(perPage !== 'all') {
    // Last page calculation
    lastPage = Math.ceil(items.length / perPage)
    if(lastPage < 1) lastPage = 1

    // Showing leads starting from...
    from = ((currentPage - 1) * perPage) + 1

    // Account for 0 leads
    if(items.length === 0) from = 0

    // Showing leads to...
    to = currentPage * perPage
    if(to > items.length) to = items.length
  }


  // Leftmost page number that is visible
  // We always show 5 page numbers at a time, the selected page being in the middle
  // (if possible)
  const fromPageCalc = () => {
    // If we're on page <= 3, then leftmost page is always 1
    if(currentPage <= 3) return 1

    // Normal scenario
    let fromPage = currentPage - 2

    // Towards the end of pages, we need to show more pages at the beginning
    // e.g. at page 12, the first page needs to be 8, and not 10, as would
    // result from only the calculation above
    if(fromPage > lastPage - 4) fromPage = lastPage - 4

    if(fromPage < 1) fromPage = 1

    return fromPage
  }

  // Same as above for the rightmost page number that is visible, except we cap it
  // to 'lastPage'
  const toPageCalc = () => {
    // Below or at 3 pages, we always show up to 5 (or lastPage)
    if(currentPage <= 3) return lastPage > 4 ? 5 : lastPage

    // Normal scenario
    const toPage = currentPage + 2

    return toPage > lastPage ? lastPage : toPage
  }


  const fromPage  = fromPageCalc()
  const toPage    = toPageCalc()

  // Convenience array for v-for looping
  const visiblePages = []
  for(let i = fromPage; i < toPage + 1; i++) {
    visiblePages.push(i)
  }


  return {
    lastPage, from, to,
    fromPage, toPage,
    visiblePages,
  }
}
