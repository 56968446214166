//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//

export const API_HOST  = window.BACKEND_API_HOST
export const API_PATH  = window.BACKEND_API_PATH

export const API_URL   = API_HOST + API_PATH


// TODO: Phase out "manager" when old UI / old API is phased out
// NOTE: Can't phase out "manager" until old DB's are migrated clean of this role
export const USER_ROLES = [ 'basic', 'user', 'manager', 'admin', 'superadmin' ]


export const DEFAULT_TOAST_DURATION = 5000


// Color choices for the Rudolf color picker
export const COLOR_PICKER_COLORS = [
    '#996413', '#FFAA00', '#BDBD54', '#FFDF3C', '#FFBF81', '#EBFF35',
    '#9A1B1B', '#E50B07', '#FF5C1F', 
    '#002EE6', '#48DAFF', '#2FA4D0', '#0090FF', '#009393', '#56FFF4',
    '#789700', '#79EA35', '#40AE00', '#AAFF00', '#17D6A2', '#00FF89',
    '#7C23CB', '#B06CFF', '#FAB4FF', '#FE6CF9', '#D907B6', '#D33FFF',
    '#959BA3', '#D9D9D9', '#000000'
]


export const GOOGLE_MAPS_API_KEYS = [
    "AIzaSyBHh_0YEwtxh_SXEp7-9_8HX_r_mFiyPOo", /* This is the API key generated for rudolfdev1@gmail.com */
    //"AIzaSyBM8m7_7cE7e6QjEyQt3C6CjQfhZcPUugc", /* This is the API key generated for rudolfdev2@gmail.com */
    //"AIzaSyClzImg1cXcZ8aqcU7HltUqESSg5Pk8EfQ", /* This is the API key generated for rudolfdev3@gmail.com */
    //"AIzaSyCSHYs9nUZmPrq_KwkPZSzz9dtmefkIE2Q", /* This is the API key generated for rudolfdev4@gmail.com */
    //"AIzaSyDS32izs2e32asfmfk1HEq88tehK-7nDzo", /* This is the API key generated for rudolfdev5@gmail.com */
]


export const GOOGLE_MAP_DAYLIGHT_STYLE = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [{ "color": "#666666" }]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [{ "visibility": "off" }, { "color": "#3e606f" }, { "weight": 2 }, { "gamma": 0.84 }]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry",
        "stylers": [{ "weight": 0.6 }, { "color": "#cccccc" }, { "visibility": "on" }]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [{ "color": "#f2f2f2" }, { "visibility": "on" }]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [{ "color": "#406d80" }, { "visibility": "off" }]
    },
    {
        "featureType": "poi.park",
        "elementType": "geometry",
        "stylers": [{ "color": "#2c5a71" }, { "visibility": "off" }]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [{ "color": "#f2f2f2" }, { "lightness": "-6" }]
    },
    {
        "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [{ "color": "#406d80" }, { "visibility": "off" }]
    },
    {
        "featureType": "water", "elementType": "geometry", "stylers": [{ "color": "#a6d4f3" }, { "lightness": "10" }]
    }
]
