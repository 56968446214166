import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, withModifiers as _withModifiers, createBlock as _createBlock, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from 'FRONTEND/images/filter-icon.svg'


const _hoisted_1 = {
  key: 0,
  class: "num-filters-active"
}
const _hoisted_2 = { class: "bottom-buttons-area" }
const _hoisted_3 = { class: "mediumtext ml-3 mb-3" }
const _hoisted_4 = { class: "filter-details" }
const _hoisted_5 = { class: "mediumtext mb-5" }
const _hoisted_6 = { class: "filter-options-area mb-5" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "status-container" }
const _hoisted_9 = { class: "status-product" }
const _hoisted_10 = { class: "greytext status-product-name" }
const _hoisted_11 = { class: "status-status" }
const _hoisted_12 = { class: "filter-options-area mb-5" }
const _hoisted_13 = ["onClick"]
const _hoisted_14 = { class: "category-name" }
const _hoisted_15 = { class: "category-arrow" }
const _hoisted_16 = { class: "more-filters" }
const _hoisted_17 = { class: "filter-options-area mb-5" }
const _hoisted_18 = ["onClick"]
const _hoisted_19 = { class: "filter-answers" }
const _hoisted_20 = { class: "rtitle ssm semibold greytext" }
const _hoisted_21 = { class: "rtitle ssm semibold normalcase" }
const _hoisted_22 = { key: 0 }
const _hoisted_23 = { class: "question" }
const _hoisted_24 = { class: "question-container" }
const _hoisted_25 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_26 = {
  key: 1,
  class: "has-text-danger mt-2"
}
const _hoisted_27 = { class: "question-filter-options-area" }
const _hoisted_28 = { class: "question-type-text" }
const _hoisted_29 = {
  key: 1,
  class: "slider-question",
  style: {"width":"100%"}
}
const _hoisted_30 = { class: "question-container" }
const _hoisted_31 = { class: "columns m-0" }
const _hoisted_32 = { class: "column is-6 p-0" }
const _hoisted_33 = { style: {"font-size":"0.857rem"} }
const _hoisted_34 = { class: "column is-6 p-0 has-text-right" }
const _hoisted_35 = { style: {"font-size":"0.857rem"} }
const _hoisted_36 = {
  key: 0,
  class: "has-text-danger mt-2"
}
const _hoisted_37 = { class: "question-filter-options-area" }
const _hoisted_38 = { class: "question-type-text" }
const _hoisted_39 = {
  key: 2,
  style: {"width":"100%"}
}
const _hoisted_40 = { class: "question-filter-options-area" }
const _hoisted_41 = { class: "question-type-text" }
const _hoisted_42 = {
  key: 3,
  style: {"width":"100%"}
}
const _hoisted_43 = { class: "question-filter-options-area" }
const _hoisted_44 = {
  key: 0,
  class: "question-type-text"
}
const _hoisted_45 = {
  key: 1,
  class: "question-type-text"
}
const _hoisted_46 = { class: "filter-time" }
const _hoisted_47 = { class: "rtitle sm mb-4" }
const _hoisted_48 = { class: "rtitle sm mb-4" }
const _hoisted_49 = { class: "rtitle sm mb-4" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_b_button = _resolveComponent("b-button")
  const _component_b_input = _resolveComponent("b-input")
  const _component_b_field = _resolveComponent("b-field")
  const _component_b_taginput = _resolveComponent("b-taginput")
  const _component_b_switch = _resolveComponent("b-switch")
  const _component_b_tag = _resolveComponent("b-tag")
  const _component_b_taglist = _resolveComponent("b-taglist")
  const _component_b_icon = _resolveComponent("b-icon")
  const _component_Collapse = _resolveComponent("Collapse")
  const _component_b_tab_item = _resolveComponent("b-tab-item")
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")
  const _component_b_dropdown = _resolveComponent("b-dropdown")
  const _component_b_checkbox = _resolveComponent("b-checkbox")
  const _component_b_radio = _resolveComponent("b-radio")
  const _component_b_slider = _resolveComponent("b-slider")
  const _component_b_datepicker = _resolveComponent("b-datepicker")
  const _component_b_tabs = _resolveComponent("b-tabs")

  return (_openBlock(), _createBlock(_component_b_dropdown, {
    id: "adv-filter-dropdown",
    ref: "advancedFilterDropdown",
    position: "is-bottom-left",
    "aria-role": "menu",
    "trap-focus": "",
    "can-close": !$data.subDropdownActive,
    "mobile-modal": false
  }, {
    trigger: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["advanced-filter-button", { "mobile": _ctx.isMobile }])
      }, [
        (_ctx.numFiltersActive > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_ctx.numFiltersActive), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _cache[16] || (_cache[16] = _createElementVNode("img", {
          src: _imports_0,
          width: "20",
          height: "20"
        }, null, -1 /* HOISTED */))
      ], 2 /* CLASS */)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_dropdown_item, {
        "aria-role": "menu-item",
        custom: "",
        paddingless: ""
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", {
            class: _normalizeClass(["advanced-filter-container", { "mobile": _ctx.isMobile }])
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createVNode(_component_b_button, {
                  type: "is-light",
                  rounded: "",
                  onClick: _cache[0] || (_cache[0] = $event => (_ctx.$refs.advancedFilterDropdown.toggle()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('generic/close')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _createElementVNode("div", null, [
                _createVNode(_component_b_button, {
                  type: "is-primary",
                  rounded: "",
                  onClick: _cache[1] || (_cache[1] = $event => (_ctx.resetFilters()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.l('filtering/reset-all')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ])
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(_toDisplayString(_ctx.l('filtering/filter-by')) + " ", 1 /* TEXT */),
              _createElementVNode("span", null, "(" + _toDisplayString(_ctx.leadsFiltered.length) + " " + _toDisplayString(_ctx.l('manage-leads-table/num-leads')) + "): ", 1 /* TEXT */)
            ]),
            _createVNode(_component_b_tabs, { position: "is-centered" }, {
              default: _withCtx(() => [
                _createVNode(_component_b_tab_item, null, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.l('filtering/details-tab')), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.l('filtering/filtering-criterias')) + ":", 1 /* TEXT */),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
                        return (_openBlock(), _createElementBlock(_Fragment, null, [
                          (filter.type === "simpleText" && $options.isCommonFilter(filter.field))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createCommentVNode(""),
                                _createCommentVNode(" Simple text filters (most used ones only = isCommonFilter)"),
                                _createCommentVNode(""),
                                _createVNode(_component_b_field, {
                                  label: $options.capitalizeFirstLetter(filter.desc),
                                  "label-position": "on-border"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_input, {
                                      modelValue: _ctx.filtering[filter.data],
                                      onInput: (event) => { _ctx.debounceTextInput()(filter.data, event.target.value) },
                                      "icon-right": _ctx.filterActive(filter.data) ? "close-circle" : "",
                                      "icon-right-clickable": true,
                                      onIconRightClick: _withModifiers($event => (_ctx.resetFilters(filter.data)), ["stop"]),
                                      placeholder: _ctx.l("filtering/filter-by") + "...",
                                      "custom-class": "mb-5",
                                      loading: _ctx.debounceLoading[filter.data]
                                    }, null, 8 /* PROPS */, ["modelValue", "onInput", "icon-right", "onIconRightClick", "placeholder", "loading"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                          (filter.type === "multiTags" && $options.isCommonFilter(filter.field))
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                _createCommentVNode(""),
                                _createCommentVNode(" Multi tag filters (most used ones only = isCommonFilter)"),
                                _createCommentVNode(""),
                                _createVNode(_component_b_field, {
                                  label: $options.capitalizeFirstLetter(filter.desc),
                                  "label-position": "on-border"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_taginput, {
                                      ref_for: true,
                                      ref: (child) => { $data.taginputRefs[filter.data] = child },
                                      modelValue: _ctx.filtering[filter.data],
                                      autocomplete: "",
                                      data: _ctx.filterableSearched[filter.data],
                                      "open-on-focus": filter.openOnFocus,
                                      "allow-new": true,
                                      field: filter.dataProp,
                                      "remove-on-keys": [],
                                      placeholder: _ctx.l("filtering/filter-by") + " " + filter.desc.toLowerCase() + "...",
                                      onFocus: $event => (_ctx.refreshFilterables(null, filter.data)),
                                      onTyping: (text) => _ctx.getFilterableItems(filter.data, text, filter.dataProp),
                                      onAdd: (item) => _ctx.addFilteredItem(filter.data, item),
                                      onRemove: (item) => _ctx.removeFilteredItem(filter.data, item)
                                    }, null, 8 /* PROPS */, ["modelValue", "data", "open-on-focus", "field", "placeholder", "onFocus", "onTyping", "onAdd", "onRemove"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                _createElementVNode("div", _hoisted_6, [
                                  _createElementVNode("div", null, [
                                    (filter.filterOptionEnabled)
                                      ? (_openBlock(), _createBlock(_component_b_switch, {
                                          key: 0,
                                          modelValue: _ctx.filtering.filterOption[filter.data],
                                          "onUpdate:modelValue": $event => ((_ctx.filtering.filterOption[filter.data]) = $event),
                                          outlined: true,
                                          size: "is-small",
                                          type: "is-dark",
                                          "passive-type": "is-dark",
                                          "true-value": "all-of",
                                          "false-value": "any-of"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getFilterOptionText(filter.data)), 1 /* TEXT */)
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
                                      : _createCommentVNode("v-if", true)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("span", {
                                      class: "reset noselect",
                                      onClick: $event => (_ctx.resetFilters(filter.data))
                                    }, _toDisplayString(_ctx.l('generic/reset')), 9 /* TEXT, PROPS */, _hoisted_7)
                                  ])
                                ])
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true),
                          (filter.type === "statusFilter")
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                _createCommentVNode(""),
                                _createCommentVNode(" Status filter is special case"),
                                _createCommentVNode(""),
                                _createVNode(_component_b_field, {
                                  label: $options.capitalizeFirstLetter(filter.desc),
                                  "label-position": "on-border"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_b_taginput, {
                                      ref_for: true,
                                      ref: (child) => { $data.taginputRefs[filter.data] = child },
                                      modelValue: _ctx.filtering[filter.data],
                                      autocomplete: "",
                                      data: _ctx.filterableSearched[filter.data],
                                      "open-on-focus": filter.openOnFocus,
                                      "allow-new": false,
                                      field: filter.dataProp,
                                      "remove-on-keys": [],
                                      placeholder: _ctx.l("filtering/filter-by") + " " + filter.desc.toLowerCase() + "...",
                                      onFocus: $event => (_ctx.refreshFilterables(null, filter.data)),
                                      onTyping: (text) => _ctx.getFilterableItems(filter.data, text, filter.dataProp),
                                      onAdd: (item) => _ctx.addFilteredItem(filter.data, item),
                                      onRemove: (item) => _ctx.removeFilteredItem(filter.data, item)
                                    }, {
                                      default: _withCtx((props) => [
                                        _createElementVNode("div", _hoisted_8, [
                                          _createElementVNode("div", {
                                            class: "status-color",
                                            style: _normalizeStyle({ "backgroundColor": props.option.color })
                                          }, null, 4 /* STYLE */),
                                          _createElementVNode("div", _hoisted_9, [
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(props.option.productName), 1 /* TEXT */),
                                            _cache[17] || (_cache[17] = _createElementVNode("br", null, null, -1 /* HOISTED */)),
                                            _createElementVNode("span", _hoisted_11, _toDisplayString(props.option.text), 1 /* TEXT */)
                                          ])
                                        ])
                                      ]),
                                      selected: _withCtx((props) => [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.tags, (tag, index) => {
                                          return (_openBlock(), _createBlock(_component_b_taglist, {
                                            key: index,
                                            attached: ""
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_b_tag, {
                                                style: _normalizeStyle({ "backgroundColor": tag.color })
                                              }, null, 8 /* PROPS */, ["style"]),
                                              _createVNode(_component_b_tag, {
                                                rounded: "",
                                                closable: "",
                                                onClose: _withModifiers($event => ($data.taginputRefs["statuses"].removeTag(index, $event)), ["stop"])
                                              }, {
                                                default: _withCtx(() => [
                                                  _createTextVNode(_toDisplayString(_ctx.truncateText(tag.productName)) + " / " + _toDisplayString(_ctx.truncateText(tag.text)), 1 /* TEXT */)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClose"])
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1024 /* DYNAMIC_SLOTS */))
                                        }), 128 /* KEYED_FRAGMENT */))
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "data", "open-on-focus", "field", "placeholder", "onFocus", "onTyping", "onAdd", "onRemove"])
                                  ]),
                                  _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                _createElementVNode("div", _hoisted_12, [
                                  _createElementVNode("div", null, [
                                    (filter.filterOptionEnabled)
                                      ? (_openBlock(), _createBlock(_component_b_switch, {
                                          key: 0,
                                          modelValue: _ctx.filtering.filterOption[filter.data],
                                          "onUpdate:modelValue": $event => ((_ctx.filtering.filterOption[filter.data]) = $event),
                                          outlined: true,
                                          size: "is-small",
                                          type: "is-dark",
                                          "passive-type": "is-dark",
                                          "true-value": "all-of",
                                          "false-value": "any-of"
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(_ctx.getFilterOptionText(filter.data)), 1 /* TEXT */)
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
                                      : _createCommentVNode("v-if", true)
                                  ]),
                                  _createElementVNode("div", null, [
                                    _createElementVNode("span", {
                                      class: "reset noselect",
                                      onClick: $event => (_ctx.resetFilters(filter.data))
                                    }, _toDisplayString(_ctx.l('generic/reset')), 9 /* TEXT, PROPS */, _hoisted_13)
                                  ])
                                ])
                              ], 64 /* STABLE_FRAGMENT */))
                            : _createCommentVNode("v-if", true)
                        ], 64 /* STABLE_FRAGMENT */))
                      }), 256 /* UNKEYED_FRAGMENT */)),
                      _createElementVNode("div", {
                        class: "filters-collapse is-clickable",
                        onClick: _cache[2] || (_cache[2] = $event => ($data.detailsMoreCollapsed = !$data.detailsMoreCollapsed))
                      }, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_ctx.l('generic/more')), 1 /* TEXT */),
                        _createElementVNode("div", _hoisted_15, [
                          _createVNode(_component_b_icon, {
                            icon: $data.detailsMoreCollapsed ? 'chevron-down' : 'chevron-up'
                          }, null, 8 /* PROPS */, ["icon"])
                        ])
                      ]),
                      _createCommentVNode(""),
                      _createCommentVNode(" Rest of filters under collapsable (less frequently used ones)"),
                      _createCommentVNode(""),
                      _createVNode(_component_Collapse, {
                        class: "r-collapse-normal",
                        when: $data.detailsMoreCollapsed
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("section", _hoisted_16, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filters, (filter) => {
                              return (_openBlock(), _createElementBlock(_Fragment, null, [
                                (filter.type === "simpleText" && !$options.isCommonFilter(filter.field))
                                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                      _createCommentVNode(""),
                                      _createCommentVNode(" Simple text filters (less commonly used ones = !isCommonFilter)"),
                                      _createCommentVNode(""),
                                      _createVNode(_component_b_field, {
                                        label: $options.capitalizeFirstLetter(filter.desc),
                                        "label-position": "on-border"
                                      }, {
                                        default: _withCtx(() => [
                                          _createVNode(_component_b_input, {
                                            modelValue: _ctx.filtering[filter.data],
                                            onInput: (event) => { _ctx.debounceTextInput()(filter.data, event.target.value) },
                                            "icon-right": _ctx.filterActive(filter.data) ? "close-circle" : "",
                                            "icon-right-clickable": true,
                                            onIconRightClick: _withModifiers($event => (_ctx.resetFilters(filter.data)), ["stop"]),
                                            placeholder: _ctx.l("filtering/filter-by") + "...",
                                            "custom-class": "simpletext-input mb-5",
                                            loading: _ctx.debounceLoading[filter.data]
                                          }, null, 8 /* PROPS */, ["modelValue", "onInput", "icon-right", "onIconRightClick", "placeholder", "loading"])
                                        ]),
                                        _: 2 /* DYNAMIC */
                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                                    ], 64 /* STABLE_FRAGMENT */))
                                  : _createCommentVNode("v-if", true),
                                (filter.type === "minMax" && !$options.isCommonFilter(filter.field))
                                  ? (_openBlock(), _createBlock(_component_b_field, {
                                      key: 1,
                                      class: "minmax-label",
                                      label: $options.capitalizeFirstLetter(filter.desc),
                                      grouped: "",
                                      style: {"margin-top":"-15px","margin-bottom":"32px"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_b_field, { expanded: "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_b_input, {
                                              size: "is-small",
                                              placeholder: _ctx.l("generic/min"),
                                              type: "number",
                                              modelValue: _ctx.filtering[filter.data][0],
                                              onInput: (event) => { _ctx.debounceMinMaxInput()(filter.data, "min", event.target.value) },
                                              "icon-right": !isNaN(_ctx.filtering[filter.data][0]) ? "close-circle" : "",
                                              "icon-right-clickable": true,
                                              onIconRightClick: _withModifiers($event => (_ctx.clearFilterMinMax(filter.data, "min")), ["stop"]),
                                              "custom-class": "simpletext-input",
                                              loading: _ctx.debounceLoading[filter.data]
                                            }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onInput", "icon-right", "onIconRightClick", "loading"])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1024 /* DYNAMIC_SLOTS */),
                                        _createVNode(_component_b_field, { expanded: "" }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_b_input, {
                                              size: "is-small",
                                              placeholder: _ctx.l("generic/max"),
                                              type: "number",
                                              modelValue: _ctx.filtering[filter.data][1],
                                              onInput: (event) => { _ctx.debounceMinMaxInput()(filter.data, "max", event.target.value) },
                                              "icon-right": !isNaN(_ctx.filtering[filter.data][1]) ? "close-circle" : "",
                                              "icon-right-clickable": true,
                                              onIconRightClick: _withModifiers($event => (_ctx.clearFilterMinMax(filter.data, "max")), ["stop"]),
                                              "custom-class": "simpletext-input",
                                              loading: _ctx.debounceLoading[filter.data]
                                            }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onInput", "icon-right", "onIconRightClick", "loading"])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1024 /* DYNAMIC_SLOTS */)
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                                  : (filter.type === "multiTags" && !$options.isCommonFilter(filter.field))
                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                                        _createCommentVNode(""),
                                        _createCommentVNode(" Multi tag filters (less commonly used ones = !isCommonFilter)"),
                                        _createCommentVNode(""),
                                        _createVNode(_component_b_field, {
                                          label: $options.capitalizeFirstLetter(filter.desc),
                                          "label-position": "on-border"
                                        }, {
                                          default: _withCtx(() => [
                                            _createVNode(_component_b_taginput, {
                                              ref_for: true,
                                              ref: (child) => { $data.taginputRefs[filter.data] = child },
                                              modelValue: _ctx.leadsStore[`filter${filter.data}`],
                                              autocomplete: "",
                                              data: _ctx.filterableSearched[filter.data],
                                              "open-on-focus": filter.openOnFocus,
                                              "allow-new": filter.allowNew,
                                              field: filter.dataProp,
                                              "remove-on-keys": [],
                                              placeholder: _ctx.l("filtering/filter-by") + " " + filter.desc.toLowerCase() + "...",
                                              onFocus: $event => (_ctx.refreshFilterables(null, filter.data)),
                                              onTyping: (text) => _ctx.getFilterableItems(filter.data, text, filter.dataProp),
                                              onAdd: (item) => _ctx.addFilteredItem(filter.data, item),
                                              onRemove: (item) => _ctx.removeFilteredItem(filter.data, item)
                                            }, null, 8 /* PROPS */, ["modelValue", "data", "open-on-focus", "allow-new", "field", "placeholder", "onFocus", "onTyping", "onAdd", "onRemove"])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                        _createElementVNode("div", _hoisted_17, [
                                          _createElementVNode("div", null, [
                                            (filter.filterOptionEnabled)
                                              ? (_openBlock(), _createBlock(_component_b_switch, {
                                                  key: 0,
                                                  modelValue: _ctx.filtering.filterOption[filter.data],
                                                  "onUpdate:modelValue": $event => ((_ctx.filtering.filterOption[filter.data]) = $event),
                                                  outlined: true,
                                                  size: "is-small",
                                                  type: "is-dark",
                                                  "passive-type": "is-dark",
                                                  "true-value": "all-of",
                                                  "false-value": "any-of"
                                                }, {
                                                  default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.getFilterOptionText(filter.data)), 1 /* TEXT */)
                                                  ]),
                                                  _: 2 /* DYNAMIC */
                                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue"]))
                                              : _createCommentVNode("v-if", true)
                                          ]),
                                          _createElementVNode("div", null, [
                                            _createElementVNode("span", {
                                              class: "reset noselect",
                                              onClick: $event => (_ctx.resetFilters(filter.data))
                                            }, _toDisplayString(_ctx.l('generic/reset')), 9 /* TEXT, PROPS */, _hoisted_18)
                                          ])
                                        ])
                                      ], 64 /* STABLE_FRAGMENT */))
                                    : _createCommentVNode("v-if", true)
                              ], 64 /* STABLE_FRAGMENT */))
                            }), 256 /* UNKEYED_FRAGMENT */))
                          ])
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["when"])
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_tab_item, null, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.l('filtering/answers-tab')), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_19, [
                      _createVNode(_component_b_dropdown, {
                        ref: "questionsFilterDropdown",
                        position: "is-bottom-right",
                        "aria-role": "menu",
                        scrollable: true,
                        "max-height": "300px",
                        onChange: $options.addAdvQuestionFilter,
                        onActiveChange: $options.toggleSubdropDown,
                        "mobile-modal": false
                      }, {
                        trigger: _withCtx(({ active }) => [
                          _createVNode(_component_b_button, {
                            type: "is-light",
                            "icon-right": active ? 'menu-up' : 'menu-down'
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.l('filtering/add-q-to-filter-by')), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["icon-right"])
                        ]),
                        default: _withCtx(() => [
                          _createVNode(_component_b_dropdown_item, {
                            custom: "",
                            "aria-role": "list-item"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_field, {
                                label: _ctx.l("filtering/search-by-product"),
                                "label-position": "on-border"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_b_input, {
                                    modelValue: $data.questionsProductFilter,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => (($data.questionsProductFilter) = $event)),
                                    expanded: ""
                                  }, null, 8 /* PROPS */, ["modelValue"])
                                ]),
                                _: 1 /* STABLE */
                              }, 8 /* PROPS */, ["label"])
                            ]),
                            _: 1 /* STABLE */
                          }),
                          _cache[18] || (_cache[18] = _createElementVNode("hr", { class: "dropdown-divider" }, null, -1 /* HOISTED */)),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($options.productQuestions, (question) => {
                            return (_openBlock(), _createBlock(_component_b_dropdown_item, {
                              key: question.templateId,
                              value: question.templateId,
                              "aria-role": "list-item"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_20, _toDisplayString(question.productName), 1 /* TEXT */),
                                _createElementVNode("div", _hoisted_21, _toDisplayString(question.description), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["value"]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ]),
                        _: 1 /* STABLE */
                      }, 8 /* PROPS */, ["onChange", "onActiveChange"]),
                      _cache[22] || (_cache[22] = _createElementVNode("div", {
                        class: "separator",
                        style: {"margin":"15px -10px 25px -10px"}
                      }, null, -1 /* HOISTED */)),
                      ($options.questionFilters.length === 0)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_22, _toDisplayString(_ctx.l('filtering/no-q-filters-active')), 1 /* TEXT */))
                        : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($options.questionFilters, (questionFilter) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: questionFilter.templateQuestion._id
                            }, [
                              _createElementVNode("div", _hoisted_23, [
                                (questionFilter.templateQuestion.type === "multiple" || questionFilter.templateQuestion.type === "multiple_many")
                                  ? (_openBlock(), _createBlock(_component_b_field, {
                                      key: 0,
                                      label: questionFilter.templateQuestion.description,
                                      "label-position": "on-border",
                                      style: {"width":"100%"}
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", null, [
                                          _createElementVNode("div", _hoisted_24, [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(questionFilter.templateQuestion.multiple_selection_strings, (option, idx) => {
                                              return (_openBlock(), _createBlock(_component_b_field, {
                                                class: "is-flex-grow-1",
                                                key: idx
                                              }, {
                                                default: _withCtx(() => [
                                                  ($options.questionFilterOptionType(questionFilter) === "checkboxes")
                                                    ? (_openBlock(), _createBlock(_component_b_checkbox, {
                                                        key: 0,
                                                        "native-value": idx,
                                                        modelValue: questionFilter.filter.selectedOptions,
                                                        "onUpdate:modelValue": $event => ((questionFilter.filter.selectedOptions) = $event),
                                                        onChange: $event => (questionFilter.filter.text = "")
                                                      }, {
                                                        default: _withCtx(() => [
                                                          _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                                        ]),
                                                        _: 2 /* DYNAMIC */
                                                      }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["native-value", "modelValue", "onUpdate:modelValue", "onChange"]))
                                                    : ($options.questionFilterOptionType(questionFilter) === "radiobuttons")
                                                      ? (_openBlock(), _createBlock(_component_b_radio, {
                                                          key: 1,
                                                          name: "questionFilter_" + questionFilter.templateQuestion._id,
                                                          "native-value": idx,
                                                          modelValue: $options.getRadioButtonValue(questionFilter),
                                                          onChange: (event) => { questionFilter.filter.selectedOptions = [ parseInt(event.target.value) ]; questionFilter.filter.text = "" }
                                                        }, {
                                                          default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(option), 1 /* TEXT */)
                                                          ]),
                                                          _: 2 /* DYNAMIC */
                                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["name", "native-value", "modelValue", "onChange"]))
                                                      : _createCommentVNode("v-if", true)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1024 /* DYNAMIC_SLOTS */))
                                            }), 128 /* KEYED_FRAGMENT */)),
                                            (questionFilter.templateQuestion.required)
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_25, "* Required question"))
                                              : _createCommentVNode("v-if", true),
                                            (questionFilter.filter.text !== "")
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_26, "Quick text filter for this question is active; make any selection here to change to advanced filtering."))
                                              : _createCommentVNode("v-if", true)
                                          ]),
                                          _createElementVNode("div", _hoisted_27, [
                                            _createElementVNode("div", null, [
                                              _createVNode(_component_b_switch, {
                                                outlined: true,
                                                size: "is-small",
                                                type: "is-dark",
                                                "passive-type": "is-dark",
                                                "true-value": "all-of",
                                                "false-value": "any-of",
                                                modelValue: questionFilter.filter.selectedType,
                                                "onUpdate:modelValue": $event => ((questionFilter.filter.selectedType) = $event),
                                                onChange: (event) => { $options.toggleQuestionFilterType(questionFilter) }
                                              }, {
                                                default: _withCtx(() => [
                                                  (questionFilter.filter.selectedType === "any-of")
                                                    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                                        _createTextVNode(_toDisplayString(_ctx.l('filtering/anyof-selected')), 1 /* TEXT */)
                                                      ], 64 /* STABLE_FRAGMENT */))
                                                    : (questionFilter.filter.selectedType === "all-of")
                                                      ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                                                          _createTextVNode(_toDisplayString(_ctx.l('filtering/exactly-as-selected')), 1 /* TEXT */)
                                                        ], 64 /* STABLE_FRAGMENT */))
                                                      : _createCommentVNode("v-if", true)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "onChange"])
                                            ]),
                                            _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr(questionFilter.templateQuestion.type)), 1 /* TEXT */)
                                          ])
                                        ])
                                      ]),
                                      _: 2 /* DYNAMIC */
                                    }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]))
                                  : (questionFilter.templateQuestion.type === "slider")
                                    ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                                        _createVNode(_component_b_field, {
                                          label: questionFilter.templateQuestion.description,
                                          "label-position": "on-border",
                                          style: {"width":"100%"}
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("div", null, [
                                              _createElementVNode("div", _hoisted_30, [
                                                _createVNode(_component_b_field, null, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_b_slider, {
                                                      class: "mt-1",
                                                      min: Number(questionFilter.templateQuestion.multiple_selection_strings[0]),
                                                      max: Number(questionFilter.templateQuestion.multiple_selection_strings[1]),
                                                      modelValue: questionFilter.filter.range,
                                                      onChange: (value) => questionFilter.filter.range = value
                                                    }, null, 8 /* PROPS */, ["min", "max", "modelValue", "onChange"])
                                                  ]),
                                                  _: 2 /* DYNAMIC */
                                                }, 1024 /* DYNAMIC_SLOTS */),
                                                _createElementVNode("div", _hoisted_31, [
                                                  _createElementVNode("div", _hoisted_32, [
                                                    _createElementVNode("span", _hoisted_33, "Min: " + _toDisplayString(questionFilter.templateQuestion.multiple_selection_strings[0]), 1 /* TEXT */)
                                                  ]),
                                                  _createElementVNode("div", _hoisted_34, [
                                                    _createElementVNode("span", _hoisted_35, "Max: " + _toDisplayString(questionFilter.templateQuestion.multiple_selection_strings[1]), 1 /* TEXT */)
                                                  ])
                                                ]),
                                                (questionFilter.templateQuestion.required)
                                                  ? (_openBlock(), _createElementBlock("p", _hoisted_36, "* Required question"))
                                                  : _createCommentVNode("v-if", true)
                                              ]),
                                              _createElementVNode("div", _hoisted_37, [
                                                _cache[19] || (_cache[19] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
                                                _createElementVNode("div", _hoisted_38, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr(questionFilter.templateQuestion.type)), 1 /* TEXT */)
                                              ])
                                            ])
                                          ]),
                                          _: 2 /* DYNAMIC */
                                        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"])
                                      ]))
                                    : (questionFilter.templateQuestion.type === "value" && questionFilter.templateQuestion.quantitative_type === "normal")
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_39, [
                                          _createVNode(_component_b_field, {
                                            class: "is-flex-grow-1",
                                            label: questionFilter.templateQuestion.description,
                                            "label-position": "on-border"
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_b_input, {
                                                modelValue: questionFilter.filter.text,
                                                onInput: (event) => _ctx.debounceQuestionTextInput()(questionFilter.filter.templateId, event.target.value),
                                                placeholder: "Answer contains...",
                                                expanded: "",
                                                loading: _ctx.debounceLoading[questionFilter.filter.templateId]
                                              }, null, 8 /* PROPS */, ["modelValue", "onInput", "loading"])
                                            ]),
                                            _: 2 /* DYNAMIC */
                                          }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                          _createElementVNode("div", _hoisted_40, [
                                            _cache[20] || (_cache[20] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
                                            _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr(questionFilter.templateQuestion.type)), 1 /* TEXT */)
                                          ])
                                        ]))
                                      : (questionFilter.templateQuestion.type === "number" || (questionFilter.templateQuestion.type === "value" && questionFilter.templateQuestion.quantitative_type !== "normal"))
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                                            _createVNode(_component_b_field, {
                                              class: "minmax-label",
                                              label: questionFilter.templateQuestion.description,
                                              grouped: ""
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_b_field, { expanded: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_b_input, {
                                                      size: "is-small",
                                                      placeholder: _ctx.l("generic/min"),
                                                      type: "number",
                                                      modelValue: questionFilter.filter.range[0],
                                                      onInput: (event) => { _ctx.debounceQuestionMinMaxInput()(questionFilter.templateQuestion._id, "min", event.target.value) },
                                                      "icon-right": !isNaN(questionFilter.filter.range[0]) ? "close-circle" : "",
                                                      "icon-right-clickable": true,
                                                      onIconRightClick: _withModifiers($event => (_ctx.clearQuestionFilterMinMax(questionFilter.templateQuestion._id, "min", false)), ["stop"]),
                                                      "custom-class": "simpletext-input",
                                                      loading: _ctx.debounceLoading[questionFilter.templateQuestion._id]
                                                    }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onInput", "icon-right", "onIconRightClick", "loading"])
                                                  ]),
                                                  _: 2 /* DYNAMIC */
                                                }, 1024 /* DYNAMIC_SLOTS */),
                                                _createVNode(_component_b_field, { expanded: "" }, {
                                                  default: _withCtx(() => [
                                                    _createVNode(_component_b_input, {
                                                      size: "is-small",
                                                      placeholder: _ctx.l("generic/max"),
                                                      type: "number",
                                                      modelValue: questionFilter.filter.range[1],
                                                      onInput: (event) => { _ctx.debounceQuestionMinMaxInput()(questionFilter.templateQuestion._id, "max", event.target.value) },
                                                      "icon-right": !isNaN(questionFilter.filter.range[1]) ? "close-circle" : "",
                                                      "icon-right-clickable": true,
                                                      onIconRightClick: _withModifiers($event => (_ctx.clearQuestionFilterMinMax(questionFilter.templateQuestion._id, "max", false)), ["stop"]),
                                                      "custom-class": "simpletext-input",
                                                      loading: _ctx.debounceLoading[questionFilter.templateQuestion._id]
                                                    }, null, 8 /* PROPS */, ["placeholder", "modelValue", "onInput", "icon-right", "onIconRightClick", "loading"])
                                                  ]),
                                                  _: 2 /* DYNAMIC */
                                                }, 1024 /* DYNAMIC_SLOTS */)
                                              ]),
                                              _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["label"]),
                                            _createElementVNode("div", _hoisted_43, [
                                              _cache[21] || (_cache[21] = _createElementVNode("div", null, null, -1 /* HOISTED */)),
                                              (questionFilter.templateQuestion.type === "value" && questionFilter.templateQuestion.quantitative_type !== "normal")
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_44, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr('personal_sales')), 1 /* TEXT */))
                                                : (questionFilter.templateQuestion.type === "number")
                                                  ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(_ctx.l('generic/type')) + ": " + _toDisplayString($options.getQuestionTypeStr('number')), 1 /* TEXT */))
                                                  : _createCommentVNode("v-if", true)
                                            ])
                                          ]))
                                        : _createCommentVNode("v-if", true),
                                _createVNode(_component_b_button, {
                                  class: "icon-button",
                                  type: "is-light",
                                  size: "is-small",
                                  "icon-right": "delete-outline",
                                  onClick: _withModifiers($event => (_ctx.resetQuestionFilter(questionFilter.templateQuestion._id)), ["stop"])
                                }, null, 8 /* PROPS */, ["onClick"])
                              ])
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                    ])
                  ]),
                  _: 1 /* STABLE */
                }),
                _createVNode(_component_b_tab_item, null, {
                  header: _withCtx(() => [
                    _createElementVNode("span", null, _toDisplayString(_ctx.l('filtering/time-tab')), 1 /* TEXT */)
                  ]),
                  default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_46, [
                      _createElementVNode("div", _hoisted_47, _toDisplayString(_ctx.l('filtering/reported-time')), 1 /* TEXT */),
                      _createVNode(_component_b_field, {
                        class: "mb-5",
                        grouped: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_field, {
                            class: "left-datepicker",
                            label: _ctx.l("generic/start"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.reportedStart,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => ((_ctx.filtering.reportedStart) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering.reportedStart !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[5] || (_cache[5] = _withModifiers($event => (_ctx.resetFilters("reportedStart")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"]),
                          _createVNode(_component_b_field, {
                            label: _ctx.l("generic/start"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.reportedEnd,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => ((_ctx.filtering.reportedEnd) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering["reportedEnd"] !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[7] || (_cache[7] = _withModifiers($event => (_ctx.resetFilters("reportedEnd")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_48, _toDisplayString(_ctx.settingsStore.label('nextappointment')), 1 /* TEXT */),
                      _createVNode(_component_b_field, {
                        class: "mb-5",
                        grouped: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_field, {
                            class: "left-datepicker",
                            label: _ctx.l("generic/start"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.nextApptStart,
                                "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => ((_ctx.filtering.nextApptStart) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering["nextApptStart"] !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[9] || (_cache[9] = _withModifiers($event => (_ctx.resetFilters("nextApptStart")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"]),
                          _createVNode(_component_b_field, {
                            label: _ctx.l("generic/end"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.nextApptEnd,
                                "onUpdate:modelValue": _cache[10] || (_cache[10] = $event => ((_ctx.filtering.nextApptEnd) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering["nextApptEnd"] !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[11] || (_cache[11] = _withModifiers($event => (_ctx.resetFilters("nextApptEnd")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"])
                        ]),
                        _: 1 /* STABLE */
                      }),
                      _createElementVNode("div", _hoisted_49, _toDisplayString(_ctx.settingsStore.label('deadline')), 1 /* TEXT */),
                      _createVNode(_component_b_field, {
                        class: "mb-5",
                        grouped: ""
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_b_field, {
                            class: "left-datepicker",
                            label: _ctx.l("generic/start"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.dueDateStart,
                                "onUpdate:modelValue": _cache[12] || (_cache[12] = $event => ((_ctx.filtering.dueDateStart) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering["dueDateStart"] !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[13] || (_cache[13] = _withModifiers($event => (_ctx.resetFilters("dueDateStart")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"]),
                          _createVNode(_component_b_field, {
                            label: _ctx.l("generic/start"),
                            "label-position": "on-border",
                            expanded: ""
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_b_datepicker, {
                                modelValue: _ctx.filtering.dueDateEnd,
                                "onUpdate:modelValue": _cache[14] || (_cache[14] = $event => ((_ctx.filtering.dueDateEnd) = $event)),
                                locale: [ _ctx.activeDateTimeLocalizationCode, "en" ],
                                "icon-right": _ctx.filtering["dueDateEnd"] !== null ? "close-circle" : "",
                                "icon-right-clickable": "",
                                onIconRightClick: _cache[15] || (_cache[15] = _withModifiers($event => (_ctx.resetFilters("dueDateEnd")), ["stop"]))
                              }, null, 8 /* PROPS */, ["modelValue", "locale", "icon-right"])
                            ]),
                            _: 1 /* STABLE */
                          }, 8 /* PROPS */, ["label"])
                        ]),
                        _: 1 /* STABLE */
                      })
                    ])
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ], 2 /* CLASS */)
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["can-close"]))
}