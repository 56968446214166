//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { createRouter, createWebHistory } from 'vue-router'

import { useRootStore }             from '../store/root.js'
import { useAuthStore }             from '../store/auth.js'

import DesktopOverview              from '../../desktop/overview/Overview.vue'
import DesktopManageLeads           from '../../desktop/manageleads/ManageLeads.vue'
import DesktopSetupProducts         from '../../desktop/setup/Products.vue'
import DesktopSetupUsers            from '../../desktop/setup/Users.vue'
import DesktopSetupData             from '../../desktop/setup/Data.vue'
import DesktopSetupLabels           from '../../desktop/setup/Labels.vue'
import DesktopSetupLocalization     from '../../desktop/setup/Localization.vue'
import DesktopSetupSettings         from '../../desktop/setup/Settings.vue'
import DesktopAccount               from '../../desktop/Account.vue'

import MobileMapView                from '../../mobile/MapView.vue'
import MobileSearch                 from '../../mobile/Search.vue'
import MobileManageLeads            from '../../mobile/ManageLeads.vue'

import Login                        from '../../shared/Login.vue'
import Activities                   from '../../shared/Activities.vue'
import LeadDetails                  from '../../shared/LeadDetails.vue'


const routes = [
    { path: '/', name: 'overview', component: DesktopOverview, meta: { requiresAdmin: false, allowed: [ 'desktop' ] } },

    { path: '/search', name: 'search', component: MobileSearch, meta: { requiresAdmin: false, allowed: [ 'mobile' ] } },

    { path: '/mapview', name: 'map-view', component: MobileMapView, meta: { requiresAdmin: false, allowed: [ 'mobile' ] } },

    { path: '/manage-leads', name: 'manage-leads', component: DesktopManageLeads, meta: { requiresAdmin: false, allowed: [ 'desktop' ] } },
    { path: '/manage-leads-mobile', name: 'manage-leads-mobile', component: MobileManageLeads, meta: { requiresAdmin: false, allowed: [ 'mobile' ] } },

    { path: '/lead/:leadId', name: 'lead-details', component: LeadDetails, meta: { requiresAdmin: false, allowed: [ 'desktop', 'mobile' ] }, props: true },

    { path: '/activities', name: 'activities', component: Activities, meta: { requiresAdmin: false, allowed: [ 'desktop', 'mobile' ] } },

    { path: '/setup/products', name: 'setup-products', component: DesktopSetupProducts, meta: { requiresAdmin: true, allowed: [ 'desktop' ] } },
    { path: '/setup/users', name: 'setup-users', component: DesktopSetupUsers, meta: { requiresAdmin: true, allowed: [ 'desktop' ] } },
    { path: '/setup/data', name: 'setup-data', component: DesktopSetupData, meta: { requiresAdmin: true, allowed: [ 'desktop' ] } },
    { path: '/setup/labels', name: 'setup-labels', component: DesktopSetupLabels, meta: { requiresAdmin: true, allowed: [ 'desktop' ] } },
    { path: '/setup/localization', name: 'setup-localization', component: DesktopSetupLocalization, meta: { requiresSuperAdmin: true, allowed: [ 'desktop' ] } },
    { path: '/setup/settings', name: 'setup-settings', component: DesktopSetupSettings, meta: { requiresSuperAdmin: true, allowed: [ 'desktop' ] } },

    { path: '/account', name: 'account', component: DesktopAccount, meta: { requiresAdmin: false, allowed: [ 'desktop', 'mobile' ] } },

    { path: '/login', name: 'login', component: Login, meta: { allowed: [ 'desktop', 'mobile' ] } }
]


const router = createRouter({
    history: createWebHistory(),
    linkExactActiveClass: 'is-active',
    routes
})


router.beforeEach(async (to, from) => {
    const rootStore = useRootStore()
    const authStore = useAuthStore()

    // Wait for authentication on 1st app load before navigating to route
    await rootStore.authentication()

    if(!authStore.isLoggedIn && to.name !== 'login') return { name: 'login' }

    const isMobile = rootStore.isMobile

    const frontpageAfterLogin = isMobile ? 'map-view' : 'overview'

    if(authStore.isLoggedIn && to.name === 'login') return { name: frontpageAfterLogin }

    if(to.meta.requiresAdmin && !authStore.isAdmin) {
        return { name: frontpageAfterLogin }
    } else if(to.meta.requiresSuperAdmin && !authStore.isSuperAdmin) {
        return { name: frontpageAfterLogin }
    }

    // Pages allowed on mobile
    if(isMobile && !to.meta.allowed.includes('mobile')) {
        return { name: frontpageAfterLogin }
    }

    // Navigation OK
    rootStore.currentPage = to.name || ''

    return true
})


export default router
