//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'
import * as appHelpers              from '../helpers.js'

import { dayjs }                    from '../app.js'

import * as crudFactory             from './_crud.js'
import { useContactsStore }         from './contacts.js'
import { useProductsStore }         from './products.js'
import { useSalesStore }            from './sales.js'
import { useSettingsStore }         from './settings.js'
import { useSortingStore }          from './sorting.js'
import { useTodosStore }            from './todo.js'
import { useUsersStore }            from './users.js'


const api = {
    'create':                       [ 'POST',   '/leads' ],
    'update':                       [ 'PATCH',  '/leads' ],
    'assign':                       [ 'POST',   '/leads/assignments' ],
    'report':                       [ 'PATCH',  '/reports' ],
    'destroy':                      [ 'DELETE', '/leads' ],
    'bulkStatus':                   [ 'POST',   '/leads/bulkstatus' ],
    'bulkDelete':                   [ 'DELETE', '/leads/bulkdelete' ],
    'bulkEdit':                     [ 'POST',   '/leads/bulk-edit' ],
    'excelImport':                  [ 'POST',   '/leads/excel-import' ],
    'saveSalesTargetAndNumbers':    [ 'PUT',    '/sales/target-and-numbers' ],
    'uploadSales':                  [ 'POST',   '/sales/uploadsales' ],
    'addDocumentLink':              [ 'POST',   '/leads/document-link' ],
    'editDocumentLink':             [ 'PATCH',  '/leads/document-link' ],
    'deleteDocumentLink':           [ 'DELETE', '/leads/document-link' ],
    'copyLead':                     [ 'POST',   '/leads/copylead' ],
}


const leads     = []
const leadsMap  = new Map()


const initialFilteringState = () => {
    return {
        leadName:               '',
        products:               [],
        projects:               [],
        assignments:            [],
        statuses:               [],
        salesYtd:               [],
        salesYtdVs:             [],
        addressFullAddress:     '',
        addressStreet:          '',
        addressCity:            '',
        addressState:           '',
        addressCountries:       [],
        addressMisc:            '',
        phoneNumber:            '',
        website:                '',
        email:                  '',
        contact:                '',
        workzone1:              '',
        workzone2:              '',
        workzone3:              '',
        workzone4:              '',
        region1:                '',
        tags:                   [],
        types:                  [],
        customerTypes:          [],
        lastComment:            '',
    
        filterOption: {
            products:               'any-of',
            projects:               'any-of',
            assignments:            'any-of',
            statuses:               'any-of',
            addressCountries:       'any-of',
            tags:                   'any-of',
            types:                  'any-of',
            customerTypes:          'any-of',
        },

        questionFilters:        [],

        reportedStart:          null,
        reportedEnd:            null,
        nextApptStart:          null,
        nextApptEnd:            null,
        dueDateStart:           null,
        dueDateEnd:             null,
    }
}

const initialState = () => {
    return {
        // We put this here because we want to share this state between main
        // table on Manage Leads -page, aswell as the "mini table" on the right
        // sidebar when google map maximized view is enabled
        leadsPerPage:   '25',
        pagination:     null,
        currentPage:    1,
        collapsedMainLeads: new Set(),
    }
}


export const useLeadsStore = defineStore('leads', {
    state: () => {
        return {
            ...crudFactory.states(),

            filtering: initialFilteringState(),

            ...initialState()
        }
    },


    //
    //
    //  ██████╗ ███████╗████████╗████████╗███████╗██████╗ ███████╗
    // ██╔════╝ ██╔════╝╚══██╔══╝╚══██╔══╝██╔════╝██╔══██╗██╔════╝
    // ██║  ███╗█████╗     ██║      ██║   █████╗  ██████╔╝███████╗
    // ██║   ██║██╔══╝     ██║      ██║   ██╔══╝  ██╔══██╗╚════██║
    // ╚██████╔╝███████╗   ██║      ██║   ███████╗██║  ██║███████║
    //  ╚═════╝ ╚══════╝   ╚═╝      ╚═╝   ╚══════╝╚═╝  ╚═╝╚══════╝
    //
    //
    getters: {
        ...crudFactory.getters(leads, leadsMap),



        // ████████████████████████████████████████████████████████████████████████████████████
        // ████████████████████████████████████████████████████████████████████████████████████
        //
        // FILTERING
        //
        // ████████████████████████████████████████████████████████████████████████████████████
        // ████████████████████████████████████████████████████████████████████████████████████

        /*
         * "Stage 0" filtering, all filters EXCEPT status filter. Used e.g. on Overview-page
         * "task pipeline" doughnut charts because we don't want status filter to affect those.
         */
        filteredStage0() {
            this.updateToggler

            if(this.numFiltersActive === 0) {
                return leads
            }

            const settingsStore = useSettingsStore()

            const filteredLeads = leads.filter(lead => {


                //
                // Simple text filters where filtering happens "live"
                // as the search is being written
                //
                const simpleTextFilters = [
                    { filter: 'leadName',            field: 'name',          type: 'text' },
                    { filter: 'addressFullAddress',  field: 'address',       type: 'text' },
                    { filter: 'addressStreet',       field: 'streetaddress', type: 'text' },
                    { filter: 'addressCity',         field: 'city',          type: 'text' },
                    { filter: 'addressState',        field: 'state',         type: 'text' },
                    { filter: 'addressMisc',         field: 'addressmisc',   type: 'text' },
                    { filter: 'phoneNumber',         field: 'phoneNumbers',  type: 'array' },
                    { filter: 'website',             field: 'website',       type: 'text' },
                    { filter: 'email',               field: 'email',         type: 'text' },
                    { filter: 'workzone1',           field: 'zone1',         type: 'text' },
                    { filter: 'workzone2',           field: 'zone2',         type: 'text' },
                    { filter: 'workzone3',           field: 'zone3',         type: 'text' },
                    { filter: 'workzone4',           field: 'zone4',         type: 'text' },
                    { filter: 'region1',             field: 'region',        type: 'text' }
                ]

                for(const textFilter of simpleTextFilters) {
                    if(this.filtering[textFilter.filter].length === 0) continue

                    const filter = appHelpers.stringNormalize(this.filtering[textFilter.filter])

                    let filterAgainst

                    if(textFilter.type === 'text') {
                        if(!lead[textFilter.field]) return false

                        filterAgainst = appHelpers.stringNormalize(lead[textFilter.field])
                    } else if(textFilter.type === 'array') {
                        if(!Array.isArray(lead[textFilter.field]) || lead[textFilter.field].length === 0) return false

                        filterAgainst = appHelpers.stringNormalize(lead[textFilter.field].join(' '))
                    }

                    if(!filterAgainst.includes(filter)) return false
                }


                //
                // PRODUCTS filter
                //
                if(this.filtering.products.length > 0) {
                    const filterOption = this.filtering.filterOption.products

                    if(!Array.isArray(lead.products) || lead.products.length === 0) return false

                    let foundOne = false

                    for(let productFilter of this.filtering.products) {
                        if(typeof productFilter !== 'object' && !productFilter?._id) continue

                        if(lead.products.includes(productFilter._id)) {
                            foundOne = true
                        } else if(filterOption === 'all-of') {
                            return false
                        }
                    }

                    if(filterOption === 'any-of' && !foundOne) return false
                }


                //
                // ASSIGNMENTS filter
                //
                if(this.filtering.assignments.length > 0) {
                    const filterOption = this.filtering.filterOption.assignments

                    // Compile all of lead's products' assigned users
                    const assignedUsers = lead.assignments.map(a => {
                        if(!a.assigneeId) return 'unassigned'

                        return  a.assigneeId
                    })

                    let foundOne = false

                    for(let filteredUser of this.filtering.assignments) {
                        const found = assignedUsers.includes(filteredUser._id)

                        if(found) {
                            foundOne = true
                        } else if(filterOption === 'all-of') {
                            return false
                        }
                    }

                    if(filterOption === 'any-of' && !foundOne) return false
                }


                //
                // SALES filters
                //
                const salesFilters = [
                    { filter: 'salesYtd',      field: 'monthly' },
                    { filter: 'salesYtdVs',    field: 'vsTargetPercentage' },
                ]

                for(const salesFilter of salesFilters) {
                    const filter = this.filtering[salesFilter.filter]

                    if(filter.length > 0) {
                        if(!lead.hasSales) return false
    
                        const salesStore = useSalesStore()
    
                        const sales = appHelpers.calcSales(lead, salesStore.selectedMonth)
    
                        let minRange = Number(filter[0])
                        let maxRange = Number(filter[1])
    
                        if(!isNaN(minRange) && isNaN(maxRange) && sales[salesFilter.field] < minRange) return false
                        if(isNaN(minRange) && !isNaN(maxRange) && sales[salesFilter.field] > maxRange) return false
                        if(!isNaN(minRange) && !isNaN(maxRange)) {
                            if(sales[salesFilter.field] < minRange || sales[salesFilter.field] > maxRange) return false
                        }
                    }
                }


                //
                // COUNTRIES filter
                //
                if(this.filtering.addressCountries.length > 0) {
                    const filterOption = this.filtering.filterOption.addressCountries

                    let foundOne = false

                    for(let countriesFilter of this.filtering.addressCountries) {
                        const filterCountryCode = countriesFilter.code.toLowerCase()

                        let leadCountryCode = ''

                        if(lead.countrycode) {
                            leadCountryCode = lead.countrycode
                        } else if(lead.googleAddress?.countryCode) {
                            // Try to find from google geolocation address_components
                            leadCountryCode = lead.googleAddress.countryCode
                        } else if(Array.isArray(lead.googleAddress?.address_components)) {
                            // Try to find from google geolocation address_components
                            const addrComps = lead.googleAddress.address_components

                            for(const addrComp of addrComps) {
                                if(Array.isArray(addrComp?.types) && addrComp.types.includes('country') && addrComp.short_name.length === 2) {
                                    leadCountryCode = addrComp.short_name
                                }
                            }
                        }

                        if(leadCountryCode.toLowerCase() === filterCountryCode) {
                            foundOne = true
                            break
                        }
                    }

                    if(!foundOne) return false
                }


                //
                // LAST COMMENT filter
                //
                if(this.filtering.lastComment.length > 0) {
                    const leadCommentingMode = settingsStore.leadCommentingMode

                    const commentFilter = appHelpers.stringNormalize(this.filtering.lastComment)

                    let lastCommentsText

                    if(leadCommentingMode === 'per_assignment') {
                        let lastCommentsPerAssignment = []

                        for(const assignment of lead.assignments) {
                            if(!Array.isArray(assignment.comments) || assignment.comments.length === 0) continue

                            const userComments = assignment.comments.filter(c => {
                                return c.commentor !== 'system' && !c.systemComment && !c.systemComment?.type
                            })

                            if(userComments.length === 0) continue

                            userComments.sort(appHelpers.stringSort.bind(null, 'desc', 'postedAt'))

                            if(!userComments[0]?.comment) return false

                            const lastComment = userComments[0]

                            lastCommentsPerAssignment.push(appHelpers.stringNormalize(lastComment.comment))
                        }

                        lastCommentsText = lastCommentsPerAssignment.join(' ')

                    } else {
                        if(!Array.isArray(lead.comments) || lead.comments.length === 0) return false

                        const userComments = lead.comments.filter(c => c.commentor !== 'system' && !c.systemComment && !c.systemComment?.type)

                        if(userComments.length === 0) return false

                        userComments.sort(appHelpers.stringSort.bind(null, 'desc', 'postedAt'))

                        if(!userComments[0]?.comment) return false

                        const lastComment = userComments[0]

                        lastCommentsText = appHelpers.stringNormalize(lastComment.comment)
                    }

                    if(!lastCommentsText.includes(commentFilter)) return false
                }


                //
                // CONTACTS filter
                //
                if(this.filtering.contact.length > 0) {
                    if(!Array.isArray(lead.contacts) || lead.contacts.length === 0) return false

                    const contactFilter = appHelpers.stringNormalize(this.filtering.contact)

                    let foundOne = false

                    for(let contact of lead.contacts) {
                        const contactFullName = appHelpers.stringNormalize(contact.fullName)
                        const contactEmail = appHelpers.stringNormalize(contact.email)

                        const filterAgainst = `${contactFullName} ${contactEmail}`

                        if(filterAgainst.includes(contactFilter)) {
                            foundOne = true
                            break
                        }
                    }

                    if(!foundOne) return false
                }



                //
                // "Multi" text filters where you can input multiple search criterias, with
                // autocomplete dropdown and filtering requires you to click an option
                // from the dropdown (as opposed to "live" filtering for the simple text
                // filters above)
                //
                // type "string" means you can input multiple strings to filter by, but the filtering
                // happens from a simple text field on the lead object
                //
                // type "array" is same, except the field on the lead object is an array
                //
                const multiFilters = [
                    { filter: 'projects',        field: 'project',        type: 'string' },
                    { filter: 'tags',            field: 'tags',           type: 'array' },
                    { filter: 'types',           field: 'projecttype',    type: 'string' },
                    { filter: 'customerTypes',   field: 'customertype',   type: 'string' },
                ]

                for(const multiFilter of multiFilters) {
                    if(this.filtering[multiFilter.filter].length > 0) {
                        const filterOption = this.filtering.filterOption[multiFilter.filter]

                        if(multiFilter.type === 'string') {
                            if(!lead[multiFilter.field]) return false
                        } else if(multiFilter.type === 'array') {
                            if(!Array.isArray(lead[multiFilter.field]) || lead[multiFilter.field].length === 0) return false
                        }

                        let foundOne = false
        
                        for(let filteredText of this.filtering[multiFilter.filter]) {
                            filteredText = filteredText.toLowerCase().trim()

                            if(multiFilter.type === 'string') {
                                const filterAgainst = lead[multiFilter.field].toLowerCase().trim()

                                if(filterAgainst === filteredText) {
                                    foundOne = true
                                } else if(filterOption === 'all-of') {
                                    return false
                                }
                            } else if(multiFilter.type === 'array') {
                                const filterAgainstList = lead[multiFilter.field].map(i => i.toLowerCase().trim())

                                if(filterAgainstList.find(i => i === filteredText)) {
                                    foundOne = true
                                } else if(filterOption === 'all-of') {
                                    return false
                                }
                            }
                        }

                        if(filterOption === 'any-of' && !foundOne) return false

                    }
                }

                //
                // QUESTION/answers filtering
                //
                // Could use some clean up / shortening / splitting into functions...
                //
                for(const questionFilter of this.filtering.questionFilters) {
                    const productsStore = useProductsStore()

                    const templateId        = questionFilter.templateId
                    const templateQuestion  = productsStore.findTemplateQuestion(templateId)

                    if(!templateQuestion || !templateQuestion?._id) continue

                    const questionType      = templateQuestion.type
                    const quantitativeType  = templateQuestion?.quantitative_type || 'normal'
                    const filterType        = questionFilter.selectedType

                    let answerFound = false

                    for(const assignment of lead.assignments) {
                        const leadQuestion = assignment.answers[templateId]

                        if(!leadQuestion) continue // No answers to this question

                        answerFound = true
    
                        if(questionType === 'multiple' || questionType === 'multiple_many') {
                            if(questionFilter.text === '') {
                                //
                                // Advanced question filter section
                                // Either this or the below "simple text" filter can be active at a time
                                //
                                const options = leadQuestion.multiple_selection_strings
                                const answeredChoices = leadQuestion.answer.choices.map(choice => {
                                    return options.findIndex(o => o === choice)
                                })
                                
                                if(filterType === 'all-of') {
                                    if(questionFilter.selectedOptions.length === 0) {
                                        if(answeredChoices.length > 0) return false
                                    } else {
                                        for(const requiredOption of questionFilter.selectedOptions) {
                                            if(!answeredChoices.includes(requiredOption)) return false
                                        }
                                        if(questionType === 'multiple_many') {
                                            for(const answeredChoice of answeredChoices) {
                                                if(!questionFilter.selectedOptions.includes(answeredChoice)) return false
                                            }
                                        }
                                    }
                                } else if(filterType === 'any-of') {
                                    if(questionFilter.selectedOptions.length > 0) {
                                        let found = false

                                        for(const requiredOption of questionFilter.selectedOptions) {
                                            if(answeredChoices.includes(requiredOption)) found = true
                                        }
                
                                        if(!found) return false
                                    }
                                }
                            } else {
                                //
                                // Column quick text filter for multiple-select questions
                                // Either this or the above advanced filter can be active at a time
                                //
                                const searchStr = appHelpers.stringNormalize(questionFilter.text)

                                let answeredChoicesStr = leadQuestion.answer.choices.join(' ')
                                answeredChoicesStr = appHelpers.stringNormalize(answeredChoicesStr)

                                if(!answeredChoicesStr.includes(searchStr)) return false
                            }

                        } else if(questionType === 'slider') {
                            let minRange      = Number(questionFilter.range[0])
                            let maxRange      = Number(questionFilter.range[1])
                            const sliderValue = Number(leadQuestion.answer?.value)

                            if(isNaN(minRange)) minRange = leadQuestion.multiple_selection_strings[0]
                            if(isNaN(maxRange)) maxRange = leadQuestion.multiple_selection_strings[1]

                            if(!isNaN(minRange) && !isNaN(maxRange)) {
                                if(isNaN(sliderValue)) return false

                                if(sliderValue < minRange || sliderValue > maxRange) return false
                            }
                        } else if(questionType === 'number' || (questionType === 'value' && quantitativeType !== 'normal')) {
                            let minRange      = parseInt(questionFilter.range[0])
                            let maxRange      = parseInt(questionFilter.range[1])

                            let answerNum

                            if(questionType === 'number') {
                                answerNum = parseInt(leadQuestion.answer?.num)
                            } else {
                                answerNum = parseInt(leadQuestion.answer?.quantity)
                            }

                            if(!isNaN(minRange) && isNaN(maxRange)) {
                                if(isNaN(answerNum) || answerNum < minRange) return false
                            } else if(isNaN(minRange) && !isNaN(maxRange)) {
                                if(isNaN(answerNum) || answerNum > maxRange) return false
                            } else if(!isNaN(minRange) && !isNaN(maxRange)) {
                                if(isNaN(answerNum)) return false
                                if(answerNum < minRange || answerNum > maxRange) return false
                            }
                        } else if(questionType === 'value' && quantitativeType === 'normal') {
                            const searchStr = appHelpers.stringNormalize(questionFilter.text)
                            const answer    = appHelpers.stringNormalize(leadQuestion.answer?.value || '')

                            if(!answer.includes(searchStr)) return false
                        }

                    }

                    if(!answerFound) return false

                }


                //
                // TIME filters
                //
                if(this.filtering.reportedStart || this.filtering.reportedEnd) {
                    const reportedStart = this.filtering.reportedStart ? dayjs(this.filtering.reportedStart) : null
                    const reportedEnd   = this.filtering.reportedEnd ? dayjs(this.filtering.reportedEnd) : null

                    const reportedTimestamp = lead.reported_timestamp ? dayjs(lead.reported_timestamp) : null

                    if(!reportedTimestamp) return false

                    if(reportedStart && !reportedEnd && reportedTimestamp.isBefore(reportedStart, 'day')) return false
                    if(!reportedStart && reportedEnd && reportedTimestamp.isAfter(reportedEnd, 'day')) return false

                    if(reportedStart && reportedEnd) {
                        if(reportedTimestamp.isBefore(reportedStart, 'day') || reportedTimestamp.isAfter(reportedEnd, 'day')) {
                            return false
                        }
                    }
                }

                const reportTimeFilters = [
                    { startFilter: 'nextApptStart', endFilter: 'nextApptEnd', leadField: 'nextAppt' },
                    { startFilter: 'dueDateStart',  endFilter: 'dueDateEnd',  leadField: 'dueDate' },
                ]

                for(const reportTimeFilter of reportTimeFilters) {
                    if(this.filtering[reportTimeFilter.startFilter] || this.filtering[reportTimeFilter.endFilter]) {
                        const filterStart = this.filtering[reportTimeFilter.startFilter] ? dayjs(this.filtering[reportTimeFilter.startFilter]) : null
                        const filterEnd   = this.filtering[reportTimeFilter.endFilter] ? dayjs(this.filtering[reportTimeFilter.endFilter]) : null

                        let foundOne = false

                        for(const assignment of lead.assignments) {
                            if(!assignment[reportTimeFilter.leadField]) continue

                            const timeAgainst = dayjs(assignment[reportTimeFilter.leadField])

                            if(!timeAgainst) return false

                            if(filterStart && !filterEnd && timeAgainst.isSameOrAfter(filterStart, 'day')) foundOne = true
                            if(!filterStart && filterEnd && timeAgainst.isSameOrBefore(filterEnd, 'day')) foundOne = true

                            if(filterStart && filterEnd) {
                                if(timeAgainst.isSameOrAfter(filterStart, 'day') && timeAgainst.isSameOrBefore(filterEnd, 'day')) {
                                    foundOne = true
                                }
                            }
                        }

                        if(!foundOne) return false
                    }
                }


                //
                // All filters passed
                //
                return true

            })

            return filteredLeads
        },


        /*
         * Fully filtered leads list (all filters active including status filter)
         */
        filtered() {
            // Need this since Vue 3.4 reactivity system refactor
            this.updateToggler

            if(this.numFiltersActive === 0) {
                return this.filteredStage0
            }

            return this.filteredStage0.filter(lead => {
                //
                // STATUS filter
                //
                if(this.filtering.statuses.length > 0) {
                    const filterOption = this.filtering.filterOption.statuses

                    const leadStatuses = lead.assignments.map(a => a.statusId).filter(s => s !== null)

                    let foundOne = false

                    for(let filteredStatus of this.filtering.statuses) {
                        const found = leadStatuses.includes(filteredStatus._id)

                        if(found) {
                            foundOne = true
                        } else if(filterOption === 'all-of') {
                            return false
                        }
                    }

                    if(filterOption === 'any-of' && !foundOne) return false
                }

                // Filters passed
                return true

            })
        },


        /*
         * Fully filtered leads list (all filters active) AND HQ leads included if subleads
         * remain after filtering.
         */
        filteredWithHQ() {
            // Need this since Vue 3.4 reactivity system refactor
            this.updateToggler

            const filteredLeads = [ ...this.filtered ]

            const hqLeadsMap = new Map()

            // Collect all HQ leads
            for(const lead of leads) {
                if(!lead.project || lead.project_isparent !== true) continue

                hqLeadsMap.set(lead.project, lead)
            }

            const includedProjects = new Set()
            const shouldHaveProjects = new Set()

            // From filtered leads, collect projects where we already have HQ lead (after filtering)
            // and projects where we SHOULD have HQ lead
            for(const filteredLead of filteredLeads) {
                if(filteredLead.project_isparent === true) {
                    includedProjects.add(filteredLead.project)
                } else if(filteredLead.project) {
                    shouldHaveProjects.add(filteredLead.project)
                }
            }

            // Grab projects that are missing the HQ lead after filtering
            const missingProjects = [...shouldHaveProjects].filter(p => ![...includedProjects].includes(p) && hqLeadsMap.has(p))

            // Put HQ lead back to filtered leads
            for(const missingProject of missingProjects) {
                filteredLeads.push(hqLeadsMap.get(missingProject))
            }

            return filteredLeads
        },


        numFiltersActive() {
            const numDetailsFilters = this.numDetailsFiltersActive
            const numAnswersFilters = this.numAnswersFiltersActive
            const numTimeFilters    = this.numTimeFiltersActive

            return numDetailsFilters + numAnswersFilters + numTimeFilters
        },

        numDetailsFiltersActive() {
            let numDetailsFilters = 0

            if(this.filtering.leadName.length > 0) numDetailsFilters++
            if(this.filtering.products.length > 0) numDetailsFilters++
            if(this.filtering.projects.length > 0) numDetailsFilters++
            if(this.filtering.assignments.length > 0) numDetailsFilters++
            if(this.filtering.statuses.length > 0) numDetailsFilters++
            if(this.filtering.salesYtd.length > 0) numDetailsFilters++
            if(this.filtering.salesYtdVs.length > 0) numDetailsFilters++
            if(this.filtering.addressFullAddress.length > 0) numDetailsFilters++
            if(this.filtering.addressStreet.length > 0) numDetailsFilters++
            if(this.filtering.addressCity.length > 0) numDetailsFilters++
            if(this.filtering.addressState.length > 0) numDetailsFilters++
            if(this.filtering.addressCountries.length > 0) numDetailsFilters++
            if(this.filtering.addressMisc.length > 0) numDetailsFilters++
            if(this.filtering.phoneNumber.length > 0) numDetailsFilters++
            if(this.filtering.website.length > 0) numDetailsFilters++
            if(this.filtering.email.length > 0) numDetailsFilters++
            if(this.filtering.contact.length > 0) numDetailsFilters++
            if(this.filtering.workzone1.length > 0) numDetailsFilters++
            if(this.filtering.workzone2.length > 0) numDetailsFilters++
            if(this.filtering.workzone3.length > 0) numDetailsFilters++
            if(this.filtering.workzone4.length > 0) numDetailsFilters++
            if(this.filtering.region1.length > 0) numDetailsFilters++
            if(this.filtering.tags.length > 0) numDetailsFilters++
            if(this.filtering.types.length > 0) numDetailsFilters++
            if(this.filtering.customerTypes.length > 0) numDetailsFilters++
            if(this.filtering.lastComment.length > 0) numDetailsFilters++

            return numDetailsFilters
        },

        numAnswersFiltersActive() {
            return this.filtering.questionFilters.length
        },

        numTimeFiltersActive() {
            let numTimeFilters = 0

            if(this.filtering.reportedStart || this.filtering.reportedEnd) numTimeFilters++
            if(this.filtering.nextApptStart || this.filtering.nextApptEnd) numTimeFilters++
            if(this.filtering.dueDateStart || this.filtering.dueDateEnd) numTimeFilters++

            return numTimeFilters
        },

        filterActive: (state) => (filter) => {
            return state.filtering[filter].length > 0
        },

        questionFilterActive: (state) => (templateId) => {
            return state.filtering.questionFilters.find(qf => qf.templateId === templateId) ? true : false
        },

        filterOption: (state) => (filter) => {
            return state.filtering.filterOption[filter]
        },



        // ████████████████████████████████████████████████████████████████████████████████████
        // ████████████████████████████████████████████████████████████████████████████████████
        //
        // GROUPING AND SORTING
        //
        // ████████████████████████████████████████████████████████████████████████████████████
        // ████████████████████████████████████████████████████████████████████████████████████

        groupedLeads() {
            const groupedLeadsObj = {}
    
            const noProjectLeads = []
    
            // Group first
            for(const lead of this.filteredWithHQ) {
                // Collect leads with no project
                if(!lead.project) {
                    noProjectLeads.push(lead)
                    continue
                }
        
                if(!groupedLeadsObj[lead.project]) {
                    groupedLeadsObj[lead.project] = {
                        subleads: []
                    }
                }
        
                if(lead.project_isparent === true) {
                    Object.assign(groupedLeadsObj[lead.project], lead)
                } else {
                    groupedLeadsObj[lead.project].subleads.push(lead)
                }
            }
    
            const groupedLeadsArr = Object.values(groupedLeadsObj)
    
            // Add leads with no project
            groupedLeadsArr.push(...noProjectLeads)
    
    
            /**************************************************************************
             * This section will drop leads that somehow have no parent/HQ
             * lead, back to the "main level". This shouldn't happen anymore
             * since we included HQ leads always if subleads are filtered, but
             * lets keep it here just for safety.
             */
            const droppedLeads = []
            let i = groupedLeadsArr.length
            while(i--) {
                if(!groupedLeadsArr[i]._id) {
                    // No parent lead exists or filtering removed it, drop subleads to main level
                    droppedLeads.push(...groupedLeadsArr[i].subleads)
                    groupedLeadsArr.splice(i, 1)
                }
    
            }
            groupedLeadsArr.push(...droppedLeads)
            /* END-drop-subleads-to-main-level - section
             *************************************************************************/

            return groupedLeadsArr
        },


        sortedLeads() {
            const productsStore = useProductsStore()
            const salesStore    = useSalesStore()
            const sortingStore  = useSortingStore()

            const column = sortingStore.column
            const order = sortingStore.order
    
            const leadsSorted = [ ...this.groupedLeads ]
    
            const stringSortColumns = {
                // Field name (this.tableColumns)  -  Lead object property
                'creation_timestamp':                 'creation_timestamp',
                'leadname':                           'name',
                'belongs_to':                         'project',
                'full_address':                       'address',
                'address':                            'streetaddress',
                'city':                               'city',
                'state':                              'state',
                'country':                            'countrycode',
                'address_misc':                       'addressmisc',
                'website':                            'website',
                'email':                              'email',
                'phone_numbers':                      'phoneNumbers',
                'tags':                               'tags',
                'workzone1':                          'zone1',
                'workzone2':                          'zone2',
                'workzone3':                          'zone3',
                'workzone4':                          'zone4',
                'region1':                            'region',
                'type':                               'projecttype',
                'customer_type':                      'customertype',
            }
    
            if(Object.keys(stringSortColumns).includes(column)) {
                const leadField = stringSortColumns[column]
        
                leadsSorted.sort(appHelpers.stringSort.bind(null, order, leadField))
        
                for(const lead of leadsSorted) {
                    if(Array.isArray(lead.subleads)) {
                        lead.subleads = [ ...lead.subleads ]
                        lead.subleads.sort(appHelpers.stringSort.bind(null, order, leadField))
                    }
                }
            } else if(column === 'product') {
                leadsSorted.sort(appHelpers.productsSort.bind(null, order))
    
                for(const lead of leadsSorted) {
                    if(Array.isArray(lead.subleads)) {
                        lead.subleads = [ ...lead.subleads ]
                        lead.subleads.sort(appHelpers.productsSort.bind(null, order))
                    }
                }
            } else if(column === 'status') {
                leadsSorted.sort(appHelpers.statusesSort.bind(null, order))
                for(const lead of leadsSorted) {
                    if(Array.isArray(lead.subleads)) {
                        lead.subleads = [ ...lead.subleads ]
                        lead.subleads.sort(appHelpers.statusesSort.bind(null, order))
                    }
                }
            } else if(column === 'assignment') {
                leadsSorted.sort(appHelpers.assignmentsSort.bind(null, order))
                for(const lead of leadsSorted) {
                    if(Array.isArray(lead.subleads)) {
                        lead.subleads = [ ...lead.subleads ]
                        lead.subleads.sort(appHelpers.assignmentsSort.bind(null, order))
                    }
                }
            } else if(column === 'next_appts' || column === 'due_dates') {
              let dateColumn
              if(column === 'next_appts') dateColumn = 'nextAppt'
              if(column === 'due_dates') dateColumn = 'dueDate'
    
              leadsSorted.sort(appHelpers.reportDateSort.bind(null, order, dateColumn))
              for(const lead of leadsSorted) {
                  if(Array.isArray(lead.subleads)) {
                      lead.subleads = [ ...lead.subleads ]
                      lead.subleads.sort(appHelpers.reportDateSort.bind(null, order, dateColumn))
                  }
              }
            } else if(column === 'sales_ytd' || column === 'sales_ytdvs') {
                leadsSorted.sort(appHelpers.salesSort.bind(null, order, column, salesStore.selectedMonth))
                for(const lead of leadsSorted) {
                    if(Array.isArray(lead.subleads)) {
                        lead.subleads = [ ...lead.subleads ]
                        lead.subleads.sort(appHelpers.salesSort.bind(null, order, column, salesStore.selectedMonth))
                    }
                }
            } else if(column.startsWith('question_')) {
    
              //
              // Sorting by question answers
              //
              const templateQuestionId = column.substring(9)
              let templateQuestion = null
    
              for(const product of productsStore.all) {
                if(!Array.isArray(product.templateQuestions)) continue
    
                templateQuestion = product.templateQuestions.find(q => q._id === templateQuestionId)
    
                if(templateQuestion) break
              }
    
              if(templateQuestion) {
                leadsSorted.sort(appHelpers.questionAnswersSort.bind(null, order, templateQuestion))
                for(const lead of leadsSorted) {
                  if(Array.isArray(lead.subleads)) {
                    lead.subleads = [ ...lead.subleads ]
                    lead.subleads.sort(appHelpers.questionAnswersSort.bind(null, order, templateQuestion))
                  }
                }
              }
            }

            return leadsSorted
          },
    
    },



    //
    //
    //  █████╗  ██████╗████████╗██╗ ██████╗ ███╗   ██╗███████╗
    // ██╔══██╗██╔════╝╚══██╔══╝██║██╔═══██╗████╗  ██║██╔════╝
    // ███████║██║        ██║   ██║██║   ██║██╔██╗ ██║███████╗
    // ██╔══██║██║        ██║   ██║██║   ██║██║╚██╗██║╚════██║
    // ██║  ██║╚██████╗   ██║   ██║╚██████╔╝██║ ╚████║███████║
    // ╚═╝  ╚═╝ ╚═════╝   ╚═╝   ╚═╝ ╚═════╝ ╚═╝  ╚═══╝╚══════╝
    //
    //
    actions: {
        ...crudFactory.actions('leads', api, leads, leadsMap),


        async create(payload) {
            const { newLead, updatedProducts } = await apiRequest(...api.create, payload)

            this.syncAddLeads(newLead)

            if(updatedProducts) {
                const productsStore = useProductsStore()

                productsStore.PUT(updatedProducts)
            }

            return newLead
        },

        syncAddLeads(newLeads) {
            if(newLeads === null) return

            this.PUT(newLeads)

            this.syncProjectHQs(newLeads)
        },

        async patch({ id, payload }) {
            const path = api.update[1] + `/${id}`

            const updatedLead = await apiRequest(api.update[0], path, payload)

            this.syncUpdate(updatedLead)

            return updatedLead
        },

        syncUpdate(updatedLeads) {
            this.UPDATE(updatedLeads)

            this.syncProjectHQs(updatedLeads)
        },

        syncProjectHQs(syncLeads) {
            if(!Array.isArray(syncLeads)) syncLeads = [ syncLeads ]

            for(const syncLead of syncLeads) {
                if(syncLead.project_isparent !== true) continue

                // HQ lead
                for(const lead of leads) {
                    if(lead._id === syncLead._id || lead.project !== syncLead.project) continue

                    lead.project_isparent = false
                }
            }

            if(this.reactivityEnabled) this.updateToggler++
        },


        resetStore() {
            Object.assign(this, initialState())
        },


        resetFilters(filter = null) {
            if(filter === null) {
                Object.assign(this.filtering, initialFilteringState())
            } else {
                this.filtering[filter] = initialFilteringState()[filter]
                this.filtering.filterOption[filter] = initialFilteringState().filterOption[filter]
            }
        },


        removeFilter({ filter, value }) {
            let idx = -1
    
            if(typeof value === 'object') {
                idx = this.filtering[filter].findIndex(i => i._id === value._id)
            } else {
                idx = this.filtering[filter].findIndex(i => i === value)
            }
    
            if(idx === -1) return
    
            this.filtering[filter].splice(idx, 1)
        },


        async assign({ payload, bulkAssign }) {
            if(!bulkAssign) {
                const { updatedLeads, updatedProducts } = await apiRequest(...api.assign, payload)

                const productsStore = useProductsStore()

                if(updatedLeads.length === 0) {
                    // In non-bulk assign, if updatedLeads comes back empty, that means
                    // we lost access to the lead via the assignment that was just made
                    // -> delete lead
                    const leadId = payload[0].leadId
                    this.syncDelete(leadId)
                } else {
                    this.PUT(updatedLeads)
                }

                // Products should always come back, as normal user can't remove the last
                // assignment a user has in a product, hence we can't lose product visibility
                productsStore.PUT(updatedProducts)
            } else {
                const path = api.assign[1] + '?bulkAssign=1'
    
                await apiRequest(api.assign[0], path, payload)
            }
        },


        async report(leadId, productId, reportUpdate, questionsWithAnswers, comment) {
            let path = api.report[1] + `/${leadId}/${productId}`

            const payload = { reportUpdate, questionsWithAnswers, comment }

            const updatedLead = await apiRequest(api.report[0], path, payload)

            this.PUT(updatedLead)
        },


        async bulkStatus(payload) {
            const updatedLeads = await apiRequest(...api.bulkStatus, payload)

            this.PUT(updatedLeads)
        },


        async destroy(leadId) {
            const url = api.destroy[1] + `/${leadId}`

            await apiRequest(api.destroy[0], url)

            this.syncDelete(leadId)
        },


        async bulkDelete(leadIds) {
            const payload = {
                bulkDeleteLeadIds: leadIds
            }
    
            await apiRequest(...api.bulkDelete, payload)
    
            this.syncDelete(leadIds)
        },
    
    
        syncDelete(deletedLeadIds) {
            const productsStore = useProductsStore()
            const todosStore = useTodosStore()

            if(!Array.isArray(deletedLeadIds)) deletedLeadIds = [ deletedLeadIds ]
    
            this.DESTROY_BY({ field: '_id', values: deletedLeadIds })
    
            productsStore.PULL_LEADS(deletedLeadIds)
            todosStore.PULL_LEADS(deletedLeadIds)
        },


        async bulkEdit(payload) {
            const updatedLeads = await apiRequest(...api.bulkEdit, payload)
    
            this.PUT(updatedLeads)
        },


        async excelImport(payload) {
            await apiRequest(...api.excelImport, payload)
        },


        async addDocumentLink(leadId, text, url) {
            let path = api.addDocumentLink[1] + `/${leadId}`

            const payload = { text, url }

            const updatedLead = await apiRequest(api.addDocumentLink[0], path, payload)

            this.PUT(updatedLead)
        },

        async editDocumentLink(leadId, documentLinkId, text) {
            let path = api.editDocumentLink[1] + `/${leadId}/${documentLinkId}`

            const payload = { text }

            const updatedLead = await apiRequest(api.editDocumentLink[0], path, payload)

            this.PUT(updatedLead)
        },

        async deleteDocumentLink(leadId, documentLinkId) {
            let path = api.deleteDocumentLink[1] + `/${leadId}/${documentLinkId}`

            const updatedLead = await apiRequest(api.deleteDocumentLink[0], path)

            this.PUT(updatedLead)
        },


        async copyLead(leadId, newLeadName) {
            let path = api.copyLead[1] + `/${leadId}`

            const payload = { newLeadName }

            const copiedLead = await apiRequest(api.copyLead[0], path, payload)

            this.ADD(copiedLead)

            return copiedLead
        },


        PULL_PRODUCT(productId) {
            let changed = false

            for(const lead of leads) {
                if(!Array.isArray(lead.products) || lead.products.length === 0) continue
    
                const idx = lead.products.findIndex(p => p === productId)
    
                if(idx !== -1) {
                    lead.products.splice(idx, 1)
                    changed = true
                }

                const productAssignmentIdx = lead.assignments.findIndex(a => a.productId === productId)

                if(productAssignmentIdx !== -1) {
                    lead.assignments.splice(productAssignmentIdx, 1)
                    changed = true
                }
            }

            if(changed && this.reactivityEnabled) this.updateToggler++
        },


        PULL_CONTACT(contactId) {
            let changed = false

            for(const lead of leads) {
                if(!Array.isArray(lead.contacts) || lead.contacts.length === 0) continue
    
                const idx = lead.contacts.findIndex(c => c._id === contactId)
    
                if(idx === -1) continue
    
                lead.contacts.splice(idx, 1)
                changed = true
            }

            if(changed && this.reactivityEnabled) this.updateToggler++
        },


        /*
         * Sync product changes to lead assignments, ie. if product name gets updated,
         * update product names in lead.assignments
         * 
         * We do it this way for performance reasons (rather than re-syncing all leads from backend);
         * for example, when updating a product's name, all leads that have that product assigned would
         * need re-syncing. This can mean thousands of leads. So the lighter option both for the
         * backend and bandwidth usage is to just send updated products to each frontend, then
         * have each frontend re-sync its leads from those products.
         * 
         * The downside is that we are duplicating some code/functionality from the backend, but the
         * performance gains outweigh this for now. This design could be looked at again in the future.
         */
        syncProductChanges(productIds = null) {
            const productsStore = useProductsStore()

            if(!Array.isArray(productIds) || productIds.length === 0) {
                productIds = productsStore.all.map(p => p._id)
            }

            let changed = false

            for(const lead of leads) {
                if(!Array.isArray(lead.assignments) || lead.assignments.length === 0) continue

                let assignmentsSortChanged = false

                for(const assignment of lead.assignments) {
                    if(!assignment.productId || !productIds.includes(assignment.productId)) continue

                    const product = productsStore.byId(assignment.productId)

                    if(!product || !product?._id) continue

                    // Product fields we want to update

                    if(assignment.productName !== product.name) {
                        assignment.productName = product.name
                        changed = true
                    }

                    if(assignment.sort_order_id !== product.sort_order_id) {
                        assignment.sort_order_id = product.sort_order_id
                        assignmentsSortChanged = true
                        changed = true
                    }

                    //
                    // Update status text and color
                    //
                    const statusesChanged = updateAssignmentStatusWithProduct(product, assignment)

                    if(statusesChanged) changed = true

                    //
                    // Update questions from product question templates
                    //
                    const questionsChanged = updateAssignmentQuestionsWithProduct(product, assignment)

                    if(questionsChanged) changed = true

                    //
                    // Update monthly sales targets with KPI weights set in product's settings
                    //
                    if(assignment.hasSales) {
                        const salesChanged = updateSalesMonthlyTargetsWeighted(product, lead, assignment)
                        if(salesChanged) changed = true
                    }

                }

                // Re-sort assignments if sort order changed
                if(assignmentsSortChanged) {
                    lead.assignments.sort(appHelpers.resourceSortFunction)
                }
            }

            if(changed && this.reactivityEnabled) this.updateToggler++

            return changed
        },


        /*
         * Sync user changes to lead assignments, ie. if user name gets updated,
         * update user names in lead.assignments
         * 
         * We do it this way for performance reasons (rather than re-syncing all leads from backend);
         * for example, when updating a user's name, all leads that have that user assigned would
         * need re-syncing. This can mean thousands of leads. So the lighter option both for the
         * backend and bandwidth usage is to just send updated users to each frontend, then
         * have each frontend re-sync its leads from those users.
         */
       syncUserChanges(userIds = null) {
            const usersStore = useUsersStore()

            if(userIds === null) {
                userIds = usersStore.all.map(u => u._id)
            } else if(!Array.isArray(userIds)) {
                userIds = [ userIds ]
            }

            let changed = false

            for(const lead of leads) {
                if(!Array.isArray(lead.assignments) || lead.assignments.length === 0) continue

                for(const assignment of lead.assignments) {
                    if(!userIds.includes(assignment.assigneeId)) continue

                    const user = usersStore.byId(assignment.assigneeId)

                    // User fields we want to update
                    if(assignment.assigneeFullName !== user.fullName) {
                        assignment.assigneeFullName = user.fullName

                        changed = true
                    }
                }
            }

            if(changed && this.reactivityEnabled) this.updateToggler++
        },


        /*
         * Sync contact changes with lead contacts. Read above for why we do things this way.
         */
        syncContactChanges(contactId) {
            const contactsStore = useContactsStore()

            let changed = false

            const updatedContact = contactsStore.byId(contactId)

            for(const lead of leads) {
                if(!Array.isArray(lead.contacts) || lead.contacts.length === 0) continue

                const leadContact = lead.contacts.find(c => c._id === contactId)

                if(!leadContact) continue

                if(leadContact.firstname !== updatedContact.firstname) {
                    leadContact.firstname = updatedContact.firstname
                    changed = true
                }

                if(leadContact.lastname !== updatedContact.lastname) {
                    leadContact.lastname = updatedContact.lastname
                    changed = true
                }
                if(leadContact.fullName !== updatedContact.fullName) {
                    leadContact.fullName = updatedContact.fullName
                    changed = true
                }
                if(leadContact.telephone !== updatedContact.telephone) {
                    leadContact.telephone = updatedContact.telephone
                    changed = true
                }
                if(leadContact.telephone2 !== updatedContact.telephone2) {
                    leadContact.telephone2 = updatedContact.telephone2
                    changed = true
                }
                if(leadContact.email !== updatedContact.email) {
                    leadContact.email = updatedContact.email
                    changed = true
                }
                if(leadContact.title !== updatedContact.title) {
                    leadContact.title = updatedContact.title
                    changed = true
                }
                if(leadContact.title2 !== updatedContact.title2) {
                    leadContact.title2 = updatedContact.title2
                    changed = true
                }
                if(leadContact.notes !== updatedContact.notes) {
                    leadContact.notes = updatedContact.notes
                    changed = true
                }
                if(leadContact.linkedin !== updatedContact.linkedin) {
                    leadContact.linkedin = updatedContact.linkedin
                    changed = true
                }
            }

            if(changed && this.reactivityEnabled) this.updateToggler++
        },


        async saveSalesTargetAndNumbers(leadId, productId, salesTarget, monthlySales) {
            const payload = { leadId, productId, salesTarget, monthlySales }

            const { updatedLead } = await apiRequest(...api.saveSalesTargetAndNumbers, payload)

            this.PUT(updatedLead)
        },


        async uploadSales(payload) {
            const { updatedLeads } = await apiRequest(...api.uploadSales, payload)

            this.PUT(updatedLeads)
        },

    }

})



/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////
//
// ██╗  ██╗███████╗██╗     ██████╗ ███████╗██████╗
// ██║  ██║██╔════╝██║     ██╔══██╗██╔════╝██╔══██╗
// ███████║█████╗  ██║     ██████╔╝█████╗  ██████╔╝
// ██╔══██║██╔══╝  ██║     ██╔═══╝ ██╔══╝  ██╔══██╗
// ██║  ██║███████╗███████╗██║     ███████╗██║  ██║
// ╚═╝  ╚═╝╚══════╝╚══════╝╚═╝     ╚══════╝╚═╝  ╚═╝
//
/////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////


function updateAssignmentStatusWithProduct(product, assignment) {
    let changed = false

    const statusIdx = assignment.statusIdx

    if(!assignment.assigneeId || statusIdx === null) return false

    if(!Array.isArray(product.statuses)) return false

    if(typeof product.statuses[statusIdx] === 'undefined') return false

    const statusColor = product.statuses[statusIdx].color
    const statusText = product.statuses[statusIdx].text

    if(assignment.statusText !== statusText) {
        assignment.statusText = statusText
        changed = true
    }

    if(assignment.statusColor !== statusColor) {
        assignment.statusColor = statusColor
        changed = true
    }

    return changed
}


/*
 * TODO: Duplicate from backend - this needs to be looked at; better sharing of the same code
 */
function updateAssignmentQuestionsWithProduct(product, assignment) {
    let changed = false

    // Skip if there's no answers
    if(!appHelpers.isObject(assignment.answers)) return false

    for(const [templateId, question] of Object.entries(assignment.answers)) {
        const templateQuestion = product.templateQuestions.find(t => t._id === templateId)

        if(!templateQuestion) {
            // Answered question doesn't exist anymore for this product (deleted by admin)
            // Delete answered question
            delete assignment.answers[templateId]
            continue
        }

        if(question.description !== templateQuestion.description) {
            question.description = templateQuestion.description
            changed = true
        }

        if(question.required !== templateQuestion.required) {
            question.required = templateQuestion.required
            changed = true
        }

        if(question.type !== templateQuestion.type) {
            // TODO: Awkward step of transfering answers between 'num' and 'quantity' fields
            //       when question type is changed between 'number' and 'qty / personal sales' question
            //       This can be removed when backend is refactored as noted in questions/editQuestion
            //       on backend side...
            if(question.type === 'number' && templateQuestion.type === 'value' && templateQuestion.quantitative_type === 'quantitative_dotsize') {
                question.answer.quantity = question.answer.num
                question.answer.num = 0
            } else if(question.type === 'value' && question.quantitative_type === 'quantitative_dotsize' && templateQuestion.type === 'number') {
                question.answer.num = question.answer.quantity
                question.answer.quantity = 0
            }

            question.type = templateQuestion.type
            changed = true
        }

        if(question.quantitative_type !== templateQuestion.quantitative_type) {
            question.quantitative_type = templateQuestion.quantitative_type
            changed = true
        }

        // Skip rest if not a multiple option question
        if(templateQuestion.type !== 'multiple' && templateQuestion.type !== 'multiple_many') continue

        const templateOptions = templateQuestion.multiple_selection_strings

        // Update answer choices for multiple option questions
        for(const [idx, currentOption] of question.multiple_selection_strings.entries()) {
            // Find answer (if any)
            const answerIdx = question.answer.choices.findIndex(c => c === currentOption)

            if(!templateOptions[idx]) {
                console.warn('updateAssignmentAnswersWithProduct: Question option has been removed:')
                console.warn(`product: ${product.name}`)
                console.warn(`question: ${question.description}`)
                console.warn(`option: ${currentOption}`)
                console.warn(`updated template: ${JSON.stringify(templateQuestion)}`)
                if(answerIdx !== -1) question.answer.choices.splice(answerIdx, 1)
                continue
            }
    
            if(templateOptions[idx] === currentOption) continue // Option not modified,
                                                                // no need to update answers

            if(answerIdx === -1) continue
    
            // Update answer with new option
            question.answer.choices[answerIdx] = templateOptions[idx]

            changed = true
        }

        // Update multiple options choices
        if(JSON.stringify(question.multiple_selection_strings) !== JSON.stringify(templateOptions)) {
            question.multiple_selection_strings = templateOptions
            changed = true
        }
    
    }

    return changed
}


/*
 * TODO: Duplicate from backend - this needs to be looked at; better sharing of the same code
 */
function updateSalesMonthlyTargetsWeighted(product, lead, assignment) {
    if(!assignment.hasSales) return false

    if(!Array.isArray(lead.kpi)) return false

    // Find the product-lead assignment specific KPI config
    const assignmentKpi = lead.kpi.find(k => String(k.product_id) === String(product._id))

    if(!assignmentKpi) return false

    // Find KPI monthly "weights" for this product
    const kpiWeightSet = Object.values(activeKpiWeights(product))

    let changed = false

    for(const [month, weight] of kpiWeightSet.entries()) {
        let monthlyTarget = 0

        if(!isNaN(assignmentKpi.sales_target)) {
            monthlyTarget = (assignmentKpi.sales_target / 12)
        }

        // Weight multiplier, 8.333 is the 1x multiplier
        const weightMultiplier = (weight / 8.333)

        const newTarget = monthlyTarget * weightMultiplier
        const currentTarget = assignment.sales.target[month]

        if(currentTarget !== newTarget) {
            assignment.sales.target[month] = newTarget
            changed = true
        }
    }

    return changed
}


function activeKpiWeights(product) {
    const weights = {
        'jan': 8.333,
        'feb': 8.333,
        'mar': 8.333,
        'apr': 8.333,
        'may': 8.333,
        'jun': 8.333,
        'jul': 8.333,
        'aug': 8.333,
        'sep': 8.333,
        'oct': 8.333,
        'nov': 8.333,
        'dec': 8.333,
    }

    if(Array.isArray(product?.kpi_weight_sets) && product.kpi_weight_sets.length > 0) {
        const activeSet = product.kpi_weight_sets.find(s => s.active === true)

        if(!activeSet) return weights

        weights.jan = activeSet.jan
        weights.feb = activeSet.feb
        weights.mar = activeSet.mar
        weights.apr = activeSet.apr
        weights.may = activeSet.may
        weights.jun = activeSet.jun
        weights.jul = activeSet.jul
        weights.aug = activeSet.aug
        weights.sep = activeSet.sep
        weights.oct = activeSet.oct
        weights.nov = activeSet.nov
        weights.dec = activeSet.dec
    }

    return weights
}