import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mobile-bottom" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_business_card_scanner = _resolveComponent("business-card-scanner")
  const _component_add_lead_contact = _resolveComponent("add-lead-contact")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["nav-button is-clickable activities-button", { "active": _ctx.rootStore.currentPage === "activities" }]),
      onClick: _cache[0] || (_cache[0] = $event => ($options.goToPage("activities")))
    }, null, 2 /* CLASS */),
    _createElementVNode("div", {
      class: "nav-button is-clickable qr-scanner-button",
      onClick: _cache[1] || (_cache[1] = $event => (_ctx.$refs.businessCardScanner.toggleComponent(true)))
    }),
    _createElementVNode("div", {
      class: "nav-button is-clickable add-lead-contact-button",
      onClick: _cache[2] || (_cache[2] = $event => (_ctx.addNewLeadMobileToggled = true))
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["nav-button is-clickable map-view-button", { "active": _ctx.rootStore.currentPage === "map-view" }]),
      onClick: _cache[3] || (_cache[3] = $event => ($options.goToPage("map-view")))
    }, null, 2 /* CLASS */),
    _createElementVNode("div", {
      class: _normalizeClass(["nav-button is-clickable manage-leads-button", { "active": _ctx.rootStore.currentPage === "manage-leads-mobile" }]),
      onClick: _cache[4] || (_cache[4] = $event => ($options.goToPage("manage-leads-mobile")))
    }, null, 2 /* CLASS */),
    _createVNode(_component_business_card_scanner, { ref: "businessCardScanner" }, null, 512 /* NEED_PATCH */),
    _createVNode(_component_add_lead_contact, { ref: "addLeadContactMobile" }, null, 512 /* NEED_PATCH */)
  ]))
}