//
//
// RUDOLF frontend
//
// Nurminen Development Oy Ltd - https://nurminen.dev
//
// For customer: The Rudolf Oy
//
// ALL RIGHTS RESERVED BY CUSTOMER
//
//

//
// File author(s):
//   - Riku Nurminen <riku@nurminen.dev>
//


import { defineStore }              from 'pinia'

import { apiRequest }               from '../api_request.js'

import * as crudFactory             from './_crud.js'

import { useLocalizationsStore }    from './localizations.js'


const api = {
    'saveSettings': [ 'POST',        '/settings' ],
    'saveLabels':   [ 'POST',        '/settings/labels' ],
}


const settings     = []
const settingsMap  = new Map()


export const useSettingsStore = defineStore('settings', {
    state: () => {
        return {
            ...crudFactory.states(),
        }
    },


    getters: {
        ...crudFactory.getters(settings, settingsMap),

        // NOTE: Should return empty string if localized string is not found
        _localizedLabel: (state) => (fieldName) => {
            const localizationsStore = useLocalizationsStore()

            const localizedLabel = localizationsStore.l(`setup-labels/${fieldName}`)

            if(localizedLabel !== '<unknown localization field>') return localizedLabel

            return ''
        },

        // NOTE: Should return empty string if label is not found in settings or
        //       in localized fields
        label: (state) => (fieldName) => {
            // Check if we have settings at all
            if(state.all.length !== 1) {
                return state._localizedLabel(fieldName)
            }

            // Check if we have user-specified label
            const labelConfigs = state.all[0]?.labelConfigs || []

            const labelConfig = labelConfigs.find(lc => lc.fieldName === fieldName)

            if(labelConfig && labelConfig.givenName) {
                return labelConfig.givenName
            }

            // No user specified label, try localizations
            return state._localizedLabel(fieldName)
        },


        getSetting: (state) => (setting) => {
            // Check if we have settings at all
            if(state.all.length !== 1) {
                return null
            }

            return state.all?.[0]?.[setting] || null
        },


        leadCommentingMode() {
            return this.getSetting('leadCommentingMode') || 'per_lead'
        }
    },


    actions: {
        ...crudFactory.actions('settings', {}, settings, settingsMap),


        async saveSettings(settings) {
            const updatedSettings = await apiRequest(...api.saveSettings, settings)

            this.PUT(updatedSettings)

            return updatedSettings
        },


        async saveLabels({ labelConfigs }) {
            const updatedSettings = await apiRequest(...api.saveLabels, { labelConfigs })

            this.PUT(updatedSettings)

            return updatedSettings
        },
    }

})
